<div (click)="openOutboundUrl()" *ngIf="config" 
    class="web-view-container" 
    [style.height]="getHeight(config.height_px)">
    <div *ngIf="loading"
        class="content loading-container" 
        [style.backgroundImage]="'url(' + config.loading_image_url + ')'">
    </div>
    <iframe *ngIf="config.content_url" 
        [src]="safe_url" 
        frameborder="0"
        [style.height]="'100%'"
        class="content"
        (load)="loaded()"
        (click)="openOutboundUrl()">
    </iframe>
</div>


