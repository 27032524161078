import { Component } from '@angular/core';

declare global {
    interface Window {
        Android: any;
        uuid: any;
        kill: () => void;
        appBackgrounded: (a: any, r?: any) => void;
    }
}

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent { }
