import { formatDistanceToNow } from 'date-fns';

export const FONTS = {
    HelveticaBold: { 'font-weight': 'bold' },
    HelveticaBoldOblique: { 'font-weight': 'bold', 'font-style': 'oblique' },
    HelveticaLight: { 'font-weight': 'lighter' },
    HelveticaLightOblique: { 'font-weight': 'lighter', 'font-style': 'oblique' },
    HelveticaOblique: { 'font-style': 'oblique' },
    HelveticaNeueBold: { 'font-weight': 'bold' },
    HelveticaNeueBoldItalic: { 'font-weight': 'bold', 'font-style': 'italic' },
    // eslint-disable-next-line @typescript-eslint/quotes
    HelveticaNeueCondensedBlack: { 'font-family': "'Roboto Condensed', sans-serif" },
    // eslint-disable-next-line @typescript-eslint/quotes
    HelveticaNeueCondensedBold: { 'font-family': "'Roboto Condensed', sans-serif" },
    HelveticaNeueItalic: { 'font-style': 'italic' },
    HelveticaNeueLight: { 'font-weight': 'lighter' },
    HelveticaNeueLightItalic: { 'font-weight': 'lighter', 'font-style': 'italic' },
    HelveticaNeueMediumItalic: { 'font-style': 'italic' },
    HelveticaNeueUltraLight: { 'font-weight': '100' },
    HelveticaNeueUltraLightItalic: { 'font-weight': '100', 'font-style': 'italic' },
    HelveticaNeueThin: { 'font-weight': '100' },
    HelveticaNeueThinItalic: { 'font-weight': '100', 'font-style': 'italic' }
};

export function clone(obj: { [key: string]: any }): { [key: string]: any } {
    return JSON.parse(JSON.stringify(obj));
}

export function normalizeCommonJSImport<T>(importPromise: Promise<T>): Promise<T> {
    // CommonJS's `module.exports` is wrapped as `default` in ESModule.
    return importPromise.then((m: any) => (m.default || m) as T);
}

function cloudinary_key(key: string) {
    return key.includes('_') ? `${key.split('_')[0][0]}${key.split('_')[1][0]}` : key[0];
}

export function formatCloudinaryUrl(url: string, options: { [key: string]: string } = null): string {
    if (url === null || url === '') {
        return;
    }
    const split_url = url.split('upload/');
    let option_string = '';
    if (options !== null) {
        option_string = Object.keys(options)
            .map((key) => {
                return `${cloudinary_key(key)}_${options[key]}`;
            })
            .join();
    }
    return `${split_url[0]}upload/${option_string}/${split_url[1]}`;
}

export function formatCloudinaryVideoUrl(url: string): string {
    if (url === null || url === '') {
        return;
    }
    const [front, back] = url.split('upload/');
    const no_transform = back.slice(back.indexOf('v'));

    return `${front}upload/w_300,q_auto/${no_transform.replace(/\.[^.]+$/, '.mp4')}`;
}

export function handlePostedAt(created_at) {
    return formatDistanceToNow(new Date(created_at), { addSuffix: true });
}

export function ensureHTTPS(url: string): string {
    if (url === null || url === undefined) {
        return '';
    }
    const regx = /http:/g;
    return url.replace(regx, 'https:');
}

export function escapeSingleQuotes(str: string): string {
    const regx = /'/g;
    return str.replace(regx, `\\'`);
}

export function handleFont(font: string): string {
    font = font.replace(/-/g, '');
    if (FONTS[font]) {
        return FONTS[font];
    }
}

export function handleShadow(card_shadow: string) {
    return {
        'box-shadow': '0 2px 4px -1px ' + card_shadow + ', \
                0 4px 5px 0 ' + card_shadow + ', \
                0 1px 10px 0 ' + card_shadow
    };
}

export function setOS(): string {
    const nAgt = navigator.userAgent;
    const clientStrings: Array<{ [key: string]: any }> = [
        { s: 'Windows 10', r: /(Windows 10.0|Windows NT 10.0)/ },
        { s: 'Windows 8.1', r: /(Windows 8.1|Windows NT 6.3)/ },
        { s: 'Windows 8', r: /(Windows 8|Windows NT 6.2)/ },
        { s: 'Windows 7', r: /(Windows 7|Windows NT 6.1)/ },
        { s: 'Windows Vista', r: /Windows NT 6.0/ },
        { s: 'Windows Server 2003', r: /Windows NT 5.2/ },
        { s: 'Windows XP', r: /(Windows NT 5.1|Windows XP)/ },
        { s: 'Windows 2000', r: /(Windows NT 5.0|Windows 2000)/ },
        { s: 'Windows ME', r: /(Win 9x 4.90|Windows ME)/ },
        { s: 'Windows 98', r: /(Windows 98|Win98)/ },
        { s: 'Windows 95', r: /(Windows 95|Win95|Windows_95)/ },
        { s: 'Windows NT 4.0', r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/ },
        { s: 'Windows CE', r: /Windows CE/ },
        { s: 'Windows 3.11', r: /Win16/ },
        { s: 'Android', r: /Android/ },
        { s: 'Open BSD', r: /OpenBSD/ },
        { s: 'Sun OS', r: /SunOS/ },
        { s: 'Linux', r: /(Linux|X11)/ },
        { s: 'iOS', r: /(iPhone|iPad|iPod)/ },
        { s: 'Mac OS X', r: /Mac OS X/ },
        { s: 'Mac OS', r: /(MacPPC|MacIntel|Mac_PowerPC|Macintosh)/ },
        { s: 'QNX', r: /QNX/ },
        { s: 'UNIX', r: /UNIX/ },
        { s: 'BeOS', r: /BeOS/ },
        { s: 'OS/2', r: /OS\/2/ },
        { s: 'Search Bot', r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/ }
    ];
    const search = clientStrings.find((val) => {
        if (val.r.test(nAgt)) {
            return true;
        }
    });
    if (search !== undefined) {
        return search.s;
    } else {
        return '-';
    }
}

export function handleStatusColor(match_status: string) {
    switch (match_status) {
        case 'on_court':
        case 'finished':
        case 'finished_recently':
            return '#c5c5c9';
        case 'playing':
            return '#EE3224';
        case 'warmup':
        case 'suspended':
        case 'future':
        case 'retired':
        case 'walkover':
            return '#AEAEB2';
        default:
            return '#E5E5EA';
    }
}

export function handleTitleColor(match_status: string): string {
    switch (match_status) {
        case 'playing':
            return '#fafafa';
        default:
            return 'black';
    }
}

export function hexToRGBA(hex: string) {
    // 3 digits
    if (hex.length === 4) {
        return {
            r: Number('0x' + hex[1] + hex[1]),
            g: Number('0x' + hex[2] + hex[2]),
            b: Number('0x' + hex[3] + hex[3])
        };
        // 6 digits
    } else if (hex.length === 7) {
        return {
            r: Number('0x' + hex[1] + hex[2]),
            g: Number('0x' + hex[3] + hex[4]),
            b: Number('0x' + hex[5] + hex[6])
        };
    } else {
        return null;
    }
}

export function blackOrWhite({ r, g, b }): string {
    const ratio = r * 0.299 + g * 0.587 + b * 0.114;
    return ratio > 190 ? '#323232;' : '#fafafa';
}

export function hexToRGBAString(hex: string) {
    let r = 0,
        g = 0,
        b = 0;

    // 3 digits
    if (hex.length === 4) {
        r = Number('0x' + hex[1] + hex[1]);
        g = Number('0x' + hex[2] + hex[2]);
        b = Number('0x' + hex[3] + hex[3]);

        // 6 digits
    } else if (hex.length === 7) {
        r = Number('0x' + hex[1] + hex[2]);
        g = Number('0x' + hex[3] + hex[4]);
        b = Number('0x' + hex[5] + hex[6]);
    }

    return `rgba(${r}, ${g}, ${b}, 0.2)`;
}

export function getUserToken(): string {
    try {
        const { token } = JSON.parse(localStorage.getItem('TennisONEUser'));
        return token;
    } catch (error) {
        return null;
    }
}

export function getFingerPrint(): string {
    try {
        const { fingerprint } = JSON.parse(localStorage.getItem('TennisONEUser'));
        return fingerprint;
    } catch (error) {
        return null;
    }
}

export function getUser(): string {
    const user = JSON.parse(localStorage.getItem('TennisONEUser'));
    return user;
}

export function getUserFingerprint(): string {
    const { fingerprint } = JSON.parse(localStorage.getItem('TennisONEUser'));
    return fingerprint;
}

export function validSelector(prop: string): string {
    if (prop === null) {
        return 'SevenByThree';
    } else if (prop === '19.5:9') {
        return 'NineteenFiveByNine';
    } else {
        return prop;
    }
}

export function handleIsLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('TennisONEUser'));
    if (user) {
        // logged in so return true
        const today = new Date();
        // TODO
        // will need to know if we want to check/create a TTL
        if (user.ttl <= today.getTime()) {
            localStorage.removeItem('TennisONEUser');
            return false;
        } else {
            return true;
        }
    } else {
        return false;
    }
}

// export async function routeParser(url: string, context: any): Promise<void> {
//     if (url === '' || url === null || url === undefined) {
//         return null;
//     }
//     const formatted = new URL(url);
//     if (['iOS', 'Mac OS X', 'Mac OS'].includes(setOS())) {
//         formatted.pathname = '//' + formatted.hostname + formatted.pathname;
//     }

//     const protocol = formatted.protocol.toLowerCase();
//     const pathname = formatted.pathname.toLowerCase();

//     if (url.includes('&newsLink=true')) {
//         const news_tab: HTMLElement = document.querySelector('.mat-tab-labels').children[1] as HTMLElement;
//         news_tab.click();
//     } else if (protocol === 'bleachr:' && pathname.includes('//contentwall/')) {
//         const content_id = pathname.split('contentwall/')[1];
//         const route_url = await context.ppvAPI.checkPurchase(content_id);
//         if (route_url !== null) {
//             if (route_url.includes('https://')) {
//                 window.open(route_url, '_blank');
//             } else {
//                 const sku = route_url.split('iap/');
//                 context.ppvAPI.openProductModal(sku[1]);
//             }
//         }
//     } else if (protocol === 'bleachr:' || protocol === 'tennisone:') {
//         switch (pathname) {
//             case '//gamezone':
//                 context.router.navigateByUrl('/game-zone');
//                 break;
//             case '//gamezone/dailypicks':
//                 context.router.navigateByUrl('/game-zone?view_picks=true');
//                 break;
//             case '//gamezone/pointstreak':
//                 context.router.navigateByUrl('/game-zone?view_streak=true');
//                 break;
//             case '//gamezone/triviablitz':
//                 context.router.navigateByUrl('/game-zone?view_trivia_blitz=true');
//                 break;
//             case '//gamezone/contest':
//                 context.router.navigateByUrl('/leaderboard');
//                 break;
//             case '//scores':
//                 context.handleTournamentLink();
//                 break;
//             // DO NOT DO THIS ITS REAL BAD BUT YEA
//             case '//scores/66cc5459-4e7d-4485-a24f-938c14bdcbc7':
//                 context.router.navigateByUrl(dev_redirect);
//                 break;
//             case '//scores/7f1b012b-0ffb-4d70-83d6-3b8255964985':
//                 context.router.navigateByUrl(prod_redirect);
//                 break;
//             case '//ticketsocket/mytickets':
//                 window.open(environment.BUY_TICKETS, '_blank');
//                 break;
//             case '//video':
//                 const [empty, value] = formatted.search.split('?=');
//                 const end = `/video?search=${value}`;
//                 context.router.navigateByUrl(end);
//                 break;
//             case '//balltracker': {
//                 context.router.navigate(['/tracer']);
//                 break;
//             }
//             default:
//                 if (pathname.includes('broadcast')) {
//                     const broadcast_id = pathname.split('/')[3];
//                     if (handleIsLoggedIn()) {
//                         window.open(
//                             environment.CHAT_URL +
//                             '/live' +
//                             buildQueryParams({ id: broadcast_id, token: getUserToken() }),
//                             '_blank'
//                         );
//                     } else {
//                         context.router.navigate(['/login-prompt']);
//                     }
//                 } else if (pathname.includes('//iap/')) {
//                     const product_id = pathname.split('/')[3];
//                     context.ppvAPI.openProductModal(product_id);
//                 } else if (pathname.includes('//practice/')) {
//                     const tournament_id = pathname.split('/')[3];
//                     context.router.navigateByUrl(`/practice/${tournament_id}`);
//                 } else if (pathname.includes('//rubbers/')) {
//                     const team_id = pathname.split('/')[3];
//                     context.handleRubberLink(team_id);
//                 } else if (pathname.includes('//wtt')) {
//                     const id = pathname.split('/')[3];
//                     if (id) {
//                         context.router.navigateByUrl(`/scores/tournament?schedule_entry_id=${id}`);
//                     } else {
//                         context.router.navigateByUrl('/wtt');
//                     }
//                 } else if (pathname.includes('//scores')) {
//                     // NOTE iOS dropped suppport for team_id linking, just defaulting
//                     // to scores tab
//                     context.handleTournamentLink();
//                 } else if (pathname.includes('//articles')) {
//                     if (url.substring(19)) {
//                         const id = url.substring(19);
//                         context.toggleDrawer(id);
//                     } else {
//                         context.router.navigateByUrl('/articles');
//                     }
//                 } else if (pathname.includes('//tennis_player')) {
//                     const id = pathname.split('/')[3];
//                     context.openPlayer(id);
//                 } else if (pathname.includes('//http')) {
//                     window.open(pathname.slice(2), '_blank');
//                 } else if (pathname.includes('//home/')) {
//                     const path_parts = pathname.split('home/');
//                     context.router.navigateByUrl(`/homescreen?timeline_id=${path_parts[1]}`);
//                 } else {
//                     window.open(url, '_blank');
//                 }
//                 break;
//         }
//     } else if (protocol === 'http:' || protocol === 'https:') {
//         switch (formatted.host) {
//             case environment.BLOG_ENDPOINT: {
//                 const route = url.split(environment.BLOG_ENDPOINT)[1];
//                 context.router.navigate(['story', route], { queryParams: { title: context.custom.title } });
//                 break;
//             }
//             case window.origin: {
//                 const route = url.split(window.origin)[1];
//                 context.router.navigate([route]);
//                 break;
//             }
//             default:
//                 window.open(url, '_blank');
//                 break;
//         }
//     } else {
//         return null;
//     }
// }

export function buildQueryParams(params: { [key: string]: string }): string {
    const str = Object.entries(params)
        .map(([key, value]) => {
            return key + '=' + encodeURI(value);
        })
        .join('&');
    return `?${str}`;
}

export const completed_match_statuses = ['finished', 'finished_retired', 'finished_walkover', 'retired', 'completed'];

export const live_match_statuses = ['playing', 'on_court', 'warmup', 'suspended', 'finished_recently'];
