import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { HttpOptions } from '../_classes';

export interface HeaderOptions {
    type: 'auth' | 'basic'
    fingerprint?: string
    identifier?: string
}

@Injectable({
    providedIn: 'root'
})
export class HttpPromiseService {
    public httpOptions = new HttpOptions()
    private defaultHeaders: HeaderOptions = {
        type: 'basic',
        identifier: 'tennis-one'
    }

    constructor(
        private http: HttpClient
    ) { }

    async get<request_type>(url: string, options: HeaderOptions = this.defaultHeaders): Promise<request_type> {
        try {
            // const header: { headers: HttpHeaders } = options?.type === 'auth' ? new HttpOptions().authHeaders(options.identifier) : new HttpOptions().basicHeaders(options.identifier)
            const header = this.httpOptions.basicHeaders()

            const request = this.http.get<request_type>(url, header)

            return await lastValueFrom(request);
        } catch (err) {
            throw err;
        }
    }

    async post<request_type, payload_type>(url: string, payload: payload_type, options: HeaderOptions = this.defaultHeaders): Promise<request_type> {
        try {
            // const header: { headers: HttpHeaders } = options?.type === 'auth' ? new HttpOptions().authHeaders(options.identifier) : new HttpOptions().basicHeaders(options.identifier)
            const header = this.httpOptions.basicHeaders()

            const request = this.http.post<request_type>(url, payload, header)

            return await lastValueFrom(request);
        } catch (err) {
            throw err;
        }
    }

    async put<request_type, payload_type>(url: string, payload: payload_type, options: HeaderOptions = this.defaultHeaders): Promise<request_type> {
        try {
            // const header: { headers: HttpHeaders } = options?.type === 'auth' ? new HttpOptions().authHeaders(options.identifier) : new HttpOptions().basicHeaders(options.identifier)
            const header = this.httpOptions.basicHeaders()

            const request = this.http.put<request_type>(url, payload, header)

            return await lastValueFrom(request);
        } catch (err) {
            throw err;
        }
    }

    // async delete<request_type>(url: string, options: HeaderOptions = this.defaultHeaders, payload?: any): Promise<request_type> {
    //     try {
    //         // const header: { headers: HttpHeaders, body?: any } = options?.type === 'auth' ? new HttpOptions().authHeaders(options.identifier) : new HttpOptions().basicHeaders(options.identifier)
    //         const header = this.httpOptions.basicHeaders()

    //         if (payload) header.body = payload;

    //         const request = this.http.delete<request_type>(url, header)

    //         return await lastValueFrom(request);
    //     } catch (err) {
    //         throw err;
    //     }
    // }
}

