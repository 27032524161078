export type EntriesThemeTypes = 'slam' | 'both1000' | 'atp1000' | 'wta1000' | 'exo' | 'atp500' | 'wta500' | 'atp250' | 'wta250' | 'atpchallenger' | 'challenger' | 'wta125' | 'college';

export interface ScheduledEntry {
    android_download_url: string;
    apple_download_url: string;
    end_date: string;
    gender: 'male' | 'female' | 'both';
    hidden: boolean;
    id: string;
    image_url: string;
    name: string;
    partnership_level: 'standard' | 'elite' | 'wtt';
    priority: number;
    start_date: string;
    team_id: string;
    theme: Theme;
    ticketing_url: string;
    time_zone_name: string;
    timeframe: string;
}
export interface Theme {
    bracket_event_id: string;
    court_type: string;
    icon: Icon;
    logo_url: string;
    navbar: Navbar;
    no_text: boolean;
    override_tabs: boolean;
    play_video_ads?: boolean;
    pretournament_page_url: string;
    sponsors: null;
    tabs: TabThemeConfig[];
    tournament_info: null;
    web_landing_page_url: null;
    types?: EntriesThemeTypes[];
    video_ad_id?: string;
}

export interface Icon {
    left: string;
    right: string;
}

export interface Navbar {
    color: string;
    url: string;
}

export interface TabThemeConfig {
    destination: TabTypes;
    show_bracket_event_link: boolean;
    name: string;
    timeline_id: string;
}

export type TabTypes = 'timeline' | 'webview' | 'scores' | 'brackets' | 'leaderboard_bracket' |
    'bracket_pools' | 'leaderboard_top_fans' | 'challenges' | 'players' | 'draws' | 'tournament_chat';