import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { helpers } from '../../helpers';
import { AnalyticService } from '../../services/analytic.service';
import { FontService } from '../../services/font-service.service';

@Component({
    selector: 'app-homescreen-standlone-card',
    templateUrl: './homescreen-standlone-card.component.html',
    styleUrls: ['./homescreen-standlone-card.component.scss']
})
export class HomescreenStandloneCardComponent implements OnInit {
    @Input() custom;
    public clipContentString = helpers.clipContentString;
    public handleFont;
    constructor(font: FontService, private dom: DomSanitizer, public analytics: AnalyticService) {
        this.handleFont = font.handleFont;
    }

    ngOnInit() {
        if (!this.custom.context.headline_background_color) {
            this.custom.context.headline_background_color = this.custom.theme_color;
        }
    }

    buildURL(post) {
        if (post.data[0].url === '' || post.data[0].url === null || post.data[0].url === undefined) {
            return null;
        }
        return this.dom.bypassSecurityTrustUrl(post.data[0].url);
    }

    cloudinaryCacheImage(photo_url) {
        if (photo_url === null || photo_url === undefined) {
            return '';
        }

        return `https://res.cloudinary.com/bleachr/image/fetch/${photo_url}`;
    }

    analytic(action: string): void {
        const event = {
            action,
            title: this.custom.title,
            subtitle: this.custom.subtitle,
            content_url: this.custom.data[0].url,
            image_url: this.custom.data[0].photo_url,
            content_text: this.custom.data[0].text,
            is_sponsored: this.custom.context.is_sponsored ? this.custom.context.is_sponsored : null
        };
        this.analytics.postEvent('homescreen_custom', event).subscribe(
            error => {
                console.log(error);
            });
    }

    valid(): boolean {
        if (this.custom.data[0].photo_url) {
            return true;
        } else {
            return false;
        }
    }

}
