import { Component, OnInit, ElementRef, EventEmitter, Input, Output, ViewChild, AfterViewInit } from '@angular/core';
import { EnhancedArticleModel } from '../models/enhanced_articles';
import Glide from '@glidejs/glide';
import { v4 } from 'uuid';
import { DomSanitizer } from '@angular/platform-browser';
import { FontService } from '../../services/font-service.service';
import { LinkifyService } from '../../services/linkify.service';
import { ensureHTTPS } from 'src/app/_functions';

@Component({
    selector: 'app-glide-carousel',
    templateUrl: './glide-carousel.component.html',
    styleUrls: ['./glide-carousel.component.scss']
})
export class GlideCarouselComponent implements OnInit, AfterViewInit {
    @ViewChild('carousel', { static: false }) carousel: ElementRef;
    @Input() data_type: string;
    @Input() data_object;
    @Output() click_event = new EventEmitter();
    public articles: EnhancedArticleModel[];
    public social_feed;
    public social_feed_items;
    public item_lenth = 0;
    public abort = false;
    public ensureHTTPS = ensureHTTPS;
    public handleFont;

    constructor(
        private dom: DomSanitizer,
        private fontSvc: FontService,
        private linkify: LinkifyService
    ) {
        this.handleFont = this.fontSvc.handleFont;
    }

    ensureValid() {
        if (!this.data_type || !this.data_object) {
            this.abort = true;
        }
    }

    ngOnInit() {
        this.setData();
        this.ensureValid();
        // console.log(this.data_object);
    }

    async ngAfterViewInit() {
        if (this.abort || !this.carousel) {
            console.warn(`There are two reasons you would see this message:
        1. Input data isn't being mapped correctly
        2. There is no carousel template
        Check and ensure all data is mapped correctly and that there is a template in the switch to render the carousel`);
            return;
        }
        const glide_id = v4().split('-')[0];
        this.carousel.nativeElement.classList.add(`g-${glide_id}`);

        const glide = await this.buildGlide(glide_id);

        glide.on(['mount.after'], () => {
            const video_tags = document.querySelectorAll('video');
            video_tags.forEach((video) => {
                video.setAttribute('width', '100%');
                video.setAttribute('height', '100%');
            });
        });

        glide.mount();
    }

    buildGlide(glide_id: string): Glide {
        return new Promise((completed) => {
            if (this.item_lenth === 1) {
                const new_glide = new Glide(`.g-${glide_id}`, {
                    type: 'slider',
                    gap: 16,
                    focusAt: 'center',
                    peek: 32
                });
                completed(new_glide);
            } else {
                const new_glide = new Glide(`.g-${glide_id}`, {
                    type: 'carousel',
                    gap: 16,
                    focusAt: 'center',
                    peek: 32,
                    animationTimingFunc: 'ease-in-out'
                });
                completed(new_glide);
            }
        });
    }

    setData() {
        switch (this.data_type) {
            case 'enhanced_articles': {
                this.articles = this.data_object.data;
                this.item_lenth = this.articles.length;
                break;
            }
            case 'social_feed': {
                this.social_feed = this.data_object.data[0];
                this.social_feed_items = this.data_object.data[0].items;
                this.item_lenth = this.social_feed_items.length;
                break;
            }
        }
    }

    clickHandler(object) {
        this.click_event.emit(object);
    }

    buildURL(article) {
        return this.dom.bypassSecurityTrustUrl(`bleachr://articles/${article.id}`);
    }

    handleURL(url: string, type?: string) {
        if (!url || url.length < 0) {
            if (type === 'video') {
                // eslint-disable-next-line max-len
                return this.dom.bypassSecurityTrustStyle(`background-image: url(https://res.cloudinary.com/bleachr/image/upload/o_65/v1586378313/TennisONE/T1%20Icons/article_placeholder.jpg); background-size: 100%; border: 1px solid #e0e0e0;`);
            }
            return;
        }

        if (type === 'image') {
            return ensureHTTPS(url);
        } else if (type === 'video') {
            url = url + '#t=1';
            return ensureHTTPS(url);
        } else if (type === 'background') {
            return this.dom.bypassSecurityTrustStyle(`background-image: url(${ensureHTTPS(url)})`);
        }
    }

    // ENHANCED ARTICLE METHODS
    buildIconPath(article_type: string) {
        switch (article_type) {
            case 'podcasts':
                return 'https://res.cloudinary.com/bleachr/image/upload/v1578074193/TennisONE/T1%20Icons/article_podcast_icon.png';

            default:
                return null;
        }
    }

    handleGradeColor(color: string) {
        if (!color) {
            return;
        }
        return color;
    }

    // SOCIAL FEED METHODS
    checkMediaType(item, post, container?: string) {

        if (item.is_social === false && container === 'youtube') {
            if (post.has_media && post.has_video) {
                return true;
            }
        } else if (item.is_social === false && container === 'web_photo') {
            if (post.has_image) {
                return true;
            }
        } else if (item.is_social === true && container === 'video') {
            if (post.has_media && post.has_video) {
                return true;
            } else if (post.has_image && post.has_video) {
                return true;
            } else {
                return false;
            }
        } else if (item.is_social === true && container === 'photo') {
            if (post.has_media && !post.has_video) {
                return true;
            } else if (post.has_image && !post.has_video) {
                return true;
            }
        }
    }

    parseDesc(desc: string) {
        return this.linkify.parse(desc, this.data_object.data[0].feed_type);
    }

}
