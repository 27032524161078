<section class="match-card">
    <div class="match-card-header">
        <div *ngIf="match_data.tournament_name" class="tournament-name">{{ match_data.tournament_name }}</div>
        <app-string [match_status]="match_data.status" *ngIf="match_data.starts_at < today || match_data.status" 
            [style.backgroundColor]="handleStatusColor(match_data.status)" 
            [style.color]="handleTitleColor(match_data.status)"
            class="match-status"></app-string>

        <div *ngIf="match_data.starts_at > today && !match_data.status"
            [style.backgroundColor]="handleStatusColor(match_data.status)" 
            [style.color]="handleTitleColor(match_data.status)"
            class="match-status">{{ match_data.starts_at | date: 'short' }}</div>
        <mat-icon class="card-more" *ngIf="show_options">more_horiz</mat-icon>
    </div>

    <app-match-team [team_data]="match_data.team1" [match_status]="match_data.status"></app-match-team>
    <hr />
    <app-match-team [team_data]="match_data.team2" [match_status]="match_data.status"></app-match-team>
</section>

