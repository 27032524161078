<a [href]="buildURL(custom)" (click)="analytic('tap')" *ngIf="valid()">
    <article class="bleachr-card" [appAspectRatio]="custom">
        <!-- HEADER CONTENT -->
        <div class="bleachr-card-headline"
            [style.background-image]="'linear-gradient(to right, '+ custom.context.headline_background_color +' 40%, transparent)'">
            <div *ngIf="custom.context.headline_icon" style="display: flex; height: inherit;">
                <div class="icon-container padded"
                    [style.height]="custom.context.title.font_size ? custom.context.title.font_size : '1.5em'"
                    [style.width]="custom.context.title.font_size ? custom.context.title.font_size : '1.5em'">
                    <img [src]="cloudinaryCacheImage(custom.context.headline_icon)" alt="">
                </div>
            </div>
            <div class="headline-text-container">
                <div class="truncate" [style.text-align]="custom.context.title.alignment"
                    [style.color]="custom.title_color" [style.font-size.px]="custom.context.title.font_size"
                    [ngStyle]="handleFont(custom.context.title.font)">
                    {{ custom.title }}
                </div>
                <div *ngIf="custom.subtitle" class="truncate sub-title" [style.color]="custom.subtitle_color"
                    [style.text-align]="custom.context.subtitle.alignment"
                    [style.font-size.px]="custom.context.subtitle.font_size"
                    [ngStyle]="handleFont(custom.context.subtitle.font)">
                    {{ custom.subtitle }}
                </div>
            </div>
        </div>
        <!-- CONTENT -->
        <div class="content-container">
            <img [src]="custom.data[0].photo_url" [alt]="custom.title" />
        </div>
        <!-- FOOTER CONTENT -->
        <footer class="bleachr-card-footer">
            <h5 [style.color]="custom.see_all_color" *ngIf="custom.see_all">
                {{ custom.see_all }}
            </h5>
            <div *ngIf="custom.data[0].text">
                <p class="body-text" [style.color]="custom.body_color"
                    [style.text-align]="custom.context.body_text.alignment"
                    [style.font-size.px]="custom.context.body_text.font_size"
                    [ngStyle]="handleFont(custom.context.body_text.font)">
                    {{ clipContentString(custom.data[0].text) }}
                </p>
            </div>
        </footer>
    </article>
    <!-- <div class="card">
    <mat-card>
      <img mat-card-image class="image-with-no-content" [src]="custom.data[0].photo_url" [alt]="custom.title"
        />
      <div class="card-headline"
        [style.background-image]="'linear-gradient(to right, '+ custom.context.headline_background_color +' 40%, transparent)'"
        *ngIf="custom.title && custom.title !== ''">
        <div *ngIf="custom.context.headline_icon" style="display: flex; height: inherit;">
            <div class="icon-container padded">
                <img [style.height]="'25px'" [src]="cloudinaryCacheImage(custom.context.headline_icon)" alt="">
            </div>
        </div>
          <div class="headline-text">
            <div class="truncate"
              [style.text-align]="custom.context.title.alignment"
              [style.color]="custom.title_color"
              [style.font-size.px]="custom.context.title.font_size || '18'" 
              [ngStyle]="handleFont(custom.context.title.font)">
              {{ custom.title }}
            </div>
            <div *ngIf="custom.subtitle"
              class="truncate" 
              [style.color]="custom.subtitle_color"
              [style.text-align]="custom.context.subtitle.alignment"
              [style.font-size.px]="custom.context.subtitle.font_size || '14'"
              [ngStyle]="handleFont(custom.context.subtitle.font)">
              {{ custom.subtitle }}
            </div>
          </div>
      </div>
      <div class="card-foot" >
        <div class="text-container padded">
          <h5 [style.color]="custom.see_all_color">
            {{ custom.see_all }}
          </h5>
          <div *ngIf="custom.data[0].text" class="padded" [style.color]="custom.subtitle_color" [style.text-align]="custom.context.body_text.alignment"
            [style.font-size.px]="custom.context.body_text.font_size"
            [ngStyle]="handleFont(custom.context.body_text.font)">
            <p>{{ clipContentString(custom.data[0].text) }}</p>
          </div>
        </div>
      </div>
    </mat-card>
  </div> -->
</a>