import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Component imports
import { AppComponent } from './app.component';

const routes: Routes = [
    { path: '*', component: AppComponent },
    { path: 'live-scores', loadChildren: () => import('./live-scores/live-scores.module').then(m => m.LiveScoresModule) },
    { path: 'homescreen', loadChildren: () => import('./homescreen-module/homescreen.module').then(m => m.HomescreenModule) },
    { path: 'practice/:team_id/:tournament_id', loadChildren: () => import('./practice-schedule-core/practice-schedule-core.module').then(m => m.PracticeScheduleCoreModule) },
    { path: 'v1-tennis-draws', loadChildren: () => import('./tennis-draws/tennis-draws.module').then(m => m.TennisDrawsModule) },
    { path: 'tennis-draws', loadChildren: () => import('./tennis-draws-v2/tennis-draws-v2.module').then(m => m.TennisDrawsV2Module) },
    { path: 'streaming', loadChildren: () => import('./streaming/streaming.module').then(m => m.StreamingModule) },
    { path: 'streamer', loadChildren: () => import('./streamer/streamer.module').then(m => m.StreamerModule) },
    { path: 'video', loadChildren: () => import('./video-widget/video-widget.module').then(m => m.VideoWidgetModule) },
    { path: 'trivia-blitz', loadChildren: () => import('./trivia-blitz-module/trivia-blitz.module').then(m => m.TriviaBlitzModule) },
    { path: 'timeline/:id', loadChildren: () => import('./singular-timelime-module/singular-timelime-module.module').then(m => m.SingularTimelimeModuleModule) },
    { path: 'match_widget', loadChildren: () => import('./match-card-module/match-card.module').then(m => m.MatchCardModule) },
    { path: 'tac', loadChildren: () => import('./tac-module/tac.module').then(m => m.TacModule) },
    { path: 'referral', loadChildren: () => import('./referral-fan/referral-fan.module').then(m => m.ReferralFanModule) },
    { path: 'chat', loadChildren: () => import('./bleachr-chat/bleachr-chat.module').then(m => m.BleachrChatModule) },
    { path: 'leaderboard', loadChildren: () => import('./leaderboard-module/leaderboard.module').then((mod) => mod.LeaderboardModule) },
    { path: 'overlay/:id', loadChildren: () => import('./video-overlay/video-overlay.module').then(m => m.VideoOverlayModule) },
    { path: 'crowdlive', loadChildren: () => import('./crowdlive-download/crowdlive-download.module').then(m => m.CrowdliveDownloadModule), title: 'CrowdLIVE INTERACTIVE' },
    { path: 'receipts/:id', loadChildren: () => import('./order-receipt/order-receipt.module').then(m => m.OrderReceiptModule), title: 'Receipt' },
    { path: ':webclient_key', loadChildren: () => import('./stream-overlay/stream-overlay.module').then(m => m.StreamOverlayModule) }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }
