<div class="wrapper">
    <button class="back-button" mat-button (click)="toggleDrawer()">
        <mat-icon>arrow_back</mat-icon>
    </button>
    <div id="top" class="parallax">
        <div class="player-photo">
            <app-image style="width: 100%; height: 100%" [style.fontSize]="'3rem'" [player]="profile" [classes]="'large-player-headshot flex-center'"></app-image>
        </div>
        <span
            class="flag-icon flag-icon-{{ profile.country | countryName }}
            flag-icon"
        ></span>
        <div class="name">
            <span> {{ profile.first_name }} </span>
            <span> {{ profile.last_name }} </span>
        </div>
        <div class="rank">
            <div>Singles Rank: {{ profile.singles_rank || 'N/A' }}</div>
            <div>Doubles Rank: {{ profile.doubles_rank || 'N/A' }}</div>
        </div>
        <div *ngIf="follow_button">
            <button class="follow-button" mat-stroked-button (click)="handleFollow(profile.id)">
                {{ followedPlayers.isFollowing(this.profile.id) ? 'Unfollow' : 'Follow' }}
            </button>
        </div>
        <a *ngIf="profile.merchandise_url" class="merch-button" [href]="profile.merchandise_url" target="_blank">Shop My Gear</a>
    </div>

    <div class="courts-section" *ngIf="live_matches">
        <div *ngFor="let c of live_matches | keyvalue : matchesService.basicSort">
            <h3>{{ buildCourtName(c.key) }}</h3>
            <div *ngFor="let match of c.value.matches" class="match-card-container">
                <app-match [match]="match"></app-match>
            </div>
        </div>
    </div>

    <section id="player-detail-bio">
        <div class="glide">
            <div data-glide-el="track" class="glide__track" *ngIf="profile.details.highlight_photos && profile.details.highlight_photos.length > 0">
                <ul class="glide__slides">
                    <li class="glide__slide" *ngFor="let image of profile.details.highlight_photos">
                        <div class="slide" [style.backgroundImage]="handleImageURL(image)"></div>
                    </li>
                </ul>
            </div>
        </div>

        <hr />

        <div class="player-bio-text" *ngIf="profile.details.bio && profile.details.bio.length > 0">
            {{ profile.details.bio }}
        </div>

        <hr />
    </section>

    <div *ngIf="sponsor !== null" class="app-sponsor-banner" (click)="analytics.handleSponsorClick(sponsor, 'players details')">
        <a [href]="sponsor.url" target="_blank">
            <img [src]="sponsor.image_url" [alt]="sponsor.name" />
        </a>
    </div>

    <div class="detail-container">
        <div class="row" style="justify-content: center; border-bottom: none">
            <h3>PLAYER DETAILS</h3>
        </div>
        <div class="row">
            <div class="col-6" *ngFor="let item of displayed_details">
                <div style="font-weight: 800; color: black">
                    {{ item.label }}
                </div>
                <div>
                    {{ item.value }}
                </div>
            </div>
        </div>
    </div>

    <div class="courts-section" *ngIf="upcoming_matches">
        <h3>Next Match</h3>
        <div *ngFor="let c of upcoming_matches | keyvalue : matchesService.basicSort">
            <h3>{{ buildCourtName(c.key) }}</h3>
            <div *ngFor="let match of c.value.matches" class="match-card-container">
                <app-match [match]="match"></app-match>
            </div>
        </div>

        <div *ngIf="noMatches(upcoming_matches)">No scheduled matches in this tournament.</div>
    </div>

    <div class="courts-section" *ngIf="completed_matches">
        <h3>Completed Matches</h3>
        <div *ngFor="let c of completed_matches | keyvalue : matchesService.basicSort">
            <h3>{{ buildCourtName(c.key) }}</h3>
            <div *ngFor="let match of c.value.matches" class="match-card-container">
                <app-match [match]="match"></app-match>
            </div>
        </div>

        <div *ngIf="noMatches(completed_matches)">No completed matches in this tournament yet.</div>
    </div>
</div>

<app-trophy-detail *ngIf="trophy" (closed)="toggleTrophyDetails($event)" [trophy]="trophy" [show_img]="true"> </app-trophy-detail>
