import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'shortName'
})
export class ShortNamePipe implements PipeTransform {
    transform(value: string): string {
        const [first_name, last_name, fallback] = value;
        if (first_name === null || first_name === undefined || last_name === null || last_name === undefined) {
            return fallback;
        }
        const letter_1 = first_name.charAt(0);
        return `${letter_1}. ${last_name}`;
    }
}
