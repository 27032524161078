<a [href]="buildURL()">
  <div class="timeline-item-header" [style.color]="trivia.title_color">
    <h2 class="title" [style.text-align]="trivia.context.title.alignment" 
                      [style.font-size.px]="trivia.context.title.font_size" 
                      [ngStyle]="handleFont(trivia.context.title.font)">
      {{trivia.title}}
    </h2>
    <h5 [style.color]="trivia.see_all_color">
      {{trivia.see_all}}
    </h5>
  </div>
  <div class="subtitle" [style.color]="trivia.subtitle_color" 
                        [style.text-align]="trivia.context.subtitle.alignment" 
                        [style.font-size.px]="trivia.context.subtitle.font_size" 
                        [ngStyle]="handleFont(trivia.context.subtitle.font)">
    {{trivia.subtitle}}
  </div>
  <div class="card" [style.border]="trivia.context.card_border ? '2px solid' + trivia.context.card_border : '' " 
                    [style.text-align]="trivia.context.body_text.alignment" 
                    [style.font-size.px]="trivia.context.body_text.font_size" 
                    [ngStyle]="handleFont(trivia.context.body_text.font)">
    <mat-card class="mat-elevation-z4" [style.color]="trivia.body_color">
      <mat-card-header>
        <div mat-card-avatar class="header-image" [style.backgroundColor]='trivia.theme_color'><img src="https://res.cloudinary.com/bleachr/image/upload/v1544636970/trophyFilled_3x_yvvrjc.png" alt=""></div>
        <mat-card-title>Trivia</mat-card-title>
      </mat-card-header>
      <mat-card-content [style.border-top]="trivia.context.content_divider ? '2px solid' + trivia.context.content_divider : '' ">
        <h4>
          {{ trivia.data[0].question}}
        </h4>
      </mat-card-content>
    </mat-card>
  </div>
  </a>