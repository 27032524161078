import { ScoreUpdateModel } from './match';

export interface FilterModel {
    label: string;
    value: string;
    order?: number;
    img_src?: string;
}

export interface DisplayMatchModel {
    [key: string]: CourtModel;
}

export interface DateFilterModel {
    label: string;
    value: string;
    isToday: boolean;
    tomorrowButton?: boolean;
}

export interface NewFilterModel {
    type: string;
    value: string;
    analytics_value: string;
}

export interface ToggleDisplayModel {
    id: string;
    type: string;
    disabled?: boolean;
    filters: FilterModel[];
}

export interface ToggleAnalyticsPayload {
    name: string;
    parameters: {
        toggled: string;
    };
    screen_name: string;
}

export interface ActiveFilterModel {
    status?: string;
    type?: string;
    date?: string;
    gender?: string;
    match_type?: string;
}

export interface CourtModel {
    [key: string]: {
        order: number;
        matches: ScoreUpdateModel[];
    };
}

export interface MatchStatsModel {
    aces: TeamStatValueModel<number>;
    double_faults: TeamStatValueModel<number>;
    first_serve_percentage: TeamStatValueModel<number>;
    first_serve_points_won: TeamStatValueModel<number>;
    second_serve_points_won: TeamStatValueModel<number>;
    break_points_saved: TeamStatValueModel<number>;
    break_points_won: TeamStatValueModel<number>;
    service_games_played: TeamStatValueModel<number>;
    first_return_points_won: TeamStatValueModel<number>;
    second_return_points_won: TeamStatValueModel<number>;
    total_points_won: TeamStatValueModel<number>;
    total_service_points_won_percentage: TeamStatValueModel<string>;
    total_return_points_won_percentage: TeamStatValueModel<string>;
    total_points_won_percentage: TeamStatValueModel<string>;
    total_first_serve_points: TeamStatValueModel<number>;
    total_second_serve_points: TeamStatValueModel<number>;
}
interface TeamStatValueModel<T> {
    team1: T;
    team2: T;
}
