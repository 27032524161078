<div class="timeline-item-header" [style.color]="fan_stream.title_color">
  <h2 class="title" [style.text-align]="fan_stream.context.title.alignment"
    [style.font-size.px]="fan_stream.context.title.font_size" [ngStyle]="handleFont(fan_stream.context.title.font)">
    {{fan_stream.title}}
  </h2>
  <h5 *ngIf="fan_stream.see_all" [style.color]="fan_stream.see_all_color">
    {{ fan_stream.see_all }}
  </h5>
</div>
<div class="subtitle" *ngIf="fan_stream.subtitle" [style.color]="fan_stream.subtitle_color"
  [style.text-align]="fan_stream.context.subtitle.alignment"
  [style.font-size.px]="fan_stream.context.subtitle.font_size" [ngStyle]="handleFont(fan_stream.context.subtitle.font)">
  {{fan_stream.subtitle}}
</div>
<div class="siema-{{fan_stream.position}}" [style.color]="fan_stream.body_color"
  [style.text-align]="fan_stream.context.body_text.alignment"
  [style.font-size.px]="fan_stream.context.body_text.font_size"
  [ngStyle]="handleFont(fan_stream.context.body_text.font)">
  <div class="slide" *ngFor="let post of fan_stream.data">
    <a [href]="buildURL(post.id)">
      <mat-card class="mat-elevation-z4"
        [style.border]="fan_stream.context.card_border ? '2px solid ' + fan_stream.context.card_border : '' "
        [ngStyle]="handleShadow(fan_stream.context.card_shadow)">
        <div *ngIf="post.photo_url !== null" class="fan-stream-post-image" mat-card-image
          [style.backgroundImage]="'url('+ checkURL(post.photo_url)+')'"
          [style.border-bottom]="fan_stream.context.content_divider ? '2px solid' + fan_stream.context.content_divider : '' ">
        </div>
        <div *ngIf="post.video_url !== null && post.photo_url === null" class="video-placeholder">
          <mat-icon>play_circle_filled</mat-icon>
        </div>
        <mat-card-footer>
          <div class="fan-details">
            <img class="fan-profile-photo" [src]='checkURL(post.fan.profile_photo_thumbnail)' alt="profile photo">
            <div>
              {{post.fan.name}}
            </div>
          </div>
          <div class="fan-post">
            {{clipContentString(post.content)}}
          </div>
        </mat-card-footer>
      </mat-card>
    </a>
  </div>
</div>