import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { addHours, isAfter } from 'date-fns';
import { environment } from 'src/environments/environment';
import { HttpOptionsHeaders } from '../services/http-option-headers';
import { filter } from 'rxjs';
import { AppStringModel } from '../_interfaces';

@Injectable({
    providedIn: 'root'
})
export class AppStringsService {
    private helper = new HttpOptionsHeaders();
    public cache: Map<string, string> = new Map();
    public supported_locales: Map<string, string> = new Map([
        ['en', 'English'],
        ['fr', 'French'],
        ['es', 'Spanish']
    ]);

    constructor(
        private http: HttpClient,
        private router: Router
    ) { }

    async handleInit() {
        this.setDfaultLang();
        await this.buildLocalStore();
        await this.buildCache();

        this.router.events.pipe(
            filter(e => e instanceof NavigationEnd)
        ).subscribe(() => {
            this.checkTTL();
        });
    }

    private setDfaultLang() {
        if (!localStorage.getItem('perferedLanguage')) {
            localStorage.setItem('perferedLanguage', 'en');
        }
    }

    private async checkTTL() {
        if (!localStorage.getItem('appStrings')) {
            return;
        }

        const local_cache: { ttl: string, cache: AppStringModel[] } = JSON.parse(localStorage.getItem('appStrings'));

        if (isAfter(new Date(), new Date(local_cache.ttl))) {
            await this.buildLocalStore();
            this.buildCache();
        }
    }

    private async buildLocalStore() {
        const headers = this.helper.buildLocaleRequestHeader(localStorage.getItem('perferedLanguage'));

        return this.http.get<{ data: AppStringModel[] }>(`${environment.FE_API_FOUR}/strings`, headers).toPromise()
            .then(res => {
                const cache = { ttl: addHours(new Date(), 1), cache: res.data }
                localStorage.setItem('appStrings', JSON.stringify(cache));
                return res.data;
            })
            .catch(error => {
                console.error(error);
                return [];
            });
    }

    private buildCache(): Promise<null> {
        return new Promise((completed) => {
            const local_cache: { ttl: string, cache: AppStringModel[] } = JSON.parse(localStorage.getItem('appStrings'));

            local_cache.cache.forEach((k) => {
                this.cache.set(k.key, k.string);
            });

            completed(null);
        })
    }

    getPerferedLanguage(): string {
        return localStorage.getItem('perferedLanguage');
    }

    getDisplayName(locale?: string) {
        if (!locale) {
            const locale_store: string = localStorage.getItem('perferedLanguage');
            return this.supported_locales.has(locale_store) ? this.supported_locales.get(locale_store) : '';
        }

        return this.supported_locales.has(locale) ? this.supported_locales.get(locale) : '';
    }

    getAppString(key_name: string): string {
        return this.cache.has(key_name) ? this.cache.get(key_name) : '';
    }

    getReplacedValue(key: string, replacements: Array<string>) {
        const app_string = this.cache.has(key) ? this.cache.get(key) : '';
        let formatted_string = app_string;
        // replacements need to be in chronological order that they need to appear
        replacements.forEach(replacement => {
            formatted_string = formatted_string.replace('%@', replacement);
        });
        return formatted_string;
    }

    async setPerferedLanguage(locale: string) {
        localStorage.setItem('perferedLanguage', locale);
        await this.buildLocalStore();
        await this.buildCache();
        return;
    }
}
