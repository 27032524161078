<div class="team" *ngIf="!team_data.is_bye; else byeBlock">
    <div class="player-photos">
        <div class="player-one player-photo-frame" [class.following]="isFollowing(team_data.player1.id)">
            <app-image [player]="team_data.player1" [classes]="'small-player-headshot player-photo flex-center'"> </app-image>
        </div>
        <div class="player-photo-frame player-one-partner" [class.following]="isFollowing(team_data.player2.id)" *ngIf="team_data.player2.id">
            <app-image [player]="team_data.player2" [classes]="'small-player-headshot player-photo partner flex-center'"> </app-image>
        </div>
    </div>
    <div class="team-seed">
        {{ team_data.player1 | seedRules }}
    </div>
    <div class="player-info">
        <div class="player-name" style="margin-left: 1%">
            <div>
                {{ [team_data.player1.first_name, team_data.player1.last_name, 'TBD'] | shortName }}
            </div>
            <div class="partner" *ngIf="team_data.player2.id">
                {{ [team_data.player2.first_name, team_data.player2.last_name, 'TBD'] | shortName }}
            </div>
        </div>
        <div class="player-status">
            <mat-icon style="color: #c9f364; font-size: 0.8rem" *ngIf="team_data.is_serving && !matchWon(team_data.is_winner, team_data.is_winner) && match_status !== 'finished'">
                fiber_manual_record
            </mat-icon>
            <mat-icon style="color: #4caf50; font-size: 0.8rem" *ngIf="team_data.is_winner"> done</mat-icon>
        </div>
    </div>
    <div class="match-sets-points">
        <div class="set" *ngFor="let set of team_data.sets; let i = index" [ngClass]="{ loser: !team_data.is_winner, setwin: team_data.is_winner }">
            {{ set.games }} <sup *ngIf="set.tiebreak !== 0">{{ set.tiebreak }}</sup>
        </div>
        <div class="team-points" [ngClass]="{ 'live-update': team_data.live_update, points: !team_data.live_update }" *ngIf="checkMatchStatusForPointDisplay(match_status)">
            {{ team_data.points || 0 }}
        </div>
    </div>
</div>

<ng-template #byeBlock>
    <div id="team2" class="placeholder">
        <div class="flag"></div>
        <div class="player-frame">
            <div class="player-photo badge"></div>
        </div>
        <div class="team-seed">-</div>
        <div class="player-name">BYE</div>
        <div></div>
        <div class="set-scores"></div>
        <div class="team-score"></div>
    </div>
</ng-template>
