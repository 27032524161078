export interface SponsorModel {
    enabled: boolean;
    id: string;
    image_url: string;
    key: string;
    locale: string;
    location_id: string;
    name: string;
    occurrence: number;
    priority: number;
    team_id: string;
    url: string;
}
