<div id="header-cont" style="width: 100vw" #headerRef (click)="handleHeaderLink()"></div>

<div id="timeline">
    <div class="timeline-item" *ngFor="let ghost of ghosts">
        <app-ghost style="z-index: 1000" [ghost]="ghost" [@fadeOut]="!ghosts.length" fxLayout="row wrap"></app-ghost>
    </div>
    <div
        class="timeline-item noBlue"
        *ngFor="let item of timeline; let i = index"
        [attr.id]="i"
        [style.backgroundColor]="item.background_color"
        [hidden]="item.card_type === 'header'"
        appObserver
        [custom]="item"
    >
        <app-homescreen-fan-stream-component *ngIf="item.card_type === 'fan_stream_post' && item.data.length > 0" [fan_stream]="item"></app-homescreen-fan-stream-component>

        <app-homescreen-tennis-match-card *ngIf="item.card_type === 'tennis_match'" [match_details]="item"> </app-homescreen-tennis-match-card>

        <app-homescreen-sponsor-card *ngIf="item.card_type === 'sponsor' && item.data.length > 0" [data]="item"> </app-homescreen-sponsor-card>

        <app-homescreen-social-feed-card
            *ngIf="item.card_type === 'feed_item' && item.item_type === 'card' && item.data.length > 0"
            [social_feed_item]="item"
        ></app-homescreen-social-feed-card>

        <app-homescreen-social-feed-component
            *ngIf="item.card_type === 'feed_item' && item.item_type === 'component' && item.data.length > 0"
            [social_feed]="item"
        ></app-homescreen-social-feed-component>

        <app-homescreen-enhanced-articles-component
            *ngIf="item.card_type === 'enhanced_article' && item.data.length > 0"
            [enhanced_articles]="item"
            [identifier]="account_identifier"
        >
        </app-homescreen-enhanced-articles-component>

        <app-homescreen-challenge-card *ngIf="item.card_type === 'challenge' && item.data.length > 0" [challenge]="item"> </app-homescreen-challenge-card>

        <app-homescreen-trivia-card *ngIf="item.card_type === 'trivia' && item.data.length > 0" [trivia]="item"> </app-homescreen-trivia-card>

        <app-homescreen-standlone-card *ngIf="item.card_type === 'standalone' && item.data.length > 0" [custom]="item"> </app-homescreen-standlone-card>

        <app-homescreen-followed-player-card *ngIf="item.card_type === 'tennis_player' && item.data.length > 0" [followed_players]="item"></app-homescreen-followed-player-card>

        <app-homescreen-sponsor-rotation *ngIf="item.card_type === 'sponsor_placeholder'" [identifier]="account_identifier" [locale]="timeline_locale">
        </app-homescreen-sponsor-rotation>

        <app-homescreen-enhanced-articles-component
            *ngIf="item.card_type === 'contributed_article' && item.data.length > 0"
            [enhanced_articles]="item"
            [identifier]="account_identifier"
        >
        </app-homescreen-enhanced-articles-component>

        <app-homescreen-web-view *ngIf="item.card_type === 'web_view'" [data]="item"> </app-homescreen-web-view>
    </div>
</div>
