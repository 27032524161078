export interface ReferralModel {
    coin_bonus: number,
    coins_earned: string,
    created_at: string,
    display_name: string,
    fan_id: string,
    metrics: MetricsModel[],
    referral_period_ends_at: string,
    referral_period_id: string,
    referral_period_starts_at: string,
    squad: any[]
}

export interface MetricsModel {
    default_payout: number,
    default_threshold: number,
    id: string,
    label: string,
    key: string,
    progress: number
}