import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { trigger } from '@angular/animations';
import { fadeIn, fadeOut } from '../../../utils/fade-animations';
import { ApiService } from '../../services/api.service';
import { ActivatedRoute } from '@angular/router';
import { AnalyticService } from '../../services/analytic.service';
import { delay, map, Observable, pluck, tap } from 'rxjs';

@Component({
    selector: 'app-homescreen-core',
    animations: [
        trigger('fadeOut', fadeOut()),
        trigger('fadeIn', fadeIn(':enter'))
    ],
    templateUrl: './homescreen-core.component.html',
    styleUrls: ['./homescreen-core.component.scss']
})
export class HomescreenCoreComponent implements OnInit {
    @ViewChild('headerRef', { static: true }) headerRef: ElementRef;
    public timeline: Observable<any[]>;
    public header_config: string;
    public account_identifier: string;
    public header_color: string;
    public theme_color: string;
    public timeline_locale: string;
    public contextDefaults;
    private RESPONSE_DELAY = 0;
    public ghosts = [];
    public header_link: string;

    constructor(
        private api: ApiService,
        private route: ActivatedRoute,
        private analytics: AnalyticService
    ) {
        this.account_identifier = this.route.snapshot.queryParams.account_id;
        this.ghosts.length = 4;
        this.contextDefaults = {
            title: {
                alignment: 'left',
                font_size: '',
                font: 'Default',
            },
            subtitle: {
                alignment: 'left',
                font_size: '',
                font: 'Default',
            },
            body_text: {
                alignment: 'left',
                font_size: '',
                font: 'Default',
            },
            card_border: '',
            card_shadow: '#33333333',
            card_text_border: '',
            aspect_ratio: 'SixteenByTen'
        };
        this.theme_color = null;
    }

    ngOnInit() {
        if (this.route.snapshot.queryParamMap.has('theme')) {
            this.theme_color = `#${this.route.snapshot.queryParamMap.get('theme')}`;
        }
        if (this.route.snapshot.queryParamMap.has('header')) {
            this.header_config = this.route.snapshot.queryParamMap.get('header');
        }
        if (this.route.snapshot.queryParamMap.has('locale')) {
            this.timeline_locale = this.route.snapshot.queryParamMap.get('locale');
        } else {
            this.timeline_locale = 'en';
        }
        this.buildView();
    }

    async buildView() {
        this.analytics.setAccount(this.account_identifier);
        await this.getTimelineHub(this.account_identifier);
        this.getTimeline(this.account_identifier).subscribe({
            next: (timeline) => {
                this.timeline = this.buildTheme(timeline);
            }
        });
    }

    buildHeaderConfig(timeline: { [key: string]: any }, param: string): { [key: string]: string } {
        let config: { [key: string]: any };
        const context = timeline.context;
        switch (param) {
            case 'a':
                // Android borked the implementation of this. After Rogers 2019
                // fix with the android team
                // i'm sorry - dan fischer 7.26.19
                config = context.beta;
                break;
            case 'b':
                config = context.alpha;
                break;
            default:
                config = {
                    header_aspect: context.header_aspect,
                    header_background_url: context.header_background_url,
                    header_color: context.header_color,
                    header_link_action: context.header_link_action,
                    headline_background_color: context.headline_background_color
                };
        }
        return config;
    }

    getTimelineHub(identifier: string) {
        return new Promise<void>((completed, rejected) => {
            this.api.getTimelineHub(identifier, this.timeline_locale).subscribe(
                data => {
                    data['data'].forEach((timeline) => {
                        if (timeline.primary) {
                            const ref = this.headerRef.nativeElement;
                            const headerConfig = this.buildHeaderConfig(timeline, this.header_config);
                            this.header_color = headerConfig.header_color;
                            this.header_link = headerConfig.header_link_action;
                            if ((timeline.context !== undefined || timeline.context !== null) && headerConfig.header_background_url !== '') {
                                const img = this.createHeaderImage(ref, headerConfig.header_background_url);
                                ref.appendChild(img);
                            } else {
                                ref.style.display = 'none';
                            }
                            completed();
                        }
                    });
                },
                error => {
                    console.error(error);
                }
            );
        });
    }

    getTimeline(identifier: string) {
        return this.api.getTimeline(identifier, this.timeline_locale).pipe(
            pluck('data'),
            map(this.filterTimeline),
            map(this.sortCards),
            delay(this.RESPONSE_DELAY),
            tap(() => this.ghosts = [])
        );
    }

    buildTheme(timeline) {
        for (const item of timeline) {
            item.context = Object.assign({}, this.contextDefaults, item.context);
        }
        // apply theme
        timeline.forEach(element => {
            element.theme_color = this.theme_color;
        });
        return timeline;
    }

    sortCards(cards) {
        return cards.concat().sort((a, b) => {
            if (Number(a.position) > Number(b.position)) {
                return 1;
            }
            if (Number(a.position) < Number(b.position)) {
                return -1;
            }
            return 0;
        });
    }

    filterTimeline(cards) {
        return cards.filter((item) => {
            if (item.card_type === 'tennis_match' || item.card_type === 'sponsor_placeholder') {
                return true;
            }
            if (item.data !== undefined && item.data.length === 0) {
                return false;
            } else {
                return true;
            }
        });
    }

    createHeaderImage(native: Element, imgSrc: string): HTMLImageElement {
        const img = new Image();
        img.onload = () => {
            this.scrollHeader(native.firstElementChild);
        };
        img.setAttribute('id', 'header-img');
        img.style.width = '100%';
        img.style.display = 'block';
        img.src = imgSrc;
        return img;
    }

    scrollHeader(elem: Element): void {
        const intViewportHeight = window.innerHeight;
        const offset = elem.getBoundingClientRect().height - intViewportHeight;
        window.scrollTo({ left: 0, top: offset, behavior: 'smooth' });
    }

    handleHeaderLink() {
        window.open(this.header_link, '_blank');
    }
}
