import { Component, OnInit, Input, AfterViewInit, OnDestroy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { helpers } from '../../helpers';
import { FontService } from '../../services/font-service.service';
declare var Siema: any;

@Component({
    selector: 'app-homescreen-followed-player-card',
    templateUrl: './homescreen-followed-player-card.component.html',
    styleUrls: ['./homescreen-followed-player-card.component.scss']
})
export class HomescreenFollowedPlayerCardComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() followed_players;
    public image_hosts: { [key: string]: any };
    public siema;
    public mutationObserver: MutationObserver;
    public handleFont: Function;
    constructor(font: FontService, private _sanitizer: DomSanitizer) {
        this.siema = null;
        this.handleFont = font.handleFont;
        // the mutation observer is to watch for unintended changes to the carousel.
        this.mutationObserver = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.type === 'childList') { // childList means that an element was removed from the DOM which is what we need to fix
                    this.mutationObserver.disconnect(); // disconnect from the old element that doesn't exist any more
                    const $starterContainer: HTMLElement = document.getElementById('starter').parentElement;
                    const curr_width = Number(($starterContainer.style.width).slice(0, -1));
                    // reconnect to the new element because it's new
                    this.mutationObserver.observe($starterContainer, { attributes: true, childList: true, subtree: true });
                    const siema_length = $starterContainer.parentElement.children.length;
                    const computed_width = ((100 / siema_length) * 2).toFixed(2);
                    if (curr_width.toFixed(2) !== computed_width) {
                        $starterContainer.style.width = `${String(curr_width * 2)}%`;
                    }
                    // Double the element width and add the percent back:
                }
            });
        });
        this.image_hosts = {
            'https://www.wtatennis.com': { regex: /https:\/\/www.wtatennis.com/, replace: 'wta' },
            'http://www.wtatennis.com': { regex: /http:\/\/www.wtatennis.com/, replace: 'wta' },
            'http://ws.protennislive.com': { regex: /http:\/\/ws.protennislive.com/, replace: 'atp' },
            'https://www.atpworldtour.com': { regex: /https:\/\/wss.atpworldtour.com/, replace: 'atp1' }
        };
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
        if (this.siema === null) {
            this.siema = new Siema({
                selector: `.siema-${this.followed_players.position}`,
                perPage: 4.25,
                onInit: () => {
                    // In order let the title area (which is bigger than a player head) play nice in the Siema carousel we need to double it's size
                    // The container element Siema makes has a width meassured in px while it's children containers use percents.
                    // Rip off the percent:
                    const $starterContainer: HTMLElement = document.getElementById('starter').parentElement;
                    this.mutationObserver.observe($starterContainer, { attributes: true, childList: true, subtree: true });
                    const curr_width = Number(($starterContainer.style.width).slice(0, -1));
                    // Test to make sure we're not doubling up
                    const siema_length = $starterContainer.parentElement.children.length;
                    const computed_width = ((100 / siema_length) * 2).toFixed(2);
                    if (curr_width.toFixed(2) !== computed_width) {
                        $starterContainer.style.width = `${String(curr_width * 2)}%`;
                    }
                    // Double the element width and add the percent back:
                }
            });
        }
    }
    ngOnDestroy() {
        this.mutationObserver.disconnect();
    }
    buildURL(player_id: string) {

        const fake_url = `${window.location.href}/followed_player/${player_id}`;

        return fake_url;
    }

    image_url(image_url: string, first_name: string, last_name: string) {
        if (image_url === null || image_url === undefined) {
            return this.buildNameBackground(first_name, last_name);
        } else if (image_url) {
            return this._sanitizer.bypassSecurityTrustStyle(`url('${helpers.ensureHTTPS(image_url)}')`);
        }
    }

    formatCloudinaryFetch(image_url: string, last_name: string) {

        const format = this.setRegex(image_url);

        let cloudinary_url = image_url.replace(format.regex, format.replace);

        //
        // NOTE
        // The code below hacks together url because this site => http://ws.protennislive.com
        // doesn't appear to return an actual url so we are manually hacking in
        // data we have on cloudinary
        //

        if (cloudinary_url.includes('atp/')) {
            if (last_name.includes('-')) {
                last_name = last_name.replace('-', '_');
            } else if (last_name.includes(' ')) {
                last_name = last_name.replace(' ', '_');
            }

            cloudinary_url = `atp/-/media/tennis/players/head-shot/2019/${last_name}_head_ao19.png`;
        }

        return this._sanitizer.bypassSecurityTrustStyle(`url('https://res.cloudinary.com/bleachr/image/upload/w_200,h_200,c_thumb,g_face/${cloudinary_url}')`);

    }

    setRegex(image_url: string) {
        for (const site in this.image_hosts) {
            if (image_url.includes(site)) {
                return this.image_hosts[site];
            }
        }
    }
    clipName(value: string): string {
        if (value.length > 11) {
            return `${value.slice(0, 11)}...`;
        }
        return value;
    }
    buildNameBackground(first_name: string, last_name: string) {
        const letter_1 = first_name.charAt(0);
        const letter_2 = last_name.charAt(0);
        const name = `${letter_1.toLocaleUpperCase()}${letter_2.toLocaleUpperCase()}`;

        return name.trim();
    }
}
