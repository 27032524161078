import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'snakecaseToHuman'
})
export class SnakecaseToHumanPipe implements PipeTransform {
    transform(value: string): string {
        return value
            .split('_')
            .map((w) => w.charAt(0).toUpperCase() + w.slice(1))
            .join(' ');
    }
}
