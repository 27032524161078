<div *ngIf="followed_players.data.length > 0" class="player-container">
  <div class="siema-{{followed_players.position}}">
    <div id="starter">
      <div class="timeline-item-header" [style.color]="followed_players.title_color"
        [style.text-align]="followed_players.context.title.alignment"
        [style.font-size.px]="followed_players.context.title.font_size"
        [ngStyle]="handleFont(followed_players.context.title.font)" [style.color]="followed_players.title_color">
        <h3 *ngIf='followed_players.title'>
          {{ followed_players.title }}
        </h3>
      </div>
      <div *ngIf='followed_players.subtitle' class="subtitle" [style.color]="followed_players.subtitle_color"
        [style.text-align]="followed_players.context.title.alignment"
        [style.font-size.px]="followed_players.context.title.font_size" [style.color]="followed_players.subtitle_color"
        [ngStyle]="handleFont(followed_players.context.subtitle.font)">
        {{ followed_players.subtitle }}
      </div>
    </div>
    <!-- Tricking Siema into thinking it has more children to display than it really does -->
    <div style="display:none;">I don't exist</div>

    <div *ngFor="let player of followed_players.data" style="position: relative;">
      <a [href]="buildURL(player.id)" style="display: flex; justify-content: center;">
        <div style="position: relative;">
          <div [style.backgroundImage]='image_url(player.photo, player.first_name, player.last_name)' alt="player photo"
            class="player-photo" *ngIf="player.photo">
          </div>
          <div class="player-badge" *ngIf="!player.photo">
            {{buildNameBackground(player.first_name, player.last_name)}}
          </div>
          <mat-icon [style.color]="followed_players.body_color" style="position: absolute; left: -8px; top: -8px;">
            add_circle_outline</mat-icon>
        </div>
      </a>

      <span style="position: absolute;
          top: 62%;
          left: 50%;
          margin-right: -50%;
          transform: translate(-50%, -50%)" class="flag-icon flag-icon-{{ player.country.toLowerCase() }}">
      </span>
      <p [style.color]="followed_players.body_color" style="font-size: .75em; margin: .5em 0; text-align: center;">
        {{ player.first_name }}
        <br>
        {{ clipName(player.last_name) }}</p>
    </div>
  </div>

</div>