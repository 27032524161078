import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpOptionsHeaders } from './http-option-headers';
import { environment } from 'src/environments/environment';
import { FanService } from './fan.service';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs';
import { getUser } from '../services/helpers';
import { FanTrophyModel, TrophyAchievementPayloadModel, TrophyModel } from '../_interfaces';

@Injectable({
    providedIn: 'root'
})

export class TrophiesService {

    public buildHeaders = new HttpOptionsHeaders();

    constructor(private http: HttpClient, private fanService: FanService, private toastr: ToastrService) { }

    getFanTrophies(fan_id: string): Promise<FanTrophyModel[]> {
        const headers = this.buildHeaders.buildRequestHeaders();
        return this.http.get<{ data: FanTrophyModel[], error: string }>(`${environment.FE_API_FOUR}/fan_trophies?fan_id=${fan_id}`, headers).toPromise()
            .then((res) => {
                return res.data;
            })
            .catch((error) => {
                console.error(error);
                return [];
            });
    }

    getTrophies(): Promise<TrophyModel[]> {

        const headers = this.buildHeaders.buildRequestHeaders();

        return this.http.get<{ data: TrophyModel[], error: string }>(`${environment.FE_API_FOUR}/trophies`, headers).toPromise()
            .then((res) => {
                return res.data;
            })
            .catch((error) => {
                console.error(error);
                return [];
            });
    }

    getTrophy(id: string, fan_id: string): Promise<any> {
        const headers = this.buildHeaders.buildRequestHeaders();

        return this.http.get<{ data: FanTrophyModel[], error: string }>(`${environment.FE_API_FOUR}/trophies/${id}?fan_id=${fan_id}`, headers).toPromise()
            .then((res) => {
                return res.data;
            })
            .catch((error) => {
                console.error(error);
                return [];
            });
    }

    postTrophyAchievement(payload: TrophyAchievementPayloadModel) {
        const headers = this.buildHeaders.buildRequestHeaders();
        return this.http.post<{ data: FanTrophyModel }>(`${environment.FE_API_FOUR}/fan_trophies`, payload, headers).toPromise()
            .then((res) => {
                return res.data;
            })
            .catch((error) => {
                console.error(error);
                return null;
            });

    }

    async sendTrophyAchievement(key: string, value: string, context: any) {
        if (getUser() === null) {
            return;
        }
        const fan_id = this.fanService.getFanId();

        const data = await this.postTrophyAchievement({ key, value });
        if (data && data.new_level_achieved) {
            this.toastr.success('New Level Achieved', `${data.name}`, { timeOut: 3000, tapToDismiss: true })
                .onTap
                .pipe(take(1))
                .subscribe(() => {
                    if (fan_id) {
                        this.getTrophy(data.id, fan_id).then(trophy => {
                            if (trophy) {
                                context.toggleTrophyDetails(trophy);
                            }
                        });
                    }
                });
        }
    }
}
