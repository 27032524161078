import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

export class HttpOptions {

    basicHeaders(identifier?: string): { headers: HttpHeaders } {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'X-Bleachr': identifier || environment.identifier
            })
        };
        return httpOptions;
    }

    // authHeaders(identifier?: string, fp?: string): { headers: HttpHeaders } {
    //     const httpOptions = {
    //         headers: new HttpHeaders({
    //             'Content-Type': 'application/json',
    //             'X-Bleachr': identifier || environment.identifier,
    //             Authorization: `Token token=${new User().getJWT()}`,
    //             'fingerprint': fp || ''
    //         })
    //     };
    //     return httpOptions;
    // }

}
