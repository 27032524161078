import { Component, Inject, OnInit } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { DomSanitizer } from '@angular/platform-browser';

export interface WebViewDataModel {
    url: string;
}
@Component({
    selector: 'app-web-view-sheet',
    templateUrl: './web-view-sheet.component.html',
    styleUrls: ['./web-view-sheet.component.scss']
})
export class WebViewSheetComponent implements OnInit {
    public safe_url = null;
    constructor(
        private sanitizer: DomSanitizer,
        private bottomSheetRef: MatBottomSheetRef<WebViewSheetComponent>,
        @Inject(MAT_BOTTOM_SHEET_DATA) public data: WebViewDataModel) { }

    ngOnInit(): void {
        this.safe_url = this.sanitizer.bypassSecurityTrustResourceUrl(this.data.url);
    }

    closeSheet() {
        this.bottomSheetRef.dismiss();
    }
}
