export interface RoundNameTranslation {
    [key: string]: string
}

export const RoundTitleTranslations: Map<string, Map<string, RoundNameTranslation>> = new Map([
    ['round 32', new Map([
        ['qual. Rnd 1', { en: 'Round 1', fr: '1er tour', de: 'Qualifikationsrunde 1' }],
        ['qual. Rnd 2', { en: 'Round 2', fr: '2nd tour', de: 'Qualifikationsrunde 2' }],
        ['rnd of 16', { en: 'Round of 16', fr: 'Huitièmes de finale', de: '1/8-Finale' }],
        ['rnd of 32', { en: 'Round 1', fr: '1ème tour', de: '1/16-Finale' }],
        ['quarter-finals', { en: 'Quarter-finals', fr: 'Quarts de finale', de: '1/4-Finale' }],
        ['quarterfinals', { en: 'Quarterfinals', fr: 'Quarts de finale', de: '1/4-Finale' }],
        ['semi-finals', { en: 'Semi-finals', fr: 'Demi-finales', de: '1/2-Finale' }],
        ['semifinals', { en: 'Semi-finals', fr: 'Demi-finales', de: '1/2-Finale' }],
        ['finals', { en: 'Finals', fr: 'Finale', de: 'Final' }]
    ])],
    ['round 64', new Map([
        ['qual. rnd 1', { en: 'Round 1', fr: '1er tour', de: 'Qualifikationsrunde 1' }],
        ['qual. rnd 2', { en: 'Round 2', fr: '2ème tour', de: 'Qualifikationsrunde 2' }],
        ['rnd of 16', { en: 'Round of 16', fr: 'Huitièmes de finale', de: '1/8-Finale' }],
        ['rnd of 32', { en: 'Round 2', fr: '2ème tour', de: '1/16-Finale' }],
        ['rnd of 64', { en: 'Round 1', fr: '1er tour', de: '1/32-Finale' }],
        ['quarter-finals', { en: 'Quarter-finals', fr: 'Quarts de finale', de: '1/4-Finale' }],
        ['quarterfinals', { en: 'Quarterfinals', fr: 'Quarts de finale', de: '1/4-Finale' }],
        ['semi-finals', { en: 'Semi-finals', fr: 'Demi-finales', de: '1/2-Finale' }],
        ['semifinals', { en: 'Semi-finals', fr: 'Demi-finales', de: '1/2-Finale' }],
        ['finals', { en: 'Finals', fr: 'Finale', de: 'Final' }],
        ['1er tour', { fr: '1er tour' }],
        ['2ème tour', { fr: '2ème tour' }],
        ['3ème tour', { fr: 'Huitièmes de finale' }],
        ['quart de finale', { fr: 'Quart de finale' }],
        ['demi-finale', { fr: 'Demi-finale' }],
        ['finale', { fr: 'Finale' }]
    ])],
    ['round 128', new Map([
        ['qual. rnd 1', { en: 'Round 1', fr: '1er tour', de: 'Qualifikationsrunde 1' }],
        ['qual. rnd 2', { en: 'Round 2', fr: '2ème tour', de: 'Qualifikationsrunde 2' }],
        ['rnd of 16', { en: 'Round of 16', fr: 'Huitièmes de finale', de: '1/8-Finale' }],
        ['rnd of 32', { en: 'Round 3', fr: '3ème tour', de: '1/16-Finale' }],
        ['rnd of 64', { en: 'Round 2', fr: '2er tour', de: '1/32-Finale' }],
        ['rnd of 128', { en: 'Round 1', fr: '1er tour', de: '1/64-Finale' }],
        ['quarter-finals', { en: 'Quarter-finals', fr: 'Quarts de finale', de: '1/4-Finale' }],
        ['quarterfinals', { en: 'Quarterfinals', fr: 'Quarts de finale', de: '1/4-Finale' }],
        ['semi-finals', { en: 'Semi-finals', fr: 'Demi-finales', de: '1/2-Finale' }],
        ['semifinals', { en: 'Semi-finals', fr: 'Demi-finales', de: '1/2-Finale' }],
        ['finals', { en: 'Finals', fr: 'Finale', de: 'Final' }],
        ['1er tour', { fr: '1er tour' }],
        ['2ème tour', { fr: '2ème tour' }],
        ['3ème tour', { fr: 'Huitièmes de finale' }],
        ['quart de finale', { fr: 'Quart de finale' }],
        ['demi-finale', { fr: 'Demi-finale' }],
        ['finale', { fr: 'Finale' }]
    ])]
])
