import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FanService } from './fan.service';
import { getUser } from './helpers';
import { HttpOptionsHeaders } from './http-option-headers';
import * as uuid from 'uuid';

const Android = window.Android || null;

@Injectable({
    providedIn: 'root'
})
export class FollowedPlayersService {
    public followed_player_ids: Array<string> = [];
    public buildHeaders = new HttpOptionsHeaders();

    constructor(
        private fanAPI: FanService,
        private http: HttpClient
    ) { }

    async buildPlayerFollows() {
        if (getUser()) {
            this.followed_player_ids = await this.fanAPI.getFanPlayerFollows();
        }
    }

    getPlayerFollows(): Array<string> {
        return this.followed_player_ids;
    }

    unfollowPlayer(id: string, idx) {
        this.followed_player_ids.splice(idx, 1);
        Android.unfollowPlayer(id);
    }

    followPlayer(id: string) {
        this.followed_player_ids.push(id);
        Android.followPlayer(id);
    }

    isFollowing(id: string): boolean {
        return this.followed_player_ids.includes(id);
    }

    async followPlayerWeb(fan_id: string, tennis_player_ids) {
        const headers = this.buildHeaders.buildRequestHeaders();
        const id = uuid.v4();
        const account_id = environment.id;

        return await this.http.post(`${environment.FE_API_THREE}/tennis/players/bulk_follow_players`, { id, tennis_player_ids, fan_id, account_id }, headers).toPromise()
            .then((data) => {
                return data['data'];
            })
            .catch((err) => {
                console.error(err);
                return null;
            })
    }

    async unfollowPlayerWeb(tennis_player_id: string) {
        const headers = this.buildHeaders.buildRequestHeaders();

        return await this.http.post(`${environment.FE_API_THREE}/tennis/players/unfollow`, { tennis_player_id }, headers).toPromise()
            .then((data) => {
                return data['data'];
            })
            .catch((err) => {
                console.error(err);
                return null;
            })
    }
}
