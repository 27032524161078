import { Component, Input } from '@angular/core';
import { helpers } from '../../helpers';
import { FontService } from '../../services/font-service.service';
@Component({
    selector: 'app-homescreen-social-feed-card',
    templateUrl: './homescreen-social-feed-card.component.html',
    styleUrls: ['./homescreen-social-feed-card.component.scss']
})
export class HomescreenSocialFeedCardComponent {
    @Input() social_feed_item;
    public handleFont: Function;
    public clipContentString = helpers.clipContentString;
    public ensureHTTPS = helpers.ensureHTTPS;
    public handleShadow = helpers.handleShadow;
    constructor(font: FontService) {
        this.handleFont = font.handleFont;
    }
    checkURL(url) {
        if (url === null) {
            return 'https://res.cloudinary.com/bleachr/image/upload/v1517500393/bleachr_admin/emptyAvatar.png';
        }
        return this.ensureHTTPS(url);
    }

    checkMediaType(item, container?: string) {
        const post = item.items[0];

        if (item.is_social === false && container === 'youtube') {
            if (post.has_media && post.has_video) {
                return true;
            }
        } else if (item.is_social === false && container === 'web_photo') {
            if (post.has_image) {
                return true;
            }
        } else if (item.is_social === true && container === 'video') {
            if (post.has_media && post.has_video) {
                return true;
            } else if (post.has_image && post.has_video) {
                return true;
            } else {
                return false;
            }
        } else if (item.is_social === true && container === 'photo') {
            if (post.has_media && !post.has_video) {
                return true;
            } else if (post.has_image && !post.has_video) {
                return true;
            }
        }
    }

    buildURL() {
        const fake_url = `${window.location.href}/social_feed}`;

        return fake_url;
    }
}
