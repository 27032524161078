import { Component, Input } from '@angular/core';
import { ScoreUpdateModel } from 'src/app/_interfaces';
import { AppStringsService } from 'src/app/_services';

@Component({
    selector: 'app-no-doubles-scoring-match-card',
    templateUrl: './no-doubles-scoring-match-card.component.html',
    styleUrls: ['./no-doubles-scoring-match-card.component.scss']
})
export class NoDoublesScoringMatchCardComponent {
    @Input() match: ScoreUpdateModel;

    constructor(public appStringsService: AppStringsService) { }
}
