import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { helpers } from '../helpers';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class AnalyticService {
    private account_id;
    private os: string;
    private fingerprint: string = null;

    constructor(
        private http: HttpClient,
        private route: ActivatedRoute,
    ) {
        this.os = this.setOS();
    }

    setFingerprint(fingerprint: string): void {
        this.fingerprint = fingerprint;
    }

    setAccount(account): void {
        this.account_id = account;
    }

    setOS(): string {
        let os = '-';
        const nAgt = navigator.userAgent;
        const clientStrings: Array<{ [key: string]: any }> = [
            { s: 'Windows 10', r: /(Windows 10.0|Windows NT 10.0)/ },
            { s: 'Windows 8.1', r: /(Windows 8.1|Windows NT 6.3)/ },
            { s: 'Windows 8', r: /(Windows 8|Windows NT 6.2)/ },
            { s: 'Windows 7', r: /(Windows 7|Windows NT 6.1)/ },
            { s: 'Windows Vista', r: /Windows NT 6.0/ },
            { s: 'Windows Server 2003', r: /Windows NT 5.2/ },
            { s: 'Windows XP', r: /(Windows NT 5.1|Windows XP)/ },
            { s: 'Windows 2000', r: /(Windows NT 5.0|Windows 2000)/ },
            { s: 'Windows ME', r: /(Win 9x 4.90|Windows ME)/ },
            { s: 'Windows 98', r: /(Windows 98|Win98)/ },
            { s: 'Windows 95', r: /(Windows 95|Win95|Windows_95)/ },
            { s: 'Windows NT 4.0', r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/ },
            { s: 'Windows CE', r: /Windows CE/ },
            { s: 'Windows 3.11', r: /Win16/ },
            { s: 'Android', r: /Android/ },
            { s: 'Open BSD', r: /OpenBSD/ },
            { s: 'Sun OS', r: /SunOS/ },
            { s: 'Linux', r: /(Linux|X11)/ },
            { s: 'iOS', r: /(iPhone|iPad|iPod)/ },
            { s: 'Mac OS X', r: /Mac OS X/ },
            { s: 'Mac OS', r: /(MacPPC|MacIntel|Mac_PowerPC|Macintosh)/ },
            { s: 'QNX', r: /QNX/ },
            { s: 'UNIX', r: /UNIX/ },
            { s: 'BeOS', r: /BeOS/ },
            { s: 'OS/2', r: /OS\/2/ },
            { s: 'Search Bot', r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/ }
        ];
        // eslint-disable-next-line guard-for-in
        for (const id in clientStrings) {
            const cs = clientStrings[id];
            if (cs.r.test(nAgt)) {
                os = cs.s;
                break;
            }
        }
        return `Web ${os}`;
    }

    handleSponsorClick = (sponsor, location, click_event = 'Sponsor banner tapped') => {
        const event = {
            key: sponsor.key,
            sponsor_name: sponsor.name,
            sponsor_id: sponsor.id,
            sponsor_location: location,
            event: click_event
        };
        this.postEvent('sponsor', event).subscribe(
            data => {
            },
            error => {
                console.log(error);
            });
    }

    handleClockClick = (sponsor, click_event = 'click') => {
        const event = {
            name: 'sponsor.clock',
            parameters: {
                name: sponsor.name,
                clock_id: sponsor.id,
                action: click_event
            }
        };
        this.postEvent('custom_event', event).subscribe(
            data => {
            },
            error => {
                console.log(error);
            });
    }

    postEvent(event_key: string, event: { [key: string]: any }, advertiserid = null): Observable<any> {
        if (this.route.snapshot.queryParamMap.has('fingerprint')) {
            this.fingerprint = this.route.snapshot.queryParamMap.get('fingerprint');
        }

        const headers = helpers.buildAnalyticHeaders(this.account_id, this.fingerprint);
        const meta: { [key: string]: any } = {
            local_time: moment().utc().toISOString(),
            language: navigator.language,
            locale: navigator.language.replace(/-/g, '_'),
            os: this.os,
            advertiserid
        };
        const events: Array<{ [key: string]: any }> = [{ event_key, event, meta }];
        const payload: { [key: string]: any } = { events };
        return this.http.post(environment.ANALYTIC_ENDPOINT, payload, headers);
    }
}
