import { Directive, Input, ElementRef, OnInit } from '@angular/core';
import { AspectRatioService } from '../services/aspect-ratio.service';
import { BasicCoreCardModel } from './models/card.basic';

@Directive({
    selector: '[appAspectRatio]'
})

export class AspectRatioDirective implements OnInit {
    @Input('appAspectRatio') card: BasicCoreCardModel;

    constructor(
        private element: ElementRef,
        private ratioService: AspectRatioService
    ) { }

    async ngOnInit() {
        if (typeof this.card === 'string') {
            this.element.nativeElement.style.paddingTop = await this.ratioService.handleRatioStr(this.card);
            this.element.nativeElement.classList.add(this.ratioService.handleRatioClass(this.card));
        } else {
            this.element.nativeElement.style.paddingTop = await this.ratioService.setAspectRatio(this.card);
            this.element.nativeElement.classList.add(this.ratioService.handleRatioClass(this.card.context.aspect_ratio));
        }
    }

}
