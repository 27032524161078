import { StandalonePlayerProfile } from './player';

export interface FanModel {
    id: string;
    first_name: string;
    last_name: string;
    name: string;
    display_name: string;
    profile_photo_thumbnail: string;
    email: string;
    city?: string;
    show_facebook_profile: boolean;
    facebook_profile_id: string;
    phone_number: string;
    verified_voice: boolean;
    profile_photo: string;
    loaded?: boolean;
    fan_id?: string;
    balance?: number;
    verified_voice_image_url: string;
}

export interface FanStatsModel {
    points_streak_live: PointsStreakLive;
    daily_quick_picks: DailyQuickPicks;
    challenges_and_trivia: ChallengesAndTrivia;
    trivia_blitz?: TriviaBlitz;
    card_game?: CardGame
}

export interface PointsStreakLive {
    longest_streak: number;
    career_points: number;
    ytd_points: number;
}

export interface DailyQuickPicks {
    best_score: number;
    career_percentage: number;
    ytd_points: number;
}

export interface ChallengesAndTrivia {
    challenge_points: number;
    bonus_points: number;
    fan_trivia: number;
    likes: number;
}

export interface CardGame {
    [key: string]: number;
}

export interface TriviaBlitz {
    trivia_score: number;
    correct_answers: number;
    incorrect_answers: number;
    correct_percentage: number;
}

export interface LoggedInFanModel {
    blocked: Boolean;
    display_name: string;
    email: string;
    favorite_player: StandalonePlayerProfile;
    first_name: string;
    hide_from_leaderboard: boolean;
    id: string;
    last_name: string;
    name: string;
    phone_number: string;
    profile_photo: string;
    profile_photo_thumbnail: string;
    verified_player: any;
    verified_voice: boolean;
    broadcast_access: {
        can_start_broadcast: boolean;
        can_start_crowdview: boolean;
    };
    verified_voice_image_url: string;
}
