
<div class="trophy-detail-modal" (click)="closeModal()">
    <div class="container" id="trophy-container">
        <div class="trophy-image">
            <img *ngIf="show_img" [src]="trophy.current_trophy_level_image_url || trophy.image_url || ''" alt="">
        </div>
        <div class="trophy-details">
            <div class="trophy-name">
                <div class="name">{{trophy.name}}</div>
                <div class="explainer">{{setExplainer()}}</div>
            </div>
            <div class="level-progress">
                <div class="progress-text">
                    <div>
                        <span *ngIf="achieved_level.idx !== null">Lvl {{achieved_level.idx + 1}}</span>
                    </div>
                    <div>
                        <span *ngIf="scores.right !== 0">{{scores.left + '/' + scores.right}}</span>
                    </div>
                    <div>
                        <span *ngIf="next_level.idx !== null">Lvl {{next_level.idx + 1}}</span>
                    </div>
                </div>
                <div class="progress-bar">
                    <div id="progress" [style.width]="progress_percentage"></div>
                </div>
            </div>
        </div>
    </div>
</div>
