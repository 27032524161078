import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'displayDuration'
})
export class DisplayDurationPipe implements PipeTransform {

  transform(value: string): string {
    if (value === null) {
      return '';
  } else {
      const arr = value.split(':').slice(0, 2);
      if (arr[0] === '00') {
          return `${Number(arr[1])}m`;
      } else {
          return `${Number(arr[0])}h ${Number(arr[1])}m`;
      }
  }
  }

}
