export interface ChatTokenData {
    account_id: string;
    chat_url: string;
    name: string;
    profile_photo: string;
    room_role: string;
    subject_id: string;
    subject_type: string;
    token: string;
    user_id: string;
}

export interface MessageModel {
    body: string;
    chat_room_id: string;
    chat_room_user: UserInfo;
    chat_room_user_id: string;
    created_at: string;
    deleted_at: string;
    hidden: boolean;
    id: string;
    reactions: [];
    type: MessageType;
    type_data: {};
    flagged_by: [];
}

export interface UserInfo {
    account_id: string;
    blocked: boolean;
    chat_room_id: string;
    external_id: string;
    id: string;
    name: string;
    profile_photo: string;
    room_role: string;
}

export type MessageType = 'message' | 'tennis_update' | 'welcome_message'

export enum ChatRoomRoles {
    chat_bot = 'bot',
    chatter = 'chatter',
    moderator = 'mod'
}