<div style="position: relative">
    <div style="position: relative; overflow: hidden" [class]="validSelector(match_details.context.aspect_ratio)">
        <img [src]="match_details.context.match_placeholder_image" alt="" />
    </div>
    <a [href]="buildURL()" *ngIf="!placeholder">
        <mat-card class="mat-elevation-z4">
            <mat-card-content
                [style.border-top]="match_details.context.content_divider ? '1px solid' + match_details.context.content_divider : ''"
                [style.color]="match_details.body_color"
                [style.text-align]="match_details.context.body_text.alignment"
                [style.font-size.px]="match_details.context.body_text.font_size"
                [ngStyle]="handleFont(match_details.context.body_text.font)"
            >
                <div class="player-container" *ngIf="player_1">
                    <div class="player-details">
                        <div class="player-photo" [style.backgroundImage]="'url(' + player_1.player_photo + ')'"></div>
                        <h4>
                            {{ player_1.display_name }}
                        </h4>
                    </div>
                    <div class="player-score">
                        <h4 *ngFor="let score of player_1.player_score">
                            {{ score['games'] }}
                        </h4>
                    </div>
                </div>

                <div class="player-container" *ngIf="player_1_partner.display_name !== null">
                    <div class="player-details">
                        <div class="player-photo" [style.backgroundImage]="'url(' + player_1_partner.player_photo + ')'"></div>
                        <h4>
                            {{ player_1_partner.display_name }}
                        </h4>
                    </div>
                    <div class="player-score">
                        <h4 *ngFor="let score of player_1_partner.player_score">
                            {{ score['games'] }}
                        </h4>
                    </div>
                </div>

                <hr />

                <div class="player-container" *ngIf="player_2">
                    <div class="player-details">
                        <div class="player-photo" [style.backgroundImage]="'url(' + player_2.player_photo + ')'"></div>
                        <h4>
                            {{ player_2.display_name }}
                        </h4>
                    </div>
                    <div class="player-score">
                        <h4 *ngFor="let score of player_2.player_score">
                            {{ score['games'] }}
                        </h4>
                    </div>
                </div>

                <div class="player-container" *ngIf="player_2_partner.display_name !== null">
                    <div class="player-details">
                        <div class="player-photo" [style.backgroundImage]="'url(' + player_2_partner.player_photo + ')'"></div>
                        <h4>
                            {{ player_2_partner.display_name }}
                        </h4>
                    </div>
                    <div class="player-score">
                        <h4 *ngFor="let score of player_2_partner.player_score">
                            {{ score['games'] }}
                        </h4>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </a>
</div>
