import { Component, OnInit, Input } from '@angular/core';
import { ensureHTTPS } from '../../services/helpers';
@Component({
    selector: 'app-image',
    templateUrl: './image.component.html',
    styleUrls: ['./image.component.scss']
})
export class ImageComponent implements OnInit {
    @Input() player;
    @Input() classes;
    public source: string;
    public image = false;
    public placeholder = false;
    private BADGE_FLEX_CENTER = ' badge flex-center';
    private ensureHTTPS = ensureHTTPS;
    constructor() { }

    ngOnInit() {
        if (this.player.photo !== null || this.player.photo !== undefined) {
            const url = this.ensureHTTPS(this.player.photo);
            this.generateImage(url);
        } else {
            this.classes += this.BADGE_FLEX_CENTER;
            this.placeholder = true;
        }
    }
    generateImage(imgSrc: string) {
        const img = new Image();
        img.onload = () => {
            this.source = img.src;
            this.image = true;
        };
        img.onerror = () => {
            this.classes += this.BADGE_FLEX_CENTER;
            this.placeholder = true;
        };
        img.onabort = () => {
            this.classes += this.BADGE_FLEX_CENTER;
            this.placeholder = true;
        };
        try {
            img.src = imgSrc;
        } catch (e) {
            console.log('yay');
        }
    }

    buildNameBackground(player) {
        if (player.display_name) {
            return player.display_name;
        }
        if (player.first_name === null || player.first_name === undefined || player.last_name === null || player.last_name === undefined) {
            return '';
        }
        const letter_1 = player.first_name.charAt(0);
        const letter_2 = player.last_name.charAt(0);
        const name = `${letter_1.toLocaleUpperCase()}${letter_2.toLocaleUpperCase()}`;
        return name.trim();
    }
}
