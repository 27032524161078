import { Component, Input } from '@angular/core';
import { handleStatusColor, handleTitleColor } from '../../services/helpers';
import { FollowedPlayersService } from 'src/app/services/followed-players.service';
import { ScoreUpdateModel } from 'src/app/_interfaces';

@Component({
    selector: 'app-match',
    templateUrl: './match.component.html',
    styleUrls: ['./match.component.scss']
})
export class MatchComponent {
    @Input() match;
    public handleTitleColor = handleTitleColor;
    public handleStatusColor = handleStatusColor;

    constructor(private followedPlayers: FollowedPlayersService) { }

    matchWon(team1_won: boolean, team2_won: boolean) {
        return team1_won || team2_won;
    }

    handleSetWin(match: ScoreUpdateModel, ourTeam: string, theirTeam: string, index: number): boolean {
        const we = match[ourTeam].sets[index].games;
        const they = match[theirTeam].sets[index].games;
        return (we > 5 && (we - they > 1) && we > they) || (we === 7 && they === 6);
    }

    checkMatchStatusForPointDisplay(status: string) {
        if (status === 'playing' || status === 'suspended') {
            return true;
        }
        return false;
    }

    isFollowing(id: string) {
        return this.followedPlayers.getPlayerFollows().includes(id);
    }

}
