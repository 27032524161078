import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DrawData, DrawMatch, DrawRoundData } from '../_interfaces';
import { HttpPromiseService } from './http-promise.service';

@Injectable({
    providedIn: 'root'
})
export class DrawsService {

    constructor(
        private httpPromise: HttpPromiseService
    ) { }

    async getMatches(team_id: string, type: string): Promise<DrawData> {
        if (!team_id || team_id.length < 0 || !type || type.length < 0) {
            return Promise.resolve({
                match_data: [],
                round_data: []
            })
        }

        return await this.httpPromise
            .get<{ data: DrawData, err: string }>(`${environment.BLEACHR_WEB_API}/v3/matches/${team_id}/tournament_type/${type}`)
            .then(res => {
                return {
                    match_data: this.ensureBestMatchPossible(res.data.match_data),
                    round_data: res.data.round_data
                }
            })
            .catch(err => {
                console.error(err);
                throw err
            })
    }

    ensureBestMatchPossible(matches: DrawMatch[]): DrawMatch[] {
        if (!matches || matches.length <= 0) {
            return []
        }

        return matches.reduce((unique, match) => {
            const duplicate_matches = matches.filter(m => m.code === match.code)

            if (duplicate_matches.length >= 2) {
                /**
                 * step 1: find best match to display when there are duplicates
                 * step 2: ensure there is only one match based on id / code
                 * step 3: if it doesn't exist add it to the unique array
                 */
                const found_unique = this.findBestMatchToDisplayIfTheresMultiple(duplicate_matches)
                const exist = unique.find((m) => m.code === match.code)

                if (!exist) {
                    unique.push(found_unique)
                }

                return unique
            }

            unique.push(match)

            return unique
        }, [])
    }

    findBestMatchToDisplayIfTheresMultiple(matches: DrawMatch[]): DrawMatch {
        const idealMatch = matches.find(m => (m.status != 'cancelled' && m.status != null && m.team1.player1?.id != null));
        if (idealMatch) {
            /**
             * best case we've got a match that isn't cancelled, isn't pending and has players
             */
            return idealMatch;
        }
        const scheduledMatch = matches.find(m => (m.status === null && m.starts_at != null && m.team1.player1?.id != null));
        if (scheduledMatch) {
            /**
             * next best a match that is scheduled and has players
             */
            return scheduledMatch
        }
        const pendingMatch = matches.find(m => (m.status === null && m.team1.player1?.id != null));
        if (pendingMatch) {
            /**
             * next best a match that is pending and has players
             */
            return pendingMatch
        }
        const cancelledMatch = matches.find(m => (m.status === 'cancelled' && m.team1.player1?.id != null));
        if (cancelledMatch) {
            /**
             * last if there's a cancelled match and no replacement use that
             */
            return cancelledMatch
        }

        /**
         * failover we are unable to find unique match so we will take the first match
         */
        console.error(`there were multiple matches and we couldn't find a best one - we are using this match => ${matches[0].id}`)

        return matches[0];
    }
}
