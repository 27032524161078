export interface SetModel {
    games: number;
    order: number;
    tiebreak: number | null;
}

export interface PlayerProfile {
    bio: {
        country: string;
        first_name: string;
        gender: string;
        id: string;
        last_name: string;
        photo: string;
        player_sponsor_url: string;
        merchandise_url: string;
        rank: unknown;
        seed: number;
        stats: {
            career_losses: string;
            career_prize_money: string;
            career_titles: string;
            career_wins: string;
            ytd_titles: string;
            ytd_winlosses: string;
        };
        suffix: string;
        tour: string;
        singles_rank?: number;
        doubles_rank?: number;
        details?: {
            age?: number;
            height?: {
                imperial: string;
                metric: string;
            };
            residence?: string;
            play_hand?: string;
            height_imperial?: string;
            current_coach: string;
        };
    };
    details: {
        age: number;
        birth_city: string;
        birth_date: string;
        height: {
            imperial: string;
            metric: string;
        };
        play_hand: string;
        pro_year: string;
        residence: string;
        weight: {
            imperial: string;
            metric: string;
        };
        bio?: string;
        highlight_photos?: string[];
        current_coach: string;
    };
    country: string;
    first_name: string;
    gender: string;
    id: string;
    last_name: string;
    photo: string;
    player_sponsor_url: string;
    rank: number;
    seed: number;
    suffix: string;
    doubles_rank: number;
    singles_rank: number;
    merchandise_url: string;
    stats: {
        career_losses: string;
        career_prize_money: string;
        career_titles: string;
        career_wins: string;
        ytd_titles: string;
        ytd_winlosses: string;
    };
}

export interface StandalonePlayerProfile {
    country: string;
    details: {
        age: number;
        birth_city: string;
        birth_date: string;
        height: {
            imperial: string;
            metric: string;
        };
        highlight_photos: string[];
        play_hand: string;
        pro_year: string;
        residence: string;
        weight: {
            imperial: string;
            metric: string;
        };
        current_coach: string;
    };
    doubles_points: number;
    doubles_rank: number;
    first_name: string;
    gender: string;
    id: string;
    last_name: string;
    photo: string;
    singles_points: number;
    singles_rank: number;
    stats: {
        career_losses: string;
        career_prize_money: string;
        career_titles: string;
        career_wins: string;
        ytd_titles: string;
        ytd_winlosses: string;
    };
    tour: string;
}

export interface PlayerDetailsModel {
    value: string | number;
    label: string;
}
