<div class="pick-container" *ngIf="odds.length > 0">
  <h3>TennisONE Fans Predictor</h3>
  <div class="predictor-container">
    <div class="team1 flex-center" [style.width]="display.team1">
      <div class="percent">{{ display.team1 }}</div>
    </div>
    <div class="middle"></div>
    <div class="team2 flex-center" [style.width]="display.team2">
      <div class="percent">{{ display.team2 }}</div>
    </div>
  </div>
</div>