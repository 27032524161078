import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

export interface WebViewConfig {
    content_url: string;
    height_px: number;
    is_bleachr: boolean;
    loading_image_url: string;
    outbound_url: string;
    timeout_ms: number;
    user_interaction_emabled: boolean;
}

@Component({
    selector: 'app-homescreen-web-view',
    templateUrl: './homescreen-web-view.component.html',
    styleUrls: ['./homescreen-web-view.component.scss']
})
export class HomescreenWebViewComponent implements OnInit {
    @Input() data;
    public config: WebViewConfig = null;
    public safe_url;
    public loading = true;
    constructor(private dom: DomSanitizer) { }

    ngOnInit() {
        this.config = this.data.data[0];
        this.safe_url = this.dom.bypassSecurityTrustResourceUrl(this.config.content_url);
    }

    loaded() {
        setTimeout(() => this.loading = false, this.config.timeout_ms);
    }

    getHeight(height: number) {
        return height ? `${height}px` : '100%';
    }

    openOutboundUrl() {
        window.open(this.config.outbound_url);
    }
}
