<div class="timeline-item-header" [style.color]="social_feed.title_color">
    <h2 *ngIf='social_feed.title' [style.text-align]="social_feed.context.title.alignment"
        [style.font-size.px]="social_feed.context.title.font_size"
        [ngStyle]="handleFont(social_feed.context.title.font)">
        {{social_feed.title}}
    </h2>
    <a [href]="buildReadMoreURL()" *ngIf="social_feed.see_all">
        <h5 *ngIf='social_feed' [style.color]="social_feed.see_all_color">
            {{social_feed.see_all}}
        </h5>
    </a>
</div>
<div class="subtitle" *ngIf="social_feed.subtitle" [style.color]="social_feed.subtitle_color"
    [style.text-align]="social_feed.context.subtitle.alignment"
    [style.font-size.px]="social_feed.context.subtitle.font_size"
    [ngStyle]="handleFont(social_feed.context.subtitle.font)">
    {{social_feed.subtitle}}
</div>

<app-glide-carousel [data_object]="social_feed" [data_type]="'social_feed'"></app-glide-carousel>