import { DrawMatch, Team, PlayerDetails } from '../_interfaces';
import { isOdd } from './is-odd';

export function prePopulateDrawMatch(current: DrawMatch, previous_code: string, previous_team: Team): DrawMatch {
    const { player1, player2 } = previous_team

    if (isOdd(previous_code)) {
        current.team1.player1 = set__PlayerRecord(current.team1.player1, player1)
        current.team1.player2 = set__PlayerRecord(current.team1.player2, player2)
        return current
    } else {
        current.team2.player1 = set__PlayerRecord(current.team2.player1, player1)
        current.team2.player2 = set__PlayerRecord(current.team2.player2, player2)
        return current
    }
}

function set__PlayerRecord(current_player_record: PlayerDetails, previous_player_record: PlayerDetails) {
    return !current_player_record.id ? previous_player_record : current_player_record
}