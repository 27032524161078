import { Injectable } from '@angular/core';
import { Channel, Socket } from 'phoenix';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MatchScoreUpdate } from '../_interfaces';

class SocketConnections {
    public connections: { [team_id: string]: Socket } = {};

    add(team_id: string, connection: Socket): void {
        if (this.connections[team_id]) {
            return;
        }
        this.connections[team_id] = connection;
    }

    remove() { }

    getConnections(): { [team_id: string]: Socket } {
        return this.connections;
    }

    getConnection(team_id: string): Socket {
        if (!team_id) {
            return;
        }
        return this.connections[team_id];
    }
}

class SocketChannels {
    private channels: { [match_id: string]: Channel } = {};

    add(match_id: string, channel: Channel) {
        if (this.channels[match_id]) {
            return;
        }
        this.channels[match_id] = channel;
    }

    getChannel(match_id: string) {
        return this.channels[match_id];
    }

    matchUpdates(match_id: string): Observable<MatchScoreUpdate> {
        if (!match_id || !this.channels[match_id]) {
            return;
        }
        return new Observable((observer) => {
            this.channels[match_id].on('live_update', (update: MatchScoreUpdate) => {
                observer.next(update);
            })
        })
    }
}

class ConnectionStatus {
    public pending: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private team_id: string | null = null;

    getState(): boolean {
        return this.pending.getValue();
    }

    getTeamID(): string {
        return this.team_id;
    }

    setState(state: boolean) {
        this.pending.next(state);
    }

    setTeamID(id: string) {
        this.team_id = id;
    }
}

@Injectable({
    providedIn: 'root'
})
export class LiveScoreSocketService {
    private socket_connections = new SocketConnections();
    private socket_state = new ConnectionStatus();
    public match_channel = new SocketChannels();

    constructor() { }

    connect(team_id: string): Promise<boolean> {
        return new Promise((completed) => {

            if (this.socket_connections.getConnection(team_id)) {
                completed(true);
                return;
            }

            if (this.socket_state.getState() && this.socket_state.getTeamID() === team_id) {
                this.socket_state.pending.subscribe((pending) => {
                    if (!pending) {
                        completed(true);
                    }
                })
                return;
            }

            this.socket_state.setState(true);
            this.socket_state.setTeamID(team_id);

            const connection = new Socket(`${environment.socket_host}/socket`, {
                params: {
                    tennis_tournament: team_id
                }
            });

            connection.onOpen(() => {
                this.socket_connections.add(team_id, connection);
                this.socket_state.setState(false);
                completed(true);
            });

            connection.connect();
        });
    }

    joinChannel(team_id: string, match_id: string): Promise<MatchScoreUpdate> {
        const match_channel = this.match_channel.getChannel(match_id);
        if (match_channel) {
            return new Promise((completed) => {
                match_channel
                    .push('current', null)
                    .receive('ok', (data) => {
                        completed(data);
                    });
                return;
            });
        }
        return new Promise((completed) => {
            const socket_connection = this.socket_connections.getConnection(team_id);
            const channelName = `tennis_match:${match_id}`;
            const joined_channel = socket_connection.channel(channelName);
            joined_channel
                .join()
                .receive('ok', (response) => {
                    completed(response);
                })
                .receive('error', (response) => {
                    const error = 'Joining chat channel failed';
                    console.error(error, response);
                });
            this.match_channel.add(match_id, joined_channel)
        });

    }

}
