import { DrawMatch, DrawsRoundUIObject } from '../_interfaces';


export function groupByRound(array: DrawMatch[]): DrawsRoundUIObject[] {

    const data = array.reduce((result: Map<string, DrawsRoundUIObject>, currentValue) => {
        if (currentValue.round.name === null) {
            console.error(`Error bad round data => ${JSON.stringify(currentValue.round)}`)
            currentValue.round = { id: "MS1", name: "Rnd of 64", order: "1" }
        }
        if (!result.has(currentValue.round.name)) {
            result.set(currentValue.round.name, {
                round_title: currentValue.round.name,
                round_code: currentValue.round,
                matches: [currentValue]
            })
        } else if (result.has(currentValue.round.name)) {
            const round = result.get(currentValue.round.name)
            round.matches.push(currentValue)
        }

        return result;
    }, new Map());

    return Array.from(data.values())
};