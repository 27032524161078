export interface TennisScheduleModel {
    id?: string;
    team_id: string;
    name: string;
    image_url: string;
    apple_download_url: string;
    android_download_url: string;
    start_date: string;
    end_date: string;
    theme: TennisScheduleTheme;
    team_name: string;
    timeframe: string;
    partnership_level: string;
    externalURL: string;
    time_zone_name: string;
}

export interface TennisScheduleTheme {
    navbar?: {
        url?: string;
        color?: string;
    };
    icon?: {
        right?: string;
        left?: string;
    };
    court_type?: string;
    pretournament_page_url?: string;
    tournament_info_apppage_url?: string;
    tournament_info_push_tag_id?: string;
    app_icon_asset_name?: string;
    matches_sponsor_image_url?: string;
    matches_sponsor_link_url?: string;
    draws_sponsor_link_url?: string;
    draws_sponsor_image_url?: string;
    players_sponsor_image_url?: string;
    players_sponsor_link_url?: string;
    tournament_info?: {
        push_tag_id: string;
        icon_asset_name: string;
    };
    sponsors: TennisScheduleSponsorModel[];
    web_landing_page_url: string;
    override_tabs?: boolean;
    tabs?: TennisScheduleCustomTabModel[];
    bracket_id?: string;
    bracket_event_id?: string;
    subtitle?: string;
}

export interface TennisScheduleSponsorModel {
    id: string;
    key: string;
    url: string;
    image_url: string;
    enabled: boolean;
    name?: string;
}

export interface ScheduleEntryTabModel {
    live: TennisScheduleModel[];
    upcoming: TennisScheduleModel[];
    past: TennisScheduleModel[];
}

export interface TennisScheduleCustomTabModel {
    destination: string;
    name?: string;
    timeline_id?: string;
    show_bracket_event_link?: boolean;
    url?: string;
}
