import { Component, OnInit, Input } from '@angular/core';
import { ensureHTTPS } from '../../services/helpers';

@Component({
    selector: 'app-profile-image',
    templateUrl: './image.component.html',
    styleUrls: ['./image.component.scss']
})
export class ProfileImageComponent implements OnInit {
    @Input() fan;
    @Input() classes;
    @Input() img_type;
    @Input() T_ONE_USER = false;
    public source: string;
    public image = false;
    public placeholder = false;
    public colors = ['#da4553', '#e9573e', '#f6bb43', '#8cc051', '#37bb9b', '#3caeda', '#4b89dc', '#967bdc', '#d870ad', '#434a54'];
    private ensureHTTPS = ensureHTTPS;

    ngOnInit() {
        if (!this.fan.hasOwnProperty('name')) {
            if (this.fan.hasOwnProperty('display_name')) {
                this.fan.name = this.fan.display_name;
            } else {
                this.fan.name = `${this.fan.first_name} ${this.fan.last_name}`;
            }
        }

        if (this.fan[this.img_type] !== null || this.fan[this.img_type] !== undefined) {
            const url = this.ensureHTTPS(this.fan[this.img_type]);
            this.generateImage(url);
        } else {
            // this.classes += ' badge';
            this.placeholder = true;
        }
    }

    generateImage(imgSrc: string) {
        const img = new Image();
        img.onload = () => {
            this.image = true;
            this.source = img.src;
        };
        img.onerror = () => {
            // this.classes += ' badge';
            this.placeholder = true;
        };
        img.onabort = () => {
            // this.classes += ' badge';
            this.placeholder = true;
        };
        try {
            img.src = imgSrc;
        } catch (e) {
            console.log('yay');
        }
    }

    buildNameBackground(fan_name: string) {
        if (fan_name === null || fan_name === undefined) {
            return '';
        }
        const [first, last] = fan_name.split(' ');

        if (first === '' || first === undefined || last === '' || last === undefined) {
            return '';
        } else {
            const letter_1 = first.charAt(0);
            const letter_2 = last.charAt(0);
            const name = `${letter_1.toLocaleUpperCase()}${letter_2.toLocaleUpperCase()}`;
            return name.trim();
        }
    }

    getProfileHash(fan_name: string, max: number): number {
        const len = fan_name.length;
        return len % max;
    }

    avatarColor(fan_name: string): string {
        if (this.T_ONE_USER) {
            return '#e0e622';
        } else {
            const idx = this.getProfileHash(fan_name, this.colors.length);
            let color = this.colors[idx];
            if (color === undefined) {
                color = '#663399';
            }
            return color;
        }

    }
}
