<section [ngSwitch]="data_type">

    <!-- ENHANCED ARTICLES COMPONENT -->
    <div *ngSwitchCase="'enhanced_articles'">
        <div class="glide" #carousel [style.color]="data_object.body_color">
            <div class="glide__track" data-glide-el="track">
                <ul class="glide__slides">
                    <li class="glide__slide" *ngFor="let article of articles">
                        <a [href]="buildURL(article)">
                            <article [style]="handleURL(article.image_url, 'background')"
                                (click)="clickHandler(article)">
                                <div *ngIf="!data_object.context.hide_card_article_title" class="article-title"
                                    [style.background-image]="'linear-gradient(to right, ' + handleGradeColor(data_object.context.headline_background_color) + ' 40%, transparent)'">
                                    <img *ngIf="buildIconPath(article.type)" class="type-icon"
                                        [src]="buildIconPath(article.type)" alt="article type icon">
                                    <div>
                                        {{article.title}}
                                    </div>
                                </div>
                            </article>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <!-- SOCIAL FEED COMPONENT -->
    <div *ngSwitchCase="'social_feed'">
        <div class="glide" #carousel *ngIf="social_feed_items">
            <div class="glide__track" data-glide-el="track">
                <ul class="glide__slides">
                    <li class="glide__slide" *ngFor="let post of social_feed_items">
                        <article class="social-post">
                            <div class="social-media-container">
                                <div class="video-wrapper" *ngIf='checkMediaType(social_feed, post, "video")'>
                                    <video class="social-post-video" [src]="handleURL(post.media_content, 'video')"
                                        controls preload="metadata"></video>
                                </div>
                                <div class="photo-wrapper"
                                    *ngIf='checkMediaType(social_feed, post, "photo") || checkMediaType(social_feed, post, "web_photo")'>
                                    <img class="social-post-image" *ngIf='checkMediaType(social_feed, post, "photo")'
                                        [src]="handleURL(post.media_content, 'image')" alt="social post image">
                                    <img class="social-post-image"
                                        *ngIf='checkMediaType(social_feed, post, "web_photo")'
                                        [src]="handleURL(post.media_content, 'image')" alt="social post image">
                                </div>
                            </div>

                            <a class="youtube-link" *ngIf='checkMediaType(social_feed, post, "youtube")'
                                [href]='post.link'>
                                <div class="youtube-image" [style]="handleURL(post.media_content, 'background')">
                                    <mat-icon class="play-icon">play_arrow</mat-icon>
                                </div>
                            </a>

                            <div class="post-details tennis-one-theme"
                                [style.text-align]="data_object.context.body_text.alignment"
                                [style.font-size.px]="data_object.context.body_text.font_size"
                                [ngStyle]="handleFont(data_object.context.body_text.font)">
                                <div class="account-details">
                                    <img class="account-profile-photo" [src]="handleURL(post.user_image, 'image')"
                                        alt="profile photo">
                                    <div>
                                        <span
                                            *ngIf="data_object.feed_type === 'Twitter'">@</span>{{post.user_screen_name}}
                                    </div>
                                </div>
                                <div class="post-content" *ngIf="post.description"
                                    [innerHTML]="parseDesc(post.description)">
                                </div>
                            </div>
                        </article>
                    </li>
                </ul>
            </div>
        </div>
    </div>

</section>