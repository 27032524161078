<div class="card bonus" id="bonus" *ngIf="section_type === 'bonus'">
    <div class="splide" [id]="slider_uuid">
        <div class="splide__track">
            <ul class="splide__list">
                <li class="splide__slide" *ngFor="let metric of referral.metrics">
                    <div class="bonus-cards">
                        <img class="metric-img" *ngIf="metric_icon[metric.key]" [src]="metric_icon[metric.key]" alt="">
                        <div style="font-size: 25px;">{{ metric.label }}</div>
                        <div class="coin-section">
                            <div class="coins"> {{ metric.default_payout }}</div>
                            <img class="coin-img" src="https://res.cloudinary.com/bleachr/image/upload/v1602089564/Bleachr/Bleachr%20Icons/coin_face.png" alt="">
                        </div>
                        <div style="min-height: 60px;">
                            <div *ngIf="metric.progress !== metric.threshold">
                                <div class="progress-amount">{{ metric.progress }}/{{ metric.default_threshold }}</div>
                                <div class="progress-bar">
                                    <div class="progress-bar-fill" [ngStyle]="{width: get_percentage(metric.progress, metric.default_threshold)}"></div>
                                </div>
                            </div>
                            <mat-icon class="check-img" *ngIf="metric.progress === metric.default_threshold" >check_circle</mat-icon>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</div>

<div class="card team" id="team" *ngIf="section_type === 'team' && referral.squad.length > 0">
    <h2 style="font-size: 36px;margin-top: 0;">My Team</h2>
    <div class="splide" [id]="slider_uuid">
        <div class="splide__track">
            <ul class="splide__list">
                <li class="splide__slide" style="width:800px" *ngFor="let squad of referral.squad;index as i;">
                    <div class="team-cards">
                        <div class="card-top">
                            <img class="profile-img" [src]="image_profile[i]" alt="">
                            <div>
                                <h2  class="header">{{ squad.display_name }}</h2>
                                <p class="content">Bleachr Creator since {{ squad.created_at | date: 'shortDate' }}</p>
                            </div>
                        </div>
                        <div class="card-bottom">
                            <div class="card-coin-row">
                                <div class="card-left-col">
                                    <h3 class="header">Coins Earned</h3>
                                    <p class="content">(In the period from {{ squad.referral_period_starts_at | date: 'shortDate' }} to {{ squad.referral_period_ends_at | date: 'shortDate' }})</p>
                                </div>
                                <div class="card-right-col">
                                    <div class="coins"> {{ squad.coins_earned | number }} </div>
                                    <img class="coin-img" src="https://res.cloudinary.com/bleachr/image/upload/v1602089564/Bleachr/Bleachr%20Icons/coin_face.png" alt="">
                                </div>
                            </div>
                            <div>
                                <div *ngFor="let metric of squad.metrics">
                                    <div class="progress-list" *ngIf="metric_icon[metric.key]">
                                        <img class="metric-img" [src]="metric_icon[metric.key]" alt="">
                                        <div class="progress-bar-container">
                                            <div class="metric-label">{{ metric.label }}</div>
                                            <div class="progress-bar">
                                                <div class="progress-bar-fill" [ngStyle]="{width: get_percentage(metric.progress, metric.threshold)}"></div>
                                            </div>
                                        </div>
                                        <div class="metric-progress" style="text-align: right;">
                                            <div class="progress-amount" *ngIf="metric.progress !== metric.threshold">{{ metric.progress }}/{{ metric.threshold }}</div>
                                            <mat-icon class="check-img" *ngIf="metric.progress === metric.threshold" >check_circle</mat-icon>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</div>    

