import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { helpers } from '../helpers';
import { environment } from 'src/environments/environment';
import { HttpPromiseService } from '../_services/http-promise.service';
import { MatchModel } from '../_interfaces/match';
import { ClientTournament, ClientTournamentAsset } from '../_interfaces';

export interface DrawRoundsDetails {
    name: string;
    round_code: string;
    round_points: string;
    round_prize_money: string;
}
export interface DrawsData {
    match_data: MatchModel[],
    round_data: [DrawRoundsDetails[]]
}

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    private helper;
    private collection_asset_path = `${environment.BLEACHR_WEB_API}/asset-collection`;

    constructor(
        private http: HttpClient,
        private httpPromise: HttpPromiseService
    ) {
        this.helper = helpers;
    }

    async getMatches(team_id: string, type: string): Promise<DrawsData> {
        return await this.httpPromise.get<{ data: DrawsData, error: string }>(`${this.helper.webAPI}/matches/${team_id}/tournament_type/${type}`)
            .then(res => res.data)
            .catch(error => {
                console.error(error);
                return null;
            });
    }

    getSponsorClock(identifier: string): Observable<any> {
        const httpHeaders = this.helper.buildBasicReqestHeader(identifier);

        return this.http.get(`${this.helper.remoteAPI}/api/v4/sponsored_clocks`, httpHeaders);
    }
    getTimeline(identifier: string, locale_string: string): Observable<any> {
        const httpHeaders = this.helper.buildLocaleRequestHeader(identifier, locale_string);

        return this.http.get(`${this.helper.remoteAPI}/api/v4/timeline`, httpHeaders);
    }

    async getTournament(identifier: string, team_id: string): Promise<any> {
        const httpHeaders = this.helper.buildBasicReqestHeader(identifier);
        const url = `${this.helper.remoteAPI}/api/v3/tennis/tournaments/${team_id}`;
        return this.httpPromise.get(url, httpHeaders);
    }

    getTimelineHub(identifier: string, locale_string: string): Observable<any> {
        const httpHeaders = this.helper.buildLocaleRequestHeader(identifier, locale_string);

        return this.http.get(`${this.helper.remoteAPI}/api/v4/timeline/hub`, httpHeaders);
    }

    async getClientTournaments(): Promise<ClientTournament[]> {
        return this.http.get<{ data: ClientTournament[], error: any }>(`${this.collection_asset_path}/client-tournament`)
            .toPromise()
            .then(r => r && r.data && r.data.length ? r.data : []);
    }

    async getPracticeCourts(identifier: string, tournament_id: string): Promise<any> {
        const httpHeaders = this.helper.buildBasicReqestHeader(identifier);
        return this.httpPromise.get(`${this.helper.remoteAPI}/api/v4/practice_matches/${tournament_id}`, httpHeaders);
    }

    async getBasicPracticeCourts(identifier: string, team_id: string): Promise<any> {
        const httpHeaders = this.helper.buildBasicReqestHeader(identifier);
        return this.httpPromise.get(`${this.helper.timelineAPI}/api/v4/practice_schedules?team_id=${team_id}`, httpHeaders);
    }

    getEventDetails(identifier: string, event_id: string): Observable<any> {
        const httpHeaders = this.helper.buildBasicReqestHeader(identifier);

        return this.http.get(`${this.helper.remoteAPI}/api/v3/events/${event_id}`, httpHeaders);
    }

    getTriviaQuestion(identifier: string, trivia_id: string): Observable<any> {
        const httpHeaders = this.helper.buildBasicReqestHeader(identifier);

        return this.http.get(`${this.helper.remoteAPI}/api/v3/trivia/${trivia_id}`, httpHeaders);
    }

    getAccountSponsors(identifier: string, locale: string): Observable<any> {
        const headers = this.helper.buildLocaleRequestHeader(identifier, locale);

        return this.http.get(`${this.helper.remoteAPI}/api/v4/sponsors`, headers);
    }

    getDailyPicksGuesses(team_id: string, token: string): Observable<any> {
        const headers = this.helper.buildBasicAuthHeaders(token);

        return this.http.get(`${this.helper.gamingAPI}/bracket/${team_id}`, headers);
    }

    fetchDacastChannel(): Observable<any> {
        return this.http.get('https://api.dacast.com/v2/channel/519019/recording/status?apikey=147259_c980e92b91972ab23f41');
    }

    getUrlRes(url: string): Observable<any> {
        return this.http.get(url, { observe: 'response' });
    }

    fetchDacast(): Observable<any> {
        return this.http.get('https://api.dacast.com/v2/channel?apikey=147259_c980e92b91972ab23f41');
    }

    getTriviaBlitzQuestion(auth_token, locale = 'en'): Promise<any> {
        const headers = this.helper.buildLocaleAuthHeaders(auth_token, locale);

        return this.http.get(`${this.helper.gamingAPI}/trivia-blitz`, headers).toPromise()
            .then(data => data)
            .catch(error => {
                console.error(error);
                return null;
            });
    }

    getTriviaBlitzQuestionByTeam(team_id: string, auth_token, locale = 'en'): Promise<any> {
        const headers = this.helper.buildLocaleAuthHeaders(auth_token, locale);

        return this.http.get(`${this.helper.gamingAPI}/trivia-blitz/${team_id}`, headers).toPromise()
            .then(data => data)
            .catch(error => {
                console.error(error);
                return null;
            });
    }

    getReferrals(tokens, identifier) {
        const headers = this.helper.buildReferralHeader(tokens, identifier);

        return this.http.get(`${this.helper.remoteAPI}/api/v4/referral`, headers).toPromise()
            .then(data => data)
            .catch(error => {
                console.error(error);
                return null;
            });
    }

    getFanProfile(tokens, identifier, id): Promise<any> {
        const headers = this.helper.buildReferralHeader(tokens, identifier);

        return this.http.get(`${this.helper.remoteAPI}/api/v4/profile/${id}`, headers).toPromise()
            .then((data) => {
                return data;
            })
            .catch((error) => {
                console.error(error);
                return null;
            });
    }

    getTriviaBlitzQuestionByTour(tour: string, auth_token, locale = 'en'): Promise<any> {
        const headers = this.helper.buildLocaleAuthHeaders(auth_token, locale);

        return this.http.get(`${this.helper.gamingAPI}/trivia-blitz/by_tour/${tour}`, headers).toPromise()
            .then(data => data)
            .catch(error => {
                console.error(error);
                return null;
            });
    }

    postTriviaBlitzAnswer(question_id: string, payload, auth_token: string): Observable<any> {
        const headers = this.helper.buildBasicAuthHeaders(auth_token);
        return this.http.put(`${this.helper.gamingAPI}/trivia-blitz/${question_id}`, payload, headers);
    }

    getGameZoneConfig(): Observable<any> {
        return this.http.get(`${this.helper.gamingAPI}/gaming-config`);
    }

    async getTimelineByID(timeline_id: string): Promise<any> {
        return await this.httpPromise.get(`${environment.BLEACHR_WEB_API}/timeline/config/${timeline_id}`)
    }

    async getActiveMatchID(): Promise<any> {
        return await this.httpPromise.get(`${environment.BLEACHR_WEB_API}/matches/scorebug/active`)
    }

    async getMatch(match_id: string, identifier: string): Promise<any> {
        const headers = this.helper.buildBasicReqestHeader(identifier);

        return await this.httpPromise.get(`${this.helper.remoteAPI}/api/v3/tennis/matches/${match_id}`, headers)
            .then(data => {
                return data['data'];
            })
            .catch(error => {
                console.error(error);
                return null;
            });
    }

    async uploadToCloudinary(fileToUpload: File): Promise<any> {
        try {
            const formData: FormData = new FormData();
            formData.append('file', fileToUpload, fileToUpload.name);
            formData.append('upload_preset', environment.cloudinary_config.upload_preset);
            const res = await this.http.post(environment.cloudinary_config.cloud_name, formData).toPromise();
            return res;
        } catch (e) {
            return null;
        }
    }

    postFanVerification(payload, auth_token: string) {
        const headers = this.helper.buildAuthHeaders(auth_token, 'bleachr');
        return this.http.post(`${this.helper.remoteAPI}/api/v4/fan_verifications`, payload, headers).toPromise();
    }

    async getTournamentId(url_key: string, password: string): Promise<any> {
        return await this.http.get(`${environment.BLEACHR_WEB_API}/asset-collection/is-client-tournament-accessible?url_key=${url_key}&password=${password}`).toPromise();
    }

    async isValidUrlKey(url_key: string): Promise<any> {
        return await this.http.get(`${environment.BLEACHR_WEB_API}/asset-collection/is-valid-url-key?url_key=${url_key}`).toPromise();
    }

    async getClientAssets(client_tournament_id: string): Promise<{ year: number, clientTournamentAssets: ClientTournamentAsset[] }[]> {
        return this.http.get<{ data: { year: number, clientTournamentAssets: ClientTournamentAsset[] }[], error: any }>(`${this.collection_asset_path}/client-assets-by-client-tournament-id/${client_tournament_id}`)
            .toPromise()
            .then(r => r && r.data && r.data.length ? r.data : []);
    }

    async updateClientAsset(clientTournamentAsset: ClientTournamentAsset): Promise<ClientTournamentAsset> {
        return this.http.put<{ data: ClientTournamentAsset, error: any }>(`${this.collection_asset_path}/client-asset`, clientTournamentAsset)
            .toPromise()
            .then(r => r && r.data && r.data.id ? r.data : null);
    }

    async updateDefaultAsset(defaultAsset): Promise<any> {
        return this.http.put(`${this.collection_asset_path}/default`, defaultAsset).toPromise()
    }

    async getAssetUrl(payload: { [key: string]: string | boolean }): Promise<string> {
        const params = `?${Object.entries(payload).map(v => `${v[0]}=${v[1]}`).join('&')}`;
        return this.http.get<{ data: string, error: any }>(`${this.collection_asset_path}/asset-url${params}`)
            .toPromise()
            .then(r => r && r.data && r.data ? r.data : '');
    }

    /**
     * Here payload is optional and could contain some or all of the following:
     * is_tac: string
     * is_default: string
     * asset_id: string
     * year_id: string
     * client_tournament_id: string
     */
    async uploadFiles(
        filesToUpload: FormData,
        user_id: string,
        payload?: { [key: string]: string | boolean }
    ): Promise<{ data: string, error: any }> {
        const params = payload && payload.is_tac ? `?${Object.entries(payload).map(v => `${v[0]}=${v[1]}`).join('&')}` : '';
        return await this.http.post<{ data: string, error: any }>(`${environment.BLEACHR_WEB_API}/fileUpload/aws-s3/multiple-upload/${user_id}${params}`, filesToUpload).toPromise();
    }
}
