<div class="match-card">
    <div>
        <div class="match-card-header" [style.backgroundColor]="handleStatusColor(match.status)"
            [style.color]="handleTitleColor(match.status)">
            {{ match.status | matchStatusTitle }}
            <span class="round_name">{{ match.round?.name }}</span>
            <span class="round_name" *ngIf="match.status ==='finished'">{{ match.duration | displayDuration }}</span>
        </div>
        <div id="team1" *ngIf="!match.team1.is_bye; else byeBlock"
            [ngClass]="{loser: match.team2.is_winner && !match.team1.is_winner}">
            <div class="flag">
                <span *ngIf="match.team1.player1.id; else flagspacer"
                    class="flag-icon flag-icon-{{match.team1.player1.country | countryName}} player-one"></span>
                <span *ngIf="match.team1.player2.id"
                    class="flag-icon flag-icon-{{match.team1.player2.country | countryName}} player-one-partner"></span>
            </div>
            <div class="player-photos">
                <!-- Player One -->
                <div class="player-one player-photo-frame" [class.following]="isFollowing(match.team1.player1.id)">
                    <app-image [player]="match.team1.player1"
                        [classes]="'small-player-headshot player-photo flex-center'">
                    </app-image>
                </div>
                <!-- Player One Partner -->
                <div class="player-photo-frame player-one-partner" [class.following]="isFollowing(match.team1.player2.id)"
                    *ngIf="match.team1.player2.id || match.type ==='MD' || match.type ==='LD'">
                    <app-image [player]="match.team1.player2"
                        [classes]="'small-player-headshot player-photo partner flex-center'">
                    </app-image>
                </div>
            </div>
            <div class="team-seed">
                {{ match.team1.player1 | seedRules }}
            </div>
            <div class="player-info">
                <div class="player-name" style="margin-left: 1%"
                    [ngClass]="{loser: match.team2.is_winner && !match.team1.is_winner, winner: match.team1.is_winner}">
                    <div>
                        {{ [match.team1.player1.first_name, match.team1.player1.last_name, 'TBD'] | shortName }}
                    </div>
                    <div class="partner" *ngIf="match.team1.player2.id">
                        {{ [match.team1.player2.first_name,match.team1.player2.last_name, 'TBD'] | shortName   }}
                    </div>
                </div>
                <div class="player-status">
                    <mat-icon style="color: #c9f364; font-size: 0.8rem;"
                        *ngIf="match.team1.is_serving && !matchWon(match.team1.is_winner, match.team2.is_winner)">
                        fiber_manual_record
                    </mat-icon>
                    <mat-icon style="color: #4CAF50; font-size: 0.8rem;" *ngIf="match.team1.is_winner">
                        done</mat-icon>
                </div>
            </div>
            <div class="sets-scores">
                <div class="set" *ngFor="let set of match.team1.sets; let i = index"
                    [ngClass]="{loser: match.team2.is_winner && !match.team1.is_winner, setwin: handleSetWin(match, 'team1', 'team2', i)}">
                    {{ set.games }} <sup *ngIf="set.tiebreak !== 0">{{set.tiebreak}}</sup>
                </div>
            </div>
            <div *ngIf="checkMatchStatusForPointDisplay(match.status)" class="team-points"
                [class.live-update]="match.team1.live_update"
                [ngClass]="{loser: match.team2.is_winner && !match.team1.is_winner, winner: match.team1.is_winner}">
                <span *ngIf="match.team1.points; else emptyPoints"> {{ match.team1.points }}
                </span>
            </div>
        </div>
        <hr>
        <div id="team2" *ngIf="!match.team2.is_bye; else byeBlock"
            [ngClass]="{loser: match.team1.is_winner && !match.team2.is_winner}">
            <div class="flag">
                <span *ngIf="match.team2.player1.id; else flagspacer"
                    class="flag-icon flag-icon-{{match.team2.player1.country | countryName}} player-one"></span>
                <span *ngIf="match.team2.player2.id"
                    class="flag-icon flag-icon-{{match.team2.player2.country | countryName}} player-one-partner"></span>
            </div>
            <div class="player-photos">
                <!-- Player One -->
                <div class="player-one player-photo-frame" [class.following]="isFollowing(match.team2.player1.id)">
                    <app-image [player]="match.team2.player1"
                        [classes]="'small-player-headshot player-photo flex-center'">
                    </app-image>
                </div>
                <!-- Player One Partner -->
                <div class="player-photo-frame player-one-partner" [class.following]="isFollowing(match.team2.player2.id)"
                    *ngIf="match.team2.player2.id || match.type ==='MD' || match.type ==='LD'">
                    <app-image [player]="match.team2.player2"
                        [classes]="'small-player-headshot player-photo partner flex-center'">
                    </app-image>
                </div>
            </div>
            <div class="team-seed">
                {{ match.team2.player1 | seedRules }}
            </div>
            <div class="player-info">
                <div class="player-name" style="margin-left: 1%"
                    [ngClass]="{loser: match.team1.is_winner && !match.team2.is_winner, winner: match.team2.is_winner}">
                    <div>
                        {{ [match.team2.player1.first_name, match.team2.player1.last_name, 'TBD'] | shortName  }}
                    </div>
                    <div class="partner" *ngIf="match.team2.player2.id">
                        {{ [match.team2.player2.first_name, match.team2.player2.last_name, 'TBD'] | shortName  }}
                    </div>
                </div>
                <div class="player-status">
                    <mat-icon style="color: #c9f364; font-size: 0.8rem;"
                        *ngIf="match.team2.is_serving && !matchWon(match.team1.is_winner, match.team2.is_winner)">
                        fiber_manual_record
                    </mat-icon>
                    <mat-icon style="color: #4CAF50; font-size: 0.8rem;" *ngIf="match.team2.is_winner">
                        done</mat-icon>
                </div>
            </div>

            <div class="sets-scores">
                <div class="set" *ngFor="let set of match.team2.sets; let i = index"
                    [ngClass]="{loser: match.team1.is_winner && !match.team2.is_winner, setwin: handleSetWin(match, 'team2', 'team1', i)}">
                    {{ set.games }} <sup *ngIf="set.tiebreak !== 0">{{set.tiebreak}}</sup>
                </div>
            </div>
            <div *ngIf="checkMatchStatusForPointDisplay(match.status)" class="team-points"
                [class.live-update]="match.team2.live_update"
                [ngClass]="{loser: match.team1.is_winner && !match.team2.is_winner, winner: match.team2.is_winner}">
                <span *ngIf="match.team2.points; else emptyPoints"> {{ match.team2.points }}
                </span>
            </div>
        </div>
    </div>
    <!-- <div *ngIf="(match.status === 'playing' || match.status === 'warmup') && match.lvision_url">
        <hr>
        <div class="lvision-container">
            <a *ngIf="(match.status === 'playing' || match.status === 'warmup') && match.lvision_url" class="lv-btn"
                (click)="openDialog(match.lvision_url)">
                <mat-icon>videocam</mat-icon>
                T1 Tracer
                <mat-icon>chevron_right</mat-icon>
            </a>
            <a *ngIf="streakTemplateLogic(match)" id="streak-racket" class="lv-btn" (click)="openStreak(match.id)">
                <mat-icon svgIcon="racket"></mat-icon>
                Points Streak Live
                <mat-icon>chevron_right</mat-icon>
            </a>
        </div>
    </div> -->
</div>

<ng-template #emptyPoints> 0 </ng-template>
<ng-template #flagspacer> <span class="flag-icon" style="box-shadow: none;"></span> </ng-template>

<!-- Used to display fake player block for bye matches -->
<ng-template #byeBlock>
    <div id="team2" class="placeholder">
        <div class="flag"></div>
        <div class="player-frame">
            <div class="player-photo badge">
            </div>
        </div>
        <div class="team-seed"> - </div>
        <div class="player-name"> BYE </div>
        <div></div>
        <div class="set-scores"></div>
        <div class="team-score"></div>
    </div>
</ng-template>