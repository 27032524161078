export interface DefinedWidget {
    id?: string;
    label: string;
    type: 'tennis_match';
    x_position: string;
    y_position: string;
    height: string;
    width: string;
    widget_config?: { [key: string]: any; }
}


export interface OverlayPage {
    id?: string;
    name: string;
    priority: number;
    widgets: string[];
}

export interface VideoOverlay {
    id: string;
    account_id: string;
    label: string;
    media_url: string;
    overlay_configuration: OverlayConfiguration;
}

export interface OverlayConfiguration {
    aspect_ratio: string;
    defined_widgets: DefinedWidget[];
    overlay_pages: {
        [key: string]: OverlayPage;
    }
}