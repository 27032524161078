import { PlayerProfile } from './player';

export interface MatchModel {
    id: string;
    round: string;
    code: string;
    type: string;
    winner: string;
    round_name: any;
    starts_at: string;
    court: string;
    // player 1
    p1_id: string;
    p1_first_name: string;
    p1_last_name: string;
    p1_seed: string;
    p1_photo: string;
    p1_country: string;
    p1_suffix: string;
    // partner player 1
    p1_partner_id: string;
    p1_partner_first_name: string;
    p1_partner_last_name: string;
    p1_panter_seed: string;
    p1_partner_photo: string;
    p1_partner_country: string;
    p1_partner_suffix: string;
    // player 2
    p2_id: string;
    p2_first_name: string;
    p2_last_name: string;
    p2_seed: string;
    p2_photo: string;
    p2_country: string;
    p2_suffix: string;
    // partner player 2
    p2_partner_id: string;
    p2_partner_first_name: string;
    p2_partner_last_name: string;
    p2_panter_seed: string;
    p2_partner_photo: string;
    p2_partner_country: string;
    p2_partner_suffix: string;
    sets?: object;
    set_scores: SetScoreModel[];
    preloaded?: boolean;
    bye?: boolean;
    status?: string;
    show_potential_player_vs_player?: boolean;
    team1_bye: string | null;
    team2_bye: string | null;
    team1_sets: MatchSet[]
    team2_sets: MatchSet[]
}

export interface SetScoreModel {
    team1_score: number;
    team2_score: number;
    winner: string;
}

export interface MatchSet {
    games: number
    order: number
    tiebreak: number
}

// v2

export interface MatchUpdateModel {
    code: string;
    id: string;
    status: string;
    duration: string;
    finished_at: string;
    streaming_config?: MatchStreamingConfigModel;
    court: {
        id: string;
        name: string;
        order: number;
    };
    team1: {
        is_serving: boolean;
        is_winner: boolean;
        points: string;
        sets: {};
    };
    team2: {
        is_serving: boolean;
        is_winner: boolean;
        points: string;
        sets: {};
    };
    tournament_id: string;
    tyoe: string;
    updated_at: string;
}

export interface ScoreUpdateModel {
    id: string;
    starts_at: string;
    type: string;
    status: string;
    display_status?: string;
    code: string;
    order: number;
    court: {
        id: string;
        name: string;
        order: number;
    };
    stats: { [key: string]: any };
    round: {
        id: string;
        name: string;
    };
    team1: {
        is_serving: boolean;
        is_winner: boolean;
        points: string;
        sets: {};
        live_update?: boolean;
        seed: string;
        player1: PlayerProfile;
        player2: PlayerProfile;
        is_bye: boolean;
    };
    team2: {
        is_serving: boolean;
        is_winner: boolean;
        points: string;
        sets: {};
        live_update?: boolean;
        seed: string;
        player1: PlayerProfile;
        player2: PlayerProfile;
        is_bye: boolean;
    };
    gaming_config: {
        streak_gaming: {
            enabled: boolean;
            rally_streak_time_seconds: number;
        };
        daily_picks_gaming: { [key: string]: any };
    };
    tournament_id: string;
    duration?: string;
    lvision_url?: string;
    matches?: [];
    streaming_config?: MatchStreamingConfigModel;
}


export interface MatchStreamingConfigModel {
    score_bug?: boolean;
    hide_game_score?: boolean;
    hide_widget?: boolean;
    hide_flags?: boolean;
    hide_photos?: boolean;
    scaling?: number;
    width?: number;
    x?: number;
    y?: number;
}

export type MatchStat = 'aces' | 'break_points_saved' | 'break_points_won' | 'double_faults' |
    'first_return_points_won' | 'first_serve_percentage' | 'first_serve_points_won' | 'second_return_points_won' |
    'second_serve_points_won' | 'service_games_played' | 'total_first_serve_points' | 'total_points_won' | 'total_points_won_percentage' |
    'total_return_points_won_percentage' | 'total_second_serve_points' | 'total_service_points_won_percentage' | 'total_service_games_won' |
    'total_return_points_won' | 'total_return_games_won' | 'total_games_won' | 'winners' | 'max_points_in_row' | 'aces_1s' | 'aces_2s' |
    'aces_3s';



