import { Directive, ElementRef, Input } from '@angular/core';
import { AnalyticService } from '../services/analytic.service';

@Directive({
    selector: '[appObserver]'
})
export class NgObserverDirective {
    @Input() custom;
    private reported = false;
    constructor(private analytics: AnalyticService, el: ElementRef) {
        const ref = el.nativeElement as Element;
        this.startup(ref);
    }

    startup = (element: Element) => {
        const observerOptions = {
            root: null,
            rootMargin: '0px',
            threshold: [0.0, 0.11, 0.20]
        };

        // Set up the observer for this box
        const observer = new IntersectionObserver(this.intersectionCallback, observerOptions);
        observer.observe(element);
    }

    intersectionCallback = (entries) => {
        entries.forEach((entry) => {
            if (!this.reported && entry.intersectionRatio > 0.1) {
                switch (this.custom.card_type) {
                    case 'sponsor':
                        this.analytics.handleSponsorClick(this.custom.data[0], 'sponsor', 'Sponsor banner visible');
                        break;
                    case 'clock':
                        this.analytics.handleClockClick(this.custom.data[0], 'impression');
                        break;
                    default:
                        this.analytic('view');
                }
                this.reported = true;
            } else if (this.reported && entry.intersectionRatio === 0) {
                this.reported = false;
            }
        });
    }

    analytic(action: string): void {
        const event = {
            action,
            title: this.custom.title,
            subtitle: this.custom.subtitle,
            content_url: this.custom.data[0].url,
            image_url: this.custom.data[0].photo_url,
            content_text: this.custom.data[0].text,
            is_sponsored: this.custom.context.is_sponsored ? this.custom.context.is_sponsored : null,
            type: this.custom.card_type,
            uuid: this.custom.context.uuid
        };
        this.analytics.postEvent('homescreen_custom', event).subscribe(
            data => {
            },
            error => {
                console.log(error);
            });
    }
}
