import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class FontService {
    private fonts = {
        HelveticaBold: { 'font-weight': 'bold' },
        HelveticaBoldOblique: { 'font-weight': 'bold', 'font-style': 'oblique' },
        HelveticaLight: { 'font-weight': 'lighter' },
        HelveticaLightOblique: { 'font-weight': 'lighter', 'font-style': 'oblique' },
        HelveticaOblique: { 'font-style': 'oblique' },
        HelveticaNeueBold: { 'font-weight': 'bold' },
        HelveticaNeueBoldItalic: { 'font-weight': 'bold', 'font-style': 'italic' },
        HelveticaNeueCondensedBlack: { 'font-family': 'Roboto Condensed sans-serif' },
        HelveticaNeueCondensedBold: { 'font-family': 'Roboto Condensed sans-serif' },
        HelveticaNeueItalic: { 'font-style': 'italic' },
        HelveticaNeueLight: { 'font-weight': 'lighter' },
        HelveticaNeueLightItalic: { 'font-weight': 'lighter', 'font-style': 'italic' },
        HelveticaNeueMediumItalic: { 'font-style': 'italic' },
        HelveticaNeueUltraLight: { 'font-weight': '100' },
        HelveticaNeueUltraLightItalic: { 'font-weight': '100', 'font-style': 'italic' },
        HelveticaNeueThin: { 'font-weight': '100' },
        HelveticaNeueThinItalic: { 'font-weight': '100', 'font-style': 'italic' },
        ItauDisplay: { 'font-family': 'ItauDisplay', 'font-weight': '400' },
        ItauDisplayBold: { 'font-family': 'ItauDisplay', 'font-weight': '700' },
        ItauDisplayExtraBold: { 'font-family': 'ItauDisplay', 'font-weight': '800' },
        Gilroy: { 'font-family': 'Gilroy', 'font-weight': '400' },
        GilroyBold: { 'font-family': 'Gilroy', 'font-weight': '700' },
        GilroyExtraBold: { 'font-family': 'Gilroy', 'font-weight': '800' }
    };
    private defaultFont = '';

    constructor(route: ActivatedRoute) {
        if (route.snapshot.queryParamMap.has('defaultFont')) {
            this.defaultFont = route.snapshot.queryParamMap.get('defaultFont');
        }
    }

    handleFont = (font: string): string => {
        const cleaned = this.defaultFont.length > 0 ? this.defaultFont : font.replace(/-/g, '');

        if (this.fonts[cleaned]) {
            return this.fonts[cleaned];
        }
    }
}
