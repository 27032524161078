export interface StreamingChannel {
    id: string;
    webclient_key: string;
    tennis_match_id: string;
    stream_url: string;
    scorebug_logo_overlay: ScorebugLogoOverlay;
    banner_overlay: BannerOverlay;
    ticker_overlay: TickerOverlay;
}

export interface ScorebugLogoOverlay {
    x: number;
    y: number;
    height: number;
    scale: number;
    current_image: number;
    hidden: boolean;
    images: string[];
}

export interface BannerOverlay extends ScorebugLogoOverlay { }

export interface TickerOverlay {
    x: number;
    y: number;
    height: number;
    width: number;
    scale: number;
    text_color: string;
    background_color: string;
    background_opacity: number;
    ticker_data_url: string;
    hidden: boolean;
    logos: string[];
    static_message_every_X_ticker_results: number;
    static_pairs: TickerStaticPair[];
}

export interface TickerStaticPair {
    message: string;
    logo: string;
}

export interface Ticker {
    match_id: string;
    tournament_id: string;
    team_id: string;
    tournament_name: string;
    ticker_type: 'live' | 'upcoming' | 'completed';
    starts_at: string;
    message: string;
}
