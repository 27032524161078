import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { HttpOptionsHeaders } from '../services/http-option-headers';
import { environment } from 'src/environments/environment';
import { MatchModel, ScoreUpdateModel } from '../_interfaces/match';
import { ActiveFilterModel, DataResponseModel, TennisScheduleModel } from '../_interfaces';
export interface DrawRoundsDetails {
    name: string;
    round_code: string;
    round_points: string;
    round_prize_money: string;
}
export interface DrawsData {
    match_data: MatchModel[],
    round_data: DrawRoundsDetails[]
}

export interface DrawTypesModel {
    [key: string]: string
}

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    public schedule_entries: TennisScheduleModel[] = [];
    private filter = new Subject<ActiveFilterModel>();
    private helper = new HttpOptionsHeaders();
    constructor(private http: HttpClient) { }

    setScheduleEntries(entry_arr: TennisScheduleModel[]) {
        this.schedule_entries = entry_arr;
    }

    getMatchesForDraws(team_id: string, type: string): Promise<DrawsData> {
        const headers = this.helper.buildBasicRequestHeader();
        return this.http.get<DrawsData>(`${environment.webclient_api}/api/matches/${team_id}/tournament_type/${type}`, headers).toPromise();
    }

    getMatches(team_id?: string): Observable<any> {
        const headers = this.helper.buildBasicRequestHeader();
        return this.http.get(`${environment.FE_API_THREE}/tennis/tournaments/${team_id}/matches`, headers);
    }

    getPlayerDetails(player_id?: string): Observable<any> {
        const headers = this.helper.buildBasicRequestHeader();
        return this.http.get(`${environment.FE_API_FOUR}/tennis/players/${player_id}`, headers);
    }

    getPlayerDetailsPromise(player_id: string): Promise<any> {
        const headers = this.helper.buildBasicRequestHeader();
        return this.http.get(`${environment.FE_API_FOUR}/tennis/players/${player_id}`, headers).toPromise()
            .then(data => {
                return data['data'];
            })
            .catch(err => {
                console.error(err);
                return null;
            });
    }

    getMatchDetails(match_id: string): Observable<DataResponseModel<ScoreUpdateModel>> {
        const headers = this.helper.buildBasicRequestHeader();
        return this.http.get<DataResponseModel<ScoreUpdateModel>>(`${environment.FE_API_THREE}/tennis/matches/${match_id}`, headers);
    }

    getMatchDetailsPromise(match_id: string): Promise<ScoreUpdateModel> {
        const headers = this.helper.buildBasicRequestHeader();
        return this.http.get<ScoreUpdateModel>(`${environment.FE_API_THREE}/tennis/matches/${match_id}`, headers).toPromise()
            .then(data => {
                return data['data'];
            })
            .catch(err => {
                console.error(err);
                return null;
            });
    }

    async getMatchById(match_id: string) {
        const headers = this.helper.buildBasicRequestHeader();
        return this.http.get(`${environment.BLEACHR_WEB_API}/matches/${match_id}`, headers).toPromise()
            .then(data => {
                return data['data'];
            })
            .catch(err => {
                console.error(err);
                return null;
            });
    }

    /**
     * 
     * @param team_id uuid string
     * @returns DrawTypesModel[]
     */
    async getMatchTypes(team_id: string): Promise<DrawTypesModel[]> {
        const headers = this.helper.buildBasicRequestHeader();
        try {
            return await this.http.get<{ data: DrawTypesModel[], error: string }>(`${environment.BLEACHR_WEB_API}/tournaments/match-types/${team_id}`, headers)
                .toPromise()
                .then((res) => {
                    return res.data;
                });
        } catch (e) {
            console.log(e);
            return [];
        }
    }

    getTournamentSchedules(): Observable<any> {
        const headers = this.helper.buildBasicRequestHeader();
        return this.http.get(`${environment.FE_API_FOUR}/tennis_schedule_entries?=`, headers);
    }

    getTennisScheduleEntries(): Promise<any> {
        const headers = this.helper.buildBasicRequestHeader();
        return this.http
            .get(`${environment.FE_API_FOUR}/tennis_schedule_entries?=`, headers)
            .toPromise()
            .then((data) => {
                return data['data'];
            })
            .catch((error) => {
                console.error(error);
                return [];
            });
    }

    getTournamentPlayers(team_id: string): Observable<any> {
        const headers = this.helper.buildBasicRequestHeader();
        return this.http.get(`${environment.FE_API_THREE}/tennis/tournaments/${team_id}/players`, headers);
    }

    getTournaments(team_id: string): Observable<any> {
        const headers = this.helper.buildBasicRequestHeader();
        return this.http.get(`${environment.FE_API_THREE}/tennis/tournaments/${team_id}`, headers);
    }

    setFilter(updatedFilters: ActiveFilterModel) {
        this.filter.next(updatedFilters);
    }

    filterService() {
        return new Observable((observer) => {
            this.filter.subscribe((data) => {
                observer.next(data);
            });
        });
    }

    getDrawsLegend(locale: string): Promise<any> {
        return new Promise((completed, rejected) => {
            this.http
                .get(`${environment.webclient_api}/api/tournament/default/legend/${locale}`)
                .toPromise()
                .then((data) => {
                    completed(data);
                })
                .catch((error) => {
                    console.error(error);
                    rejected();
                });
        });
    }

    getEliteTheme(schedule_entry_id: string): Promise<any> {
        const headers = this.helper.buildBasicRequestHeader();
        return new Promise((completed) => {
            this.http
                .get(`${environment.BLEACHR_WEB_API}/tennis-schedule-entries/elite-theme/${schedule_entry_id}`, headers)
                .toPromise()
                .then((data) => {
                    if (data === null) {
                        completed([]);
                    } else {
                        completed(data);
                    }
                })
                .catch((error) => {
                    console.error(error);
                    completed([]);
                });
        });
    }

    getMatchOdds(match_id: string): Observable<any> {
        const headers = this.helper.buildRequestHeaders();
        return this.http.get(`${environment.gaming_api}/bracket/odds/${match_id}`, headers);
    }

    getMatchOddsPromise(match_id: string): Promise<any> {
        const headers = this.helper.buildRequestHeaders();
        return this.http.get(`${environment.gaming_api}/bracket/odds/${match_id}`, headers).toPromise()
            .then(data => {
                return data['data'];
            })
            .catch(err => {
                console.error(err);
                return [];
            });
    }

    async getRubberByTeamID(team_id: string): Promise<any> {
        try {
            const headers = this.helper.buildBasicRequestHeader();
            const { data } = await this.http.get<{ data: any }>(`${environment.FE_API_FOUR}/tennis/tournaments/${team_id}/rubbers`, headers).toPromise();
            return data;
        } catch (error) {
            console.log(error);
            return null;
        }
    }

    async getAccount(): Promise<any> {
        const headers = this.helper.buildRequestHeaders();
        return await this.http.get(`${environment.FE_API_FOUR}/accounts`, headers).toPromise();
    }
}
