import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpOptionsHeaders } from './http-option-headers';
import { SponsorModel } from '../_interfaces';

@Injectable({
    providedIn: 'root'
})
export class SponsorService {
    public sponsors: { [key: string]: Array<SponsorModel> } = {};
    private indices: { [key: string]: number } = {};
    public buildHeaders = new HttpOptionsHeaders();

    constructor(private http: HttpClient) {
        this.sponsors = {};
        this.indices = {};
    }

    getPageSponsor = (banner: string): SponsorModel | null => {
        this.nextSponsor(banner);
        return this.getSponsor(banner);
    }

    getAccountSponsors(): Observable<any> {
        const headers = this.buildHeaders.buildBasicRequestHeader();

        return this.http.get(`${environment.FE_API_FOUR}/sponsors`, headers);
    }

    setSponsors(sponsors: Array<SponsorModel>): void {
        sponsors.forEach((s) => {
            if (!this.sponsors.hasOwnProperty(s.key)) {
                this.sponsors[s.key] = [];
                this.indices[s.key] = -1;
            }
            this.sponsors[s.key].push(s);
        });
        Object.values(this.sponsors).forEach((val) => {
            val.sort((a, b) => a.priority - b.priority);
        });
    }

    getSponsor(key: string): SponsorModel | null {
        if (this.sponsors.hasOwnProperty(key)) {
            return this.sponsors[key][this.indices[key]];
        } else {
            return null;
        }
    }

    nextSponsor(key: string): void {
        if (this.sponsors.hasOwnProperty(key)) {
            this.indices[key] += 1;
            if (this.indices[key] >= this.sponsors[key].length) {
                this.indices[key] = 0;
            }
        } else {
            return;
        }
    }
}
