import { Pipe, PipeTransform } from '@angular/core';
import { orderBy } from 'lodash-es';


@Pipe({
    name: 'orderBy',
    pure: false
})
export class OrderByPipe implements PipeTransform {

    transform(collection: any[], order: 'asc' | 'desc', column: string): any[] {
        if (!collection || !Array.isArray(collection)) {
            return collection;
        }
        if (collection.length <= 1 || !column || column.length <= 0) {
            return collection;
        }
        return orderBy(collection, [column], [order]);
    }

}
