import { Component, OnInit, Input } from '@angular/core';
import { AnalyticService } from '../../services/analytic.service';

@Component({
    selector: 'app-homescreen-sponsor-card',
    templateUrl: './homescreen-sponsor-card.component.html',
    styleUrls: ['./homescreen-sponsor-card.component.scss']
})
export class HomescreenSponsorCardComponent implements OnInit {
    @Input() data;
    public sponsor: { [key: string]: any };
    public handleSponsorClick;
    constructor(private analytics: AnalyticService) {
        this.handleSponsorClick = this.analytics.handleSponsorClick;
    }

    ngOnInit() {
        this.sponsor = this.data.data[0];
    }
}

