import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'seedRules'
})
export class SeedRulesPipe implements PipeTransform {
    transform(value: { [key: string]: string | number }, ...args: unknown[]): string {
        const { seed, suffix } = value;
        let output = '';
        if (suffix !== undefined && suffix !== null) {
            output += suffix;
        } else if (seed !== 0 && seed !== null && suffix === null) {
            output += seed;
        } else {
            output += '-';
        }
        return output;
    }
}
