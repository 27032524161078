<a [href]="buildURL()">
    <div class="timeline-item-header" [style.color]="social_feed_item.title_color">
        <h2 class="title" [style.text-align]="social_feed_item.context.title.alignment"
            [style.font-size.px]="social_feed_item.context.title.font_size"
            [ngStyle]="handleFont(social_feed_item.context.title.font)">
            {{social_feed_item.title}}
        </h2>
        <h5 [style.color]="social_feed_item.see_all_color">
            {{social_feed_item.see_all}}
        </h5>
    </div>
    <div class="subtitle" [style.color]="social_feed_item.subtitle_color"
        [style.text-align]="social_feed_item.context.subtitle.alignment"
        [style.font-size.px]="social_feed_item.context.subtitle.font_size"
        [ngStyle]="handleFont(social_feed_item.context.title.font)">
        {{social_feed_item.subtitle}}
    </div>

    <mat-card class="mat-elevation-z4"
        [style.border]="social_feed_item.context.card_border ? '2px solid ' + social_feed_item.context.card_border : '' "
        [ngStyle]="handleShadow(social_feed_item.context.card_shadow)" [style.color]="social_feed_item.body_color"
        [style.text-align]="social_feed_item.context.subtitle.alignment"
        [style.font-size.px]="social_feed_item.context.subtitle.font_size"
        [ngStyle]="handleFont(social_feed_item.context.subtitle.font)">
        <video preload="auto" class="social-post-video" *ngIf='checkMediaType(social_feed_item.data[0], "video")'
            controls [src]='checkURL(social_feed_item.data[0].items[0].media_content)'></video>
        <img class="social-post-image" mat-card-image *ngIf='checkMediaType(social_feed_item.data[0], "photo")'
            [src]='checkURL(social_feed_item.data[0].items[0].media_content)' alt="social post image">
        <img class="social-post-image" mat-card-image *ngIf='checkMediaType(social_feed_item.data[0], "web_photo")'
            [src]='checkURL(social_feed_item.data[0].items[0].media_content)' alt="social post image">
        <!-- Youtube Hack -->
        <a class="youtube-link" *ngIf='checkMediaType(social_feed_item.data[0], "youtube")'
            [href]='social_feed_item.data[0].items[0].link'>
            <div class="youtube-image"
                [style.backgroundImage]="'url('+ checkURL(social_feed_item.data[0].items[0].media_content)+')'">
                <mat-icon class="play-icon">play_arrow</mat-icon>
            </div>
        </a>
        <mat-card-footer
            [style.border-top]="social_feed_item.context.content_divider ? '2px solid' + social_feed_item.context.content_divider : '' "
            [style.text-align]="social_feed_item.context.body_text.alignment"
            [style.font-size.px]="social_feed_item.context.body_text.font_size"
            [ngStyle]="handleFont(social_feed_item.context.body_text.font)">
            <div class="fan-details">
                <img class="fan-profile-photo" [src]='checkURL(social_feed_item.data[0].profile_icon)'
                    alt="profile photo">
                <div>
                    {{social_feed_item.data[0].name}}
                </div>
            </div>
            <div class="fan-post">
                {{clipContentString(social_feed_item.data[0].items[0].description)}}
            </div>
        </mat-card-footer>
    </mat-card>
</a>