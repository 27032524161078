import { Component, Input } from '@angular/core';
import { APP_STRING_KEYS, STATS_APP_STRING_KEYS } from 'src/app/_constants';
import { AppStringsService } from 'src/app/_services';

@Component({
    selector: 'app-string',
    templateUrl: './app-string.component.html',
    styleUrls: ['./app-string.component.scss']
})
export class AppStringComponent {
    @Input() key_name: string;
    @Input() match_status: string;
    @Input() match_stats_string: string = '';

    public app_string_keys = APP_STRING_KEYS;

    constructor(
        private appStringAPI: AppStringsService
    ) { }

    get appString(): string {
        if (this.match_stats_string.length > 0) {
            return this.handleStats(this.match_stats_string);
        }
        if (this.key_name) {
            return this.appStringAPI.cache.has(this.key_name.toLowerCase()) ? this.appStringAPI.cache.get(this.key_name.toLowerCase()) : '';
        }
        if (this.match_status) {
            switch (this.match_status) {
                case 'playing':
                    return this.appStringAPI.cache.has(this.app_string_keys.TOGGLE_LIVE) ? this.appStringAPI.cache.get(this.app_string_keys.TOGGLE_LIVE).toUpperCase() : '';
                case 'finished':
                case 'finished_recently':
                    return this.appStringAPI.cache.has(this.app_string_keys.FINAL) ? this.appStringAPI.cache.get(this.app_string_keys.FINAL).toUpperCase() : '';
                case 'on_court':
                    return this.appStringAPI.cache.has(this.app_string_keys.ON_COURT) ? this.appStringAPI.cache.get(this.app_string_keys.ON_COURT).toUpperCase() : '';
                case 'finished_retired':
                    return this.appStringAPI.cache.has(this.app_string_keys.RETIREMENT) ? this.appStringAPI.cache.get(this.app_string_keys.RETIREMENT).toUpperCase() : '';
                case 'finished_walkover':
                    return this.appStringAPI.cache.has(this.app_string_keys.WALKOVER) ? this.appStringAPI.cache.get(this.app_string_keys.WALKOVER).toUpperCase() : '';
                case null:
                    return '';
                case 'warmup':
                case 'suspended':
                default:
                    return this.match_status.toUpperCase();
            }
        }
    }

    handleStats(key_name: string): string {
        const app_string = STATS_APP_STRING_KEYS.get(key_name) as string;
        return this.appStringAPI.cache.get(app_string) ?? key_name.replace(/_/g, ' ');
    }
}
