import { Component, Input } from '@angular/core';
import { helpers } from '../../helpers';
import { FontService } from '../../services/font-service.service';

@Component({
    selector: 'app-homescreen-challenge-card',
    templateUrl: './homescreen-challenge-card.component.html',
    styleUrls: ['./homescreen-challenge-card.component.scss']
})
export class HomescreenChallengeCardComponent {
    @Input() challenge;
    public handleShadow = helpers.handleShadow;
    public handleFont: Function;
    constructor(font: FontService) {
        this.handleFont = font.handleFont;
    }

    buildURL() {
        const fake_url = `${window.location.href}/challenge}`;

        return fake_url;
    }
}
