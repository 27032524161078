import { PaginationData } from ".";

export interface TennisStreamer {
    api_key: string;
    created_at: Date | string;
    dark_logo_url: string;
    label: string;
    light_logo_url: string;
    portal_password: string;
    updated_at: Date | string;
}

export interface PaginatedTennisStreamers { 
    items: TennisStreamer[];
    pagination: PaginationData;
}