<div [ngClass]="inactive ? 'toggle-container disabled-toggle' : 'toggle-container'" *ngIf="toggle.id !== 'all_players_my_players'">
    <div class="background"></div>
    <span
        *ngFor="let filter of toggle.filters"
        [id]="toggle.id + '-' + filter.value"
        (click)="handleToggle(filter.value)"
        [class]="handleLabelClass(filter.value)">
        {{ filter.label }}
    </span>
    <div [id]="toggle.id"></div>
</div>

<div *ngIf="toggle.id === 'all_players_my_players'" class="player-list-toggle">
    <div *ngFor="let filter of toggle.filters" class="player-list-container">
        <div id="active-line"></div>
        <span
            *ngIf="!filter.img_src"
            [id]="toggle.id + '-' + filter.value"
            (click)="handleToggle(filter.value)"
            (click)="toggleAllPlayers(filter.value)"
            [class]="handleLabelClass(filter.value)"
            class="all-players-label list-label">
            {{ filter.label }}
        </span>
        <img 
            *ngIf="filter.img_src"
            [src]="filter.img_src" 
            class="filter-image list-label"
            [id]="toggle.id + '-' + filter.value"
            (click)="handleToggle(filter.value)"
            (click)="toggleAllPlayers(filter.value)"
            [class]="handleLabelClass(filter.value)">
    </div>
    <div [id]="toggle.id"></div>
</div>