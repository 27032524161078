import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// MATERIAL
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';

// COMPONENTS
import { HomescreenCoreComponent } from './homescreen-core/homescreen-core.component';
import { HomescreenChallengeCardComponent } from './homescreen-challenge-card/homescreen-challenge-card.component';
import { HomescreenEnhancedArticlesComponentComponent } from './homescreen-enhanced-articles-component/homescreen-enhanced-articles-component.component';
import { HomescreenFanStreamComponentComponent } from './homescreen-fan-stream-component/homescreen-fan-stream-component.component';
import { HomescreenFollowedPlayerCardComponent } from './homescreen-followed-player-card/homescreen-followed-player-card.component';
import { HomescreenSocialFeedCardComponent } from './homescreen-social-feed-card/homescreen-social-feed-card.component';
import { HomescreenSocialFeedComponentComponent } from './homescreen-social-feed-component/homescreen-social-feed-component.component';
import { HomescreenSponsorCardComponent } from './homescreen-sponsor-card/homescreen-sponsor-card.component';
import { HomescreenSponsorRotationComponent } from './homescreen-sponsor-rotation/homescreen-sponsor-rotation.component';
import { HomescreenStandloneCardComponent } from './homescreen-standlone-card/homescreen-standlone-card.component';
import { HomescreenTennisMatchCardComponent } from './homescreen-tennis-match-card/homescreen-tennis-match-card.component';
import { HomescreenTriviaCardComponent } from './homescreen-trivia-card/homescreen-trivia-card.component';
import { HomescreenWebViewComponent } from './homescreen-web-view/homescreen-web-view.component';
import { GhostComponent } from './ghost/ghost.component';
import { NgObserverDirective } from './observer.directive';
import { SharedModule } from '../shared/shared.module';


@NgModule({
    declarations: [
        HomescreenCoreComponent,
        HomescreenChallengeCardComponent,
        HomescreenEnhancedArticlesComponentComponent,
        HomescreenFanStreamComponentComponent,
        HomescreenFollowedPlayerCardComponent,
        HomescreenSocialFeedCardComponent,
        HomescreenSocialFeedComponentComponent,
        HomescreenSponsorCardComponent,
        HomescreenSponsorRotationComponent,
        HomescreenStandloneCardComponent,
        HomescreenTennisMatchCardComponent,
        HomescreenTriviaCardComponent,
        HomescreenWebViewComponent,
        GhostComponent,
        NgObserverDirective
    ],
    imports: [
        CommonModule,
        MatCardModule,
        MatIconModule,
        SharedModule
    ],
    exports: [
        HomescreenCoreComponent,
        HomescreenSocialFeedComponentComponent,
        HomescreenStandloneCardComponent
    ]
})
export class HomescreenModule { }
