export interface DrawMatch {
    tournament_id: string
    show_potential_player_vs_player: boolean
    id: string
    round: Round
    code: string
    type: string
    status: string
    winner: TeamsNames
    court: string
    starts_at: string
    team1: Team
    team2: Team
    preloaded?: boolean
}

export type TeamsNames = 'team1' | 'team2'

export interface Round {
    id: string
    name: string
    order: string
}

export interface Team {
    player1: PlayerDetails
    player2: PlayerDetails
    points: string
    sets: Set[]
    is_serving: boolean
    is_winner: boolean
}

export interface PlayerDetails {
    id: string
    first_name: string
    last_name: string
    country: string
    seed: number
    suffix: string
    photo: string
    is_bye?: boolean
    following?: boolean
    display_country?: string
    display_name?: string
    rank?: string
    score?: Set[]
}

export interface TennisPlayer {
    country: string;
    details: {};
    doubles_points: number;
    doubles_rank: number;
    first_name: string;
    gender: string;
    id: string;
    last_name: string;
    merchandise_url: string;
    photo: string;
    player_sponsor_url: string;
    singles_points: number;
    singles_rank: number;
    stats: {};
    tour: string;
    display_name?: string;
    feed_id?: string;
}

export interface Set {
    games: number
    order: number
    tiebreak: number
}
