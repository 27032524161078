export interface LeaderboardModel {
    fan: LeaderBoardFanModel;
    trivia_score: string;
    streak_score: string;
    daily_picks_score: string;
    total: string;
    rank: string;
    isFan?: boolean;
    card_score?: string;
}

export interface LeaderBoardFanModel {
    id: string;
    display_name: string;
    profile_photo: string;
    name: string;
}
