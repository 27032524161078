<a [href]="buildURL()">
  <div class="timeline-item-header" [style.color]="challenge.title_color">
    <h2 class="title" [style.text-align]="challenge.context.title.alignment"
                      [style.font-size.px]="challenge.context.title.font_size"
                      [ngStyle]="handleFont(challenge.context.title.font)">
      {{challenge.title}}
    </h2>
    <h5 [style.color]="challenge.see_all_color">
      {{challenge.see_all}}
    </h5>
  </div>
  <div class="subtitle" [style.color]="challenge.subtitle_color"
                        [style.text-align]="challenge.context.subtitle.alignment"
                        [style.font-size.px]="challenge.context.subtitle.font_size"
                        [ngStyle]="handleFont(challenge.context.subtitle.font)">
    {{challenge.subtitle}}
  </div>
  <div class="card">
    <mat-card class="mat-elevation-z4"  [style.color]="challenge.body_color"
                                        [style.border]="challenge.context.card_border ? '2px solid ' + challenge.context.card_border : '' "
                                        [ngStyle]="handleShadow(challenge.context.card_shadow)"
                                        [style.text-align]="challenge.context.body_text.alignment"
                                        [style.font-size.px]="challenge.context.body_text.font_size"
                                        [ngStyle]="handleFont(challenge.context.body_text.font)">
      <mat-card-header>
        <div mat-card-avatar class="header-image" [style.backgroundColor]='challenge.theme_color'><img src="https://res.cloudinary.com/bleachr/image/upload/v1544636970/trophyFilled_3x_yvvrjc.png" alt=""></div>
        <mat-card-title>Challenge</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <h4>
          {{ challenge.data[0].challenge}}
        </h4>
      </mat-card-content>
    </mat-card>
  </div>
</a>