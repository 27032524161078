import { Court, Team } from '../_interfaces';

export type MatchStatusTypes = 'cancelled' | 'finished' | 'finished_retired' | 'finished_walkover' | 'playing' | 'suspended' | 'warmup';

export interface MatchScoreUpdate {
    code: string;
    court: Court;
    duration: null;
    finished_at: string;
    id: string;
    status: MatchStatusTypes;
    streaming_config?: any;
    team1: Team;
    team2: Team;
    tournament_id: string;
    type: string;
    updated_at: string;
}