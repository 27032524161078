import { Injectable } from '@angular/core';
import { ApiService } from '../scores-module/api.service';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { AppStringsService } from 'src/app/_services';
import { APP_STRING_KEYS } from 'src/app/_constants';
import { CourtModel, DisplayMatchModel, ScoreUpdateModel } from '../_interfaces';

@Injectable({
    providedIn: 'root'
})
export class MatchesService {
    public all_matches: ScoreUpdateModel[] = [];
    public all_match_nest: DisplayMatchModel = {};
    public no_court_tourneys: Array<string> = [];
    public has_live_matches = false;
    public time_zone = '';
    public app_string_keys = APP_STRING_KEYS;
    constructor(
        private api: ApiService,
        public appStringAPI: AppStringsService
    ) { }

    buildMatches(team_id: string) {
        if (!this.all_matches.length) {
            return new Promise((completed, rejected) => {
                this.api.getMatches(team_id).subscribe(
                    (data) => {
                        const raw = data['data'];
                        if (raw.length > 0) {
                            this.all_matches = raw;
                            this.all_match_nest = this.assignMatchesToDatesAndCourts(this.all_matches);
                            this.all_match_nest = this.sortByDateAndCourt(this.all_match_nest);
                        }
                        completed(null);
                    },
                    error => {
                        console.error(error);
                        rejected();
                    }
                );
            });
        }
        return;
    }

    sortByDateAndCourt(match_nest): DisplayMatchModel {
        for (const date of Object.keys(match_nest)) {
            this.sortMatchesInCourt(match_nest[date]);
        }

        return match_nest;
    }

    sortMatchesInCourt(courts: CourtModel): CourtModel {
        let hasLive = false;
        for (const name of Object.keys(courts)) {
            // fallback sort in case matches have the same status
            courts[name].matches.sort(function (a, b) {
                return a.order - b.order;
            });

            //@ts-ignore
            courts[name].matches = this.buildMatchDisplayStatuses(courts[name].matches);

            const playing = [];
            const finished_recently = [];
            const starts_at = [];
            const finished = [];
            const followed_by = [];
            const not_before = [];

            const finishedStatuses = ['finished', 'completed', 'finished_retired', 'finished_walkover'];
            const liveStatuses = ['playing', 'on_court', 'warmup', 'suspended'];
            courts[name].matches.forEach((match) => {
                if (match.display_status) {
                    if (liveStatuses.some((status) => status === match.display_status)) {
                        playing.push(match);
                    } else if (match.display_status === 'finished_recently') {
                        finished_recently.push(match);
                    } else if (!Number.isNaN(+(match.display_status.charAt(0)))) {
                        starts_at.push(match);
                    } else if (match.display_status === 'FOLLOWED BY') {
                        followed_by.push(match);
                    } else if (match.display_status.includes('ESTIMATED START')) {
                        not_before.push(match);
                    } else if (finishedStatuses.some((status) => status === match.display_status)) {
                        finished.push(match);
                    }
                }
            });

            const useLiveToggle = playing.length || finished_recently.length;

            if (useLiveToggle && !hasLive) {
                hasLive = true;
            }

            //@ts-ignore
            courts[name].matches = [...playing, ...finished_recently, ...starts_at, ...not_before, ...followed_by, ...finished];
        }
        this.has_live_matches = hasLive;
        return courts;
    }

    buildMatchDisplayStatuses(matches: ScoreUpdateModel[]) {
        const ESTIMATED_START = this.appStringAPI.getAppString(this.app_string_keys.ESTIMATED_START).toUpperCase();
        matches.forEach((match, i) => {
            if (match.display_status !== null) {
                return;
            } else if (this.no_court_tourneys.includes(match.tournament_id)) {
                match.display_status = `${ESTIMATED_START} ${format(new Date(match.starts_at), 'p')}`;
            } else {
                if (i === 0) {
                    match.display_status = `${format(new Date(match.starts_at), 'p')}`;
                } else if (match.starts_at === matches[i - 1].starts_at) {
                    match.display_status = this.appStringAPI.getAppString(this.app_string_keys.FOLLOWED_BY).toUpperCase();
                } else if (match.starts_at !== matches[i - 1].starts_at) {
                    match.display_status = `${ESTIMATED_START} ${format(new Date(match.starts_at), 'p')}`;
                } else {
                    match.display_status = match.status;
                }
            }
        });

        return matches;
    }

    filterMatchesByPlayer(player_id: string): ScoreUpdateModel[] {
        if (player_id) {
            return this.all_matches.filter(match => {
                const ids = [match.team1.player1.id, match.team1.player2.id, match.team2.player1.id, match.team2.player2.id];
                if (ids.includes(player_id)) {
                    return match;
                }
            });
        }

        return [];
    }

    assignMatchesToDates(arr: any[]) {
        const tz_name = this.time_zone;

        return arr.reduce((acc, rub) => {
            const date = rub.date !== null ? format(utcToZonedTime(new Date(rub.date), tz_name), 'yyyy-MM-dd') : 'unscheduled';
            if (!acc[date]) {
                acc[date] = rub;
            }
            return acc;
        }, {});
    }

    assignMatchesToDatesAndCourts(matches: ScoreUpdateModel[]): DisplayMatchModel {
        const tz_name = this.time_zone;
        return matches.reduce(function (acc: { [key: string]: any }, obj: { [key: string]: any }, i: number) {
            const date: string = obj.starts_at !== null ? format(utcToZonedTime(new Date(obj.starts_at), tz_name), 'yyyy-MM-dd') : 'unscheduled';
            const court: string = obj.court.name !== null ? `${obj.court.order}_${obj.court.name}` : 'unscheduled';
            const display_status = obj.status;

            if (!acc[date]) {
                acc[date] = {};
            }
            if (!acc[date][court]) {
                acc[date][court] = {
                    order: obj.court.order,
                    matches: []
                };
            }

            obj.display_status = display_status;
            acc[date][court].matches.push(obj);
            return acc;
        }, {});
    }

    assignMatchesToCourts(matches: ScoreUpdateModel[]): CourtModel {
        return matches.reduce(function (acc: { [key: string]: any }, obj: { [key: string]: any }, i: number) {
            const court: string = obj.court.name !== null ? `${obj.court.order}_${obj.court.name}` : 'unscheduled';

            if (!acc[court]) {
                acc[court] = {
                    order: obj.court.order,
                    matches: []
                };
            }
            acc[court].matches.push(obj);
            return acc;
        }, {});
    }

    buildCourtName(key: string) {
        if (key === 'unscheduled') {
            return '';
        } else {
            return key.split('_')[1];
        }
    }

    getMatches(): ScoreUpdateModel[] {
        return this.all_matches;
    }

    getCourtMatches(): DisplayMatchModel {
        return this.all_match_nest;
    }

    setAllMatches(all_matches) {
        this.all_matches = all_matches;
    }

    setMatchNest(nest) {
        this.all_match_nest = nest;
    }

    setTimeZone(tz: string) {
        this.time_zone = tz;
    }

    basicSort(a, b) {
        if (a.key === 'unscheduled') {
            return 1;
        }
        if (b.key === 'unscheduled') {
            return -1;
        }
        return (a.value.order > b.value.order) ? 1 : -1;
    }

    setNoCourtTourneys(tourney_ids: Array<string>) {
        this.no_court_tourneys = tourney_ids;
    }

    getTournaments(team_id: string) {
        return new Promise((resolve, reject) => {
            this.api.getTournaments(team_id).subscribe(
                (data) => {
                    const raw = data['data'];
                    if (raw && raw.brackets) {
                        raw['brackets'].forEach(tourney => {
                            if (!tourney.is_court_info_expected) {
                                this.no_court_tourneys.push(tourney.id);
                            }
                        });
                    }
                    resolve(null);
                },
                error => {
                    console.error(error);
                    reject();
                }
            );
        });
    }
}
