import { Component, OnInit, Input } from '@angular/core';
import { FontService } from '../../services/font-service.service';
import { TimelineItemModel } from '../models/timeline';

@Component({
    selector: 'app-homescreen-enhanced-articles-component',
    templateUrl: './homescreen-enhanced-articles-component.component.html',
    styleUrls: ['./homescreen-enhanced-articles-component.component.scss']
})
export class HomescreenEnhancedArticlesComponentComponent implements OnInit {
    @Input() enhanced_articles: TimelineItemModel;
    @Input() identifier: string;
    public handleFont;
    constructor(font: FontService) {
        this.handleFont = font.handleFont;
    }

    ngOnInit() { }

}
