import { Component, OnInit, Input } from '@angular/core';
import { SponsorModel } from 'src/app/_interfaces';
import { ApiService } from '../../services/api.service';

@Component({
    selector: 'app-homescreen-sponsor-rotation',
    templateUrl: './homescreen-sponsor-rotation.component.html',
    styleUrls: ['./homescreen-sponsor-rotation.component.scss']
})
export class HomescreenSponsorRotationComponent implements OnInit {
    @Input() identifier: string;
    @Input() locale: string;
    public sponsors: SponsorModel[];
    public display_sponsor: SponsorModel;

    constructor(
        private api: ApiService
    ) {
        this.sponsors = [];
        this.display_sponsor = null;
    }

    ngOnInit() {
        this.buildView();
    }

    async buildView() {
        const data = await this.getAccountSponsors(this.identifier, this.locale);

        if (data && data['data'] && data['data'].length > 0) {
            this.sponsors = data['data'];

            this.filterHomescreenSponsors(this.sponsors);
            if (this.sponsors.length > 0) {
                this.sortSponsorsByPriority(this.sponsors);
                this.setDisplaySponsor();
            }
        }
    }

    filterHomescreenSponsors(sponsors: SponsorModel[]) {
        const homescreen_sponsors = sponsors.filter(sponsor => sponsor.key === 'homescreen-rotation');
        this.sponsors = homescreen_sponsors;
    }

    sortSponsorsByPriority(sponsors: SponsorModel[]) {
        sponsors.sort((a, b) => (a.priority < b.priority) ? 1 : (a.priority === b.priority) ? ((a.priority < b.priority) ? 1 : -1) : -1);
    }

    setDisplaySponsor() {
        let newDisplaySponsor = this.sponsors[0];

        const lastUsedSponsorId = window.localStorage.getItem('sponsor_id');

        if (lastUsedSponsorId) {
            const index = this.sponsors.findIndex(sponsor => sponsor.id === lastUsedSponsorId);

            if (index !== -1 && this.sponsors[index + 1]) {
                newDisplaySponsor = this.sponsors[index + 1];
            }
        }

        this.display_sponsor = newDisplaySponsor;
        window.localStorage.setItem('sponsor_id', this.display_sponsor.id);
    }

    getAccountSponsors(identifier: string, locale: string) {
        return new Promise((resolve, reject) => {
            this.api.getAccountSponsors(identifier, locale).subscribe(data => {
                resolve(data);
            }, error => {
                console.error(error);
            });
        });
    }
}
