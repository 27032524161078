import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// MATERIAL
import { MatIconModule } from '@angular/material/icon';

import { SharedRoutingModule } from './shared-routing.module';
import { GlideCarouselComponent } from './glide-carousel/glide-carousel.component';
import { SplideCarouselComponent } from './splide-carousel/splide-carousel.component';
import { AspectRatioDirective } from './aspect-ratio.directive';
import { InfoDialogComponent } from './info-dialog/info-dialog.component';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatDialogModule } from '@angular/material/dialog';
import { AppStringModule } from '../_modules/app-string/app-string.module';
//import { VideoPlayerModule } from '../_modules/video-player/video-player.module';
import { ToggleFilterComponent } from './toggle-filter/toggle-filter.component';
import { ImageComponent } from './image/image.component';
import { ProfileImageComponent } from './profile-image/image.component';
import { StandalonePlayerProfileComponent } from './standalone-player-profile/standalone-player-profile.component';
import { PickPercentageComponent } from './pick-percentage/pick-percentage.component';
import { MatchComponent } from './match/match.component';
import { NoDoublesScoringMatchCardComponent } from './no-doubles-scoring-match-card/no-doubles-scoring-match-card.component';
import { PlayerVsPlayerComponent } from './player-vs-player/player-vs-player.component';
//import { NgObserverDirective } from '../homescreen-module/observer.directive';
import { ScoresTourneyCardComponent } from './scores-tourney-card/scores-tourney-card.component';
import { TrophyDetailComponent } from './trophy-detail/trophy-detail.component';
import { CountrynamePipe } from './_pipes/countryname.pipe';
import { ShortNamePipe } from './_pipes/short-name.pipe';
import { SeedRulesPipe } from './_pipes/seedRules.pipe';
import { MatchStatusTitlePipe } from './_pipes/match-status-title.pipe';
import { DisplayDurationPipe } from './_pipes/display-duration.pipe';
import { SnakecaseToHumanPipe } from './_pipes/snakecase-to-human.pipe';
//import { HomescreenClockComponent } from '../homescreen-module/homescreen-clock/homescreen-clock.component';
import { SponsorBannerComponent } from './sponsor-banner/sponsor-banner.component';
import { WebViewSheetComponent } from './web-view-sheet/web-view-sheet.component';
import { OrderByPipe } from './_pipes/order-by.pipe';
import { MatchLiveScoresComponent } from './match-live-scores/match-live-scores.component';
import { MatchTeamComponent } from './match-live-scores/match-team/match-team.component';
import { MatchCardOptionsComponent } from './match-card-options/match-card-options.component';
//import { IapProductDialogComponent } from './iap-product-dialog/iap-product-dialog.component';
import { ClipContentPipe } from './_pipes/clip-content.pipe';
import { EmptyStateViewComponent } from './empty-state-view/empty-state-view.component';
import { PlyrModule } from './plyr/plyr.module';


@NgModule({
    declarations: [
        GlideCarouselComponent,
        AspectRatioDirective,
        SplideCarouselComponent,
        InfoDialogComponent,
        ToggleFilterComponent,
        ImageComponent,
        ProfileImageComponent,
        StandalonePlayerProfileComponent,
        PickPercentageComponent,
        MatchComponent,
        NoDoublesScoringMatchCardComponent,
        PlayerVsPlayerComponent,
        //NgObserverDirective,
        ScoresTourneyCardComponent,
        TrophyDetailComponent,
        //IapProductDialogComponent,
        CountrynamePipe,
        ShortNamePipe,
        SeedRulesPipe,
        MatchStatusTitlePipe,
        DisplayDurationPipe,
        ClipContentPipe,
        SnakecaseToHumanPipe,
        //HomescreenClockComponent,
        SponsorBannerComponent,
        WebViewSheetComponent,
        //BracketBattlePopupComponent,
        OrderByPipe,
        EmptyStateViewComponent,
        MatchLiveScoresComponent,
        MatchTeamComponent,
        MatchCardOptionsComponent
    ],
    imports: [
        CommonModule,
        MatIconModule,
        SharedRoutingModule,
        MatCardModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        MatBottomSheetModule,
        MatDialogModule,
        AppStringModule,
        PlyrModule
        //VideoPlayerModule
    ],
    exports: [
        GlideCarouselComponent,
        AspectRatioDirective,
        SplideCarouselComponent,
        InfoDialogComponent,
        ToggleFilterComponent,
        ImageComponent,
        ProfileImageComponent,
        StandalonePlayerProfileComponent,
        PickPercentageComponent,
        MatchComponent,
        NoDoublesScoringMatchCardComponent,
        PlayerVsPlayerComponent,
        //NgObserverDirective,
        InfoDialogComponent,
        ScoresTourneyCardComponent,
        GlideCarouselComponent,
        AspectRatioDirective,
        TrophyDetailComponent,
        //IapProductDialogComponent,
        CountrynamePipe,
        ShortNamePipe,
        SeedRulesPipe,
        MatchStatusTitlePipe,
        DisplayDurationPipe,
        ClipContentPipe,
        SnakecaseToHumanPipe,
        //HomescreenClockComponent,
        SponsorBannerComponent,
        //BracketBattlePopupComponent,
        OrderByPipe,
        EmptyStateViewComponent,
        MatchLiveScoresComponent,
        //EnhancedArticleModule,
        MatchCardOptionsComponent
    ]
})
export class SharedModule { }
