import { HttpHeaders } from '@angular/common/http';
import { getUserToken } from './helpers';
import { environment } from 'src/environments/environment';
import { CurrentUserModel } from '../_interfaces';

export class HttpOptionsHeaders {
    buildAuthHeaders(hash: string) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'X-Bleachr': environment.identifier,
                fingerprint: `${hash}`
            })
        };
        return httpOptions;
    }

    buildBasicRequestHeader() {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'X-Bleachr': environment.identifier
            })
        };
        return httpOptions;
    }

    buildAnalyticHeaders(account_id: string, fingerprint: string) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'x-bleachr': account_id,
                'Bleachr-analytics': `Homescreen`,
                'fingerprint': fingerprint
            })
        };
        return httpOptions;
    }

    buildLocaleRequestHeader(locale: string) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'X-Bleachr': environment.identifier,
                'Accept-Language': locale,
                'fingerprint': this.getFingerprint()
            })
        };
        return httpOptions;
    }

    buildRequestHeaders(temp: string = null) {
        const token = temp !== null ? temp : getUserToken();
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: `Token token=${token}`,
                'X-Bleachr': environment.identifier
            })
        };
        return httpOptions;
    }

    buildPostHeaders(fp_hash: string) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: `Token token=${getUserToken()}`,
                'X-Bleachr': environment.identifier,
                fingerprint: `${fp_hash}`
            })
        };
        return httpOptions;
    }

    buildCommentHeaders() {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: `Token token=${getUserToken()}`,
                'X-Bleachr': environment.identifier
            })
        };
        return httpOptions;
    }

    buildGeolocationHeaders(location_string: string, token = null) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'x-bleachr': 'tennis-one',
                'geolocation': location_string,
                Authorization: `Token token=${token}`
            })
        };
        return httpOptions;
    }

    async getUserToken(): Promise<string> {
        const local_user: { token: string } = JSON.parse(localStorage.getItem('TennisONEUser'));
        return local_user.token;
    }

    getFingerprint() {
        const local_user: CurrentUserModel = JSON.parse(localStorage.getItem('TennisONEUser'));
        return local_user ? local_user.fingerprint : '';
    }
}
