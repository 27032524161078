import { MatchStat, Set, Team } from '.'

export interface MatchData {
    chat_enabled?: boolean;
    code: string;
    court: Court;
    created_at: Date;
    duration?: string;
    faceoffs: any[];
    finished_at: Date;
    gaming_config: GamingConfig;
    has_live_video: boolean;
    hawkeye: null;
    id: string;
    live_video_highlights: string;
    live_video_url: null;
    location: string;
    lvision_url: null;
    order: number;
    round: Court;
    show_match_details?: boolean;
    spoiler_match_code: null;
    starts_at: string;
    stats?: { [key in MatchStat]: Teams };
    status: string;
    streaming_config: string;
    team1: Team;
    team2: Team;
    tournament_id: string;
    type: string;
    type_hint: string;
    updated_at: Date;
    winner: null;
    tournament_name?: string;
    tournament_team_id: string;
    display_status?: string;
}

export interface Teams {
    team1: string;
    team2: string;
}

export interface Court {
    id: null | string;
    name: null | string;
    order: number | null;
    city?: string;
}

export interface GamingConfig {
    daily_picks_gaming: Gaming;
    streak_gaming: Gaming;
}

export interface Gaming {
    enabled: boolean;
}

export interface MatchTeam {
    is_bye: boolean;
    is_serving: boolean;
    is_winner: boolean;
    player1: MatchPlayer;
    player2: MatchPlayer;
    points: string;
    rank: number;
    seed: number;
    sets: Set[];
    live_update: boolean;
}

export interface MatchPlayer {
    country: string;
    doubles_points: number;
    doubles_rank: number;
    first_name: string;
    gender: string;
    id: string;
    last_name: string;
    photo: string;
    player_sponsor_url: null;
    rank: number;
    seed: number;
    singles_points: number;
    singles_rank: number;
    suffix: null;
    display_name: string;
}
