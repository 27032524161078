import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PaginatedTennisStreamers } from 'src/app/_interfaces';
import { environment } from 'src/environments/environment';
import { lastValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class TennisStreamerService {

    constructor(
        private http: HttpClient
    ) { }

    async findAll(current_page: number, take: number, label?: string, api_key?: string): Promise<PaginatedTennisStreamers> {
        const params = `?current_page=${current_page}&take=${take}${label ? `&label=${label}` : ''}${api_key ? `&api_key=${api_key}` : ''}`;
        const url = `${environment.BLEACHR_WEB_API}/tennis-streamer${params}`;

        return await lastValueFrom(this.http.get<{ data: PaginatedTennisStreamers }>(url))
            .then(res => res && res.data);
    }
}