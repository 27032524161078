import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import Splide from '@splidejs/splide';

@Component({
    selector: 'app-splide-carousel',
    templateUrl: './splide-carousel.component.html',
    styleUrls: ['./splide-carousel.component.scss']
})
export class SplideCarouselComponent implements OnInit {
    @Input() section_type;
    @Input() referral;
    @Input() identifier;
    @Input() token;
    public slider: Splide;
    public metric_icon = {
        "broadcast.tips.fan.distinct": "https://res.cloudinary.com/bleachr/image/upload/v1639519508/Bleachr/Bleachr%20Icons/fan_tips.png",
        "broadcast.viewers.fan.distinct": "https://res.cloudinary.com/bleachr/image/upload/v1639519508/Bleachr/Bleachr%20Icons/fan_views.png",
        "broadcast.flairs.sold": "https://res.cloudinary.com/bleachr/image/upload/v1639519508/Bleachr/Bleachr%20Icons/flair_sold.png",
        "broadcast.tickets.sold": "https://res.cloudinary.com/bleachr/image/upload/v1639519508/Bleachr/Bleachr%20Icons/tickets_sold.png"
    }

    public image_profile = [];

    constructor( private api: ApiService ) { }

    createSlider(id: string){
        const per_page = id === "team" ? 1.1 : 2.1; 
        const fix_height = id === "team" ? "auto" : "450px"; 
        this.slider = new Splide(`#${id}`, {
            focus: 'center',
            perPage: per_page,
            fixedHeight: fix_height,
            arrows: false,
            pagination: false,
            gap: '1.5rem',
            lazyLoad: 'nearby',
            cover: true,
            preloadPages: 1
        }).mount();
    }

    ngOnInit(): void {
        if(this.section_type === 'team' && this.referral.squad.length > 0){
            this.referral.squad.forEach(async (item) => {
                const fan_data = await this.api.getFanProfile(this.token, this.identifier, item.fan_id);
                this.image_profile.push(fan_data.data.profile_photo);
            });
        }
    }

    async ngAfterViewInit() {
        const id_list = [];
        
        document.querySelectorAll(".card").forEach(el => id_list.push(el.id));
        id_list.forEach(id => this.createSlider(id)); 
    }

    get_percentage(progress, threshold) {
        return (100 / (threshold / progress)) + "%";
    }

}
