<!-- <div class="modal flex-center" (click)="closeSelf()">
  <div class="modal-content flex-center">
    <div *ngIf="info === undefined">
      <h2>Quick Picks</h2>
      <p>Play for a chance to win T1 Bucks to purchase cool gear in the TennisONE ProShop!  Participants with the first, second and third most points at the end of each contest period win, respectively, 100, 50 and 25 T1 Bucks (for a 1-week contest) and 200, 100 and 50 T1 Bucks (for 2-week contests).  Each point earned playing the contest also accrues towards T1 Bucks.  Points awarded for correct picks increase as the tournament progresses to give you a chance right to the end.<a href="https://bleachr-public-static-content.s3.amazonaws.com/tennis-one/rules.html" target="_blank"> See full contest rules here</a> </p>
      <p></p>
      <h2>Points Streak Live</h2>
      <p>Play for a chance to win T1 Bucks to purchase cool gear in the TennisONE ProShop!  Participants with the first, second and third most points at the end of each contest period win, respectively, 100, 50 and 25 T1 Bucks (for a 1-week contest) and 200, 100 and 50 T1 Bucks (for 2-week contests).  The participant with the single longest streak at the end of each contest period also wins 100 T1 Bucks (for a 1 week-long contest) and 200 T1 Bucks (for 2 week-long contests).   Each point earned playing the contest also accrues towards T1 Bucks.<a href="https://bleachr-public-static-content.s3.amazonaws.com/tennis-one/rules.html" target="_blank"> See full contest rules here</a> </p>
    </div>
    <div *ngIf="info !== undefined">
      <h2>Rules</h2>
      <p>{{ info }}</p>
    </div>
  </div>
</div> -->

<section class="content">
    <span class="alert-type" [ngSwitch]="payload.type === 'info'">
        <mat-icon *ngSwitchCase="true">info</mat-icon>
        <mat-icon *ngSwitchCase="false">error</mat-icon>
    </span>
    <div>
        <h3 *ngIf="payload.message.title">{{ payload.message.title }}</h3>
        <p>
            {{ payload.message.body }}
        </p>
    </div>
    <span *ngIf="payload.message.error"> {{ payload.message.error }} </span>
</section>
<section class="actions">
    <button mat-stroked-button (click)="closeSelf()">{{ payload.message.cancel || 'cancel'}}</button>
</section>
