import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { VideoOverlay } from '../_interfaces';

@Injectable({
    providedIn: 'root'
})
export class VideoOverlayService {
    constructor(private http: HttpClient) {}

    async getById(id: string): Promise<VideoOverlay> {
        return (await lastValueFrom(this.http.get<{ data: VideoOverlay }>(`${environment.BLEACHR_WEB_API}/video-overlay/by-id/${id}`)))
            ?.data;
    }
}
