import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpOptionsHeaders } from '../services/http-option-headers';
import { environment } from '../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { LoggedInFanModel } from '../_interfaces';

export interface CurrentUserModel {
    fingerprint: string;
    meta: any;
    token: string;
}

export interface BracketBattleStateModel {
    valid_fan: boolean;
    dismissed: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class FanService {
    public buildHeaders = new HttpOptionsHeaders();
    public fan: LoggedInFanModel;
    public bb_banner_state: BehaviorSubject<BracketBattleStateModel> = new BehaviorSubject<BracketBattleStateModel>({ valid_fan: false, dismissed: false });
    public bb_popup_state: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(private http: HttpClient) { }

    postBulkFanFollowTags(payload) {
        const headers = this.buildHeaders.buildRequestHeaders();

        return this.http.post(`${environment.FE_API_FOUR}/tags/bulk_follow`, payload, headers);
    }

    postBulkFanFollowPlayers(payload) {
        const headers = this.buildHeaders.buildRequestHeaders();

        return this.http.post(`${environment.FE_API_THREE}/tennis/players/bulk_follow_players`, payload, headers);
    }

    getFanPlayerFollows(): Promise<any> {
        const headers = this.buildHeaders.buildRequestHeaders();
        return this.http.get(`${environment.FE_API_THREE}/tennis/players/followed_players`, headers).toPromise()
            .then((data) => {
                return data['data'];
            })
            .catch((error) => {
                console.error(error);
                return [];
            });
    }

    getFollowedPlayerRecentMatch(): Promise<any> {
        const headers = this.buildHeaders.buildRequestHeaders();
        return this.http.get(`${environment.FE_API_FOUR}/tennis/players/recent_match?followed=true&simplified_draw_type=S`, headers).toPromise()
            .then((data) => {
                return data['data'];
            })
            .catch((error) => {
                console.error(error)
                return null;
            });
    }

    getFanProfile(): Promise<any> {
        const headers = this.buildHeaders.buildRequestHeaders();

        return this.http.get(`${environment.FE_API_FOUR}/profile`, headers).toPromise()
            .then((data) => {
                return data['data'];
            })
            .catch((error) => {
                console.error(error);
                return null;
            });
    }

    updateFanProfile(payload): Promise<any> {
        const headers = this.buildHeaders.buildRequestHeaders();
        return this.http.post(`${environment.FE_API_FOUR}/profile`, payload, headers).toPromise()
            .then((data) => {
                return data['data'];
            })
            .catch(error => console.error(error));
    }

    getFanById(id: string): Promise<any> {
        const headers = this.buildHeaders.buildRequestHeaders();

        return this.http.get(`${environment.FE_API_FOUR}/profile/${id}`, headers).toPromise()
            .then((data) => {
                return data['data'];
            })
            .catch((error) => {
                console.error(error);
                return null;
            });

    }

    getFanId() {
        return this.fan.id;
    }

    setFan(fan) {
        this.fan = fan;
    }

    getFan() {
        return this.fan;
    }

}
