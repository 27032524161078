import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-match-card-options',
    templateUrl: './match-card-options.component.html',
    styleUrls: ['./match-card-options.component.scss']
})
export class MatchCardOptionsComponent implements OnInit {
    @Input() match_details;
    @Output() close = new EventEmitter();
    @Output() viewMatchDetails = new EventEmitter();

    constructor(
        private router: Router
    ) { }

    ngOnInit() {

    }

    closeDrawer() {
        this.close.emit(`.match-options-drawer`);
    }

    navigateToPlayers() {
        this.router.navigate(['/players'], {queryParams: {my_players: true}});
    }

    openMatchDetails() {
        this.viewMatchDetails.emit(this.match_details);
    }
}
