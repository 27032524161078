import { Injectable } from '@angular/core';
import * as linkifyjs from 'linkifyjs';
import hashtag from 'linkifyjs/plugins/hashtag';
import mention from 'linkifyjs/plugins/mention';
import { Link } from '../homescreen-module/models/linkify';

@Injectable({
    providedIn: 'root'
})
export class LinkifyService {
    private social_routes = {
        Facebook: {
            'hashtag': 'https://facebook.com/hashtag/',
            'user': 'https://facebook.com/'
        },
        Twitter: {
            'hashtag': 'https://twitter.com/hashtag/',
            'user': 'https://twitter.com/hashtag/'
        },
        Instagram: {
            'hashtag': 'https://instagram.com/explore/tags/',
            'user': 'https://instagram.com/'
        }
    };

    constructor() { }

    // Find any links in a given text as a string
    parse(text: string, feed_type: string): string {
        hashtag(linkifyjs);
        mention(linkifyjs);
        const parsed_links: Array<Link> = linkifyjs.find(text);
        return this.linkify(parsed_links, text, feed_type);
    }

    private linkify(links: Array<Link>, desc: string, feed_type: string): string {

        links.forEach((link) => {
            if (link.type === 'hashtag') {
                desc = desc.replace(link.value,
                    '<a href="' + this.social_routes[feed_type].hashtag + link.value.substr(1) + '">' + link.value + '</a>');
            } else if (link.type === 'mention') {
                desc = desc.replace(link.value,
                    '<a href="' + this.social_routes[feed_type].user + link.value.substr(1) + '">' + link.value + '</a>');
            } else if (link.type === 'url') {
                desc = desc.replace(link.href,
                    '<a href="' + link.href + '">' + link.value + '</a>');
            }
        });
        return desc;

    }
}
