import { HttpHeaders } from '@angular/common/http';
import { environment } from '../environments/environment';

class Helpers {
    public remoteAPI = environment.timeline_api;
    public timelineAPI = environment.timeline_api;
    public gamingAPI = environment.gaming_api;
    public webAPI = environment.BLEACHR_WEB_API;
    private CONTENT_LEN_LIMIT = 45;

    buildLocaleRequestHeader(account_identifier: string, locale: string) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'X-Bleachr': account_identifier,
                'Accept-Language': locale
            })
        };
        return httpOptions;
    }

    buildBasicReqestHeader(account_identifier: string) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'X-Bleachr': `${account_identifier}`
            })
        };
        return httpOptions;
    }

    buildReferralHeader(token, identifier) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'x-Bleachr': `${identifier}`,
                Authorization: `Token token=${token}`,
            })
        }
        return httpOptions;
    }

    buildAnalyticHeaders(account_id: string, fingerprint: string) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'x-bleachr': account_id,
                'Bleachr-analytics': `Homescreen`,
                'fingerprint': fingerprint
            })
        };
        return httpOptions;
    }

    buildBasicAuthHeaders(token: string) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: `Token token=${token}`,
            })
        };
        return httpOptions;
    }

    buildLocaleAuthHeaders(token: string, locale: string) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Accept-Language': locale,
                Authorization: `Token token=${token}`
            })
        };
        return httpOptions;
    }

    buildAuthHeaders(token: string, identifier: string) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: `Token token=${token}`,
                'X-Bleachr': identifier
            })
        };
        return httpOptions;
    }

    clipContentString = (content: string): string => {
        if (content.length > this.CONTENT_LEN_LIMIT) {
            const lastSpace = content.lastIndexOf(' ', this.CONTENT_LEN_LIMIT - 1);
            return `${content.slice(0, lastSpace)}...`;
        } else {
            return content;
        }
    }

    ensureHTTPS(url: string): string {
        if (url === null || url === undefined) {
            return '';
        }
        const regx = /http:/g;
        return url.replace(regx, 'https:');
    }

    handleShadow(card_shadow: string) {
        return {
            'box-shadow': '0 2px 4px -1px ' + card_shadow + ', \
                0 4px 5px 0 ' + card_shadow + ', \
                0 1px 10px 0 ' + card_shadow
        };
    }
}

export const helpers = new Helpers();

export function normalizeCommonJSImport<T>(
    importPromise: Promise<T>,
): Promise<T> {
    // CommonJS's `module.exports` is wrapped as `default` in ESModule.
    return importPromise.then((m: any) => (m.default || m) as T);
}
export const twelve = (hours: number): number => {
    let h = hours;
    if (h >= 12) {
        h = hours - 12;
    }
    if (h === 0) {
        h = 12;
    }
    return h;
};

export function setOS(): string {
    const nAgt = navigator.userAgent;
    const clientStrings: Array<{ [key: string]: any }> = [
        { s: 'Windows 10', r: /(Windows 10.0|Windows NT 10.0)/ },
        { s: 'Windows 8.1', r: /(Windows 8.1|Windows NT 6.3)/ },
        { s: 'Windows 8', r: /(Windows 8|Windows NT 6.2)/ },
        { s: 'Windows 7', r: /(Windows 7|Windows NT 6.1)/ },
        { s: 'Windows Vista', r: /Windows NT 6.0/ },
        { s: 'Windows Server 2003', r: /Windows NT 5.2/ },
        { s: 'Windows XP', r: /(Windows NT 5.1|Windows XP)/ },
        { s: 'Windows 2000', r: /(Windows NT 5.0|Windows 2000)/ },
        { s: 'Windows ME', r: /(Win 9x 4.90|Windows ME)/ },
        { s: 'Windows 98', r: /(Windows 98|Win98)/ },
        { s: 'Windows 95', r: /(Windows 95|Win95|Windows_95)/ },
        { s: 'Windows NT 4.0', r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/ },
        { s: 'Windows CE', r: /Windows CE/ },
        { s: 'Windows 3.11', r: /Win16/ },
        { s: 'Android', r: /Android/ },
        { s: 'Open BSD', r: /OpenBSD/ },
        { s: 'Sun OS', r: /SunOS/ },
        { s: 'Linux', r: /(Linux|X11)/ },
        { s: 'iOS', r: /(iPhone|iPad|iPod)/ },
        { s: 'Mac OS X', r: /Mac OS X/ },
        { s: 'Mac OS', r: /(MacPPC|MacIntel|Mac_PowerPC|Macintosh)/ },
        { s: 'QNX', r: /QNX/ },
        { s: 'UNIX', r: /UNIX/ },
        { s: 'BeOS', r: /BeOS/ },
        { s: 'OS/2', r: /OS\/2/ },
        { s: 'Search Bot', r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/ }
    ];
    const search = clientStrings.find((val) => {
        if (val.r.test(nAgt)) {
            return true;
        }
    });
    if (search !== undefined) {
        return search.s;
    } else {
        return '-';
    }
}

export function broadcastLoadingComplete() {
    if ((window as any).webkit) {
        (window as any).webkit.messageHandlers.bleachrLoadingComplete.postMessage('done');
    }
}

export function hexToRGBA(hex: string) {
    // 3 digits
    if (hex.length === 4) {
        return {
            r: Number('0x' + hex[1] + hex[1]),
            g: Number('0x' + hex[2] + hex[2]),
            b: Number('0x' + hex[3] + hex[3])
        };
        // 6 digits
    } else if (hex.length === 7) {
        return {
            r: Number('0x' + hex[1] + hex[2]),
            g: Number('0x' + hex[3] + hex[4]),
            b: Number('0x' + hex[5] + hex[6])
        };
    } else {
        return null;
    }
}

export function isBlackOrWhite({ r, g, b }): string {
    const ratio = r * 0.299 + g * 0.587 + b * 0.114;
    return ratio > 186 ? '#000000' : '#ffffff';
}
