export const tournament_icons = {
    slam: 'https://res.cloudinary.com/bleachr/image/upload/v1639672082/TennisONE/Tournament%20Logos/Slam_2x.png',
    atp250: 'https://res.cloudinary.com/bleachr/image/upload/v1639672369/TennisONE/Tournament%20Logos/ATP_250_2x.png',
    atp500: 'https://res.cloudinary.com/bleachr/image/upload/v1639672315/TennisONE/Tournament%20Logos/ATP_500_2x.png',
    atp1000: 'https://res.cloudinary.com/bleachr/image/upload/v1639672115/TennisONE/Tournament%20Logos/ATP_1000_2x.png',
    wta125: 'https://res.cloudinary.com/bleachr/image/upload/v1639672432/TennisONE/Tournament%20Logos/WTA_125_2x.png',
    wta250: 'https://res.cloudinary.com/bleachr/image/upload/v1639672390/TennisONE/Tournament%20Logos/WTA_250_2x.png',
    wta500: 'https://res.cloudinary.com/bleachr/image/upload/v1639672349/TennisONE/Tournament%20Logos/WTA_500_2x.png',
    wta1000: 'https://res.cloudinary.com/bleachr/image/upload/v1639672141/TennisONE/Tournament%20Logos/WTA_1000_2x.png',
    both1000: 'https://res.cloudinary.com/bleachr/image/upload/v1639672169/TennisONE/Tournament%20Logos/Both_1000_2x.png',
    exo: 'https://res.cloudinary.com/bleachr/image/upload/v1639672465/TennisONE/Tournament%20Logos/Exo_2x.png',
    challenger: ''
};