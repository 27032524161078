<div id="no-doubles-container" *ngIf="match.team1.player2.id">
    <div class="players-container">
        <div id="team1-container">
            <div class="player1" style="position: relative">
                <div class="radius"  [ngClass]="match.team1.player2.id ? 'doubles d1-offset' : 'player-photo-frame'">
                    <app-image
                        style="width: 100%; height: 100%"
                        [player]="match.team1.player1"
                        [classes]="'photo bigger head-to-head-img med-player-headshot flex-center bigger-font'"
                    >
                    </app-image>
                </div>
                <span
                    style="width: 20px; height: 15px;"
                    class="flag-icon flag-icon-{{ match.team1.player1.country | countryName }} player-one"
                    [ngClass]="match.team1.player2.id ? 'double-flag d1-flag' : 'flag-icon'"
                ></span>
                <div *ngIf="match.team1.player2.id"  class="radius doubles d2-offset">
                    <app-image
                        style="width: 100%; height: 100%"
                        [player]="match.team1.player2"
                        [classes]="'photo bigger head-to-head-img med-player-headshot flex-center bigger-font'"
                    >
                    </app-image>
                </div>
                <span *ngIf="match.team1.player2.id" style="width: 20px; height: 15px;" 
                class="flag-icon-{{ match.team1.player2.country | countryName }} player-one double-flag d2-flag"></span>
                <div *ngIf="!match.team1.player2.id" class="name">
                    <span> {{ match.team1.player1.first_name }} </span>
                    <span> {{ match.team1.player1.last_name }} </span>
                </div>
                <div *ngIf="match.team1.player2.id" class="names">
                    <span> {{ match.team1.player1.first_name[0] }}. {{ match.team1.player1.last_name }} </span>
                    <span> {{ match.team1.player2.first_name[0] }}. {{ match.team1.player2.last_name }} </span>
                </div>
            </div>
        </div>
        <div class="vs-container">VS</div>
        <div id="team2-container">
            <div class="player1">
                <div class="radius" [ngClass]="match.team2.player2.id ? 'doubles d1-offset' : 'player-photo-frame'" >
                    <app-image
                        style="width: 100%; height: 100%"
                        [player]="match.team2.player1"
                        [classes]="'photo bigger med-player-headshot flex-center head-to-head-img bigger-font'"
                    >
                    </app-image>
                </div>
                <span
                    style="width: 20px; height: 15px;"
                    class="flag-icon flag-icon-{{ match.team2.player1.country | countryName }} player-one"
                    [ngClass]="match.team2.player2.id ? 'double-flag d1-flag' : 'flag-icon'"
                ></span>
                <div *ngIf="match.team2.player2.id"  class="radius doubles d2-offset">
                    <app-image
                        style="width: 100%; height: 100%"
                        [player]="match.team2.player2"
                        [classes]="'photo bigger med-player-headshot flex-center head-to-head-img bigger-font'"
                    >
                    </app-image>
                </div>
                <span *ngIf="match.team2.player2.id" style="width: 20px; height: 15px;" 
                class="flag-icon-{{ match.team2.player2.country | countryName }} player-one double-flag d2-flag"></span>
                <div *ngIf="!match.team2.player2.id" class="name">
                    <span> {{ match.team2.player1.first_name }} </span>
                    <span> {{ match.team2.player1.last_name }} </span>
                </div>
                <div *ngIf="match.team1.player2.id" class="names">
                    <span> {{ match.team2.player1.first_name[0] }}. {{ match.team2.player1.last_name }} </span>
                    <span> {{ match.team2.player2.first_name[0] }}. {{ match.team2.player2.last_name }} </span>
                </div>
            </div>
        </div>
    </div>
</div>

<div align="center" *ngIf="match.team1.player2.id">
    {{ appStringsService.getAppString('tennis.match.noDoubles') }}
</div>
