export interface FanTrophyModel {
    current_level_explainer: string;
    current_level_id: string;
    current_level_name: string;
    current_trophy_level_image_url: string;
    current_value: number;
    account_id: string;
    earnable: boolean;
    explainer: string;
    id: string;
    image_url: string;
    key: string;
    name: string;
    rate_limit_seconds: number;
}

export interface TrophyModel {
    account_id: string;
    earnable: boolean;
    explainer: string;
    id: string;
    image_url: string;
    key: string;
    name: string;
    rate_limit_seconds: number;
    current_level_explainer?: string;
    current_level_id?: string;
    current_level_name?: string;
    current_trophy_level_image_url?: string;
    current_value?: number;
    levels?: TrophyLevelModel[];
}

export interface TrophyLevelModel {
    explainer: string;
    id: string;
    image_url: string;
    locale: string;
    name: string;
    trophy_id: string;
    value_threshold: number;
}

export interface TrophyDetailsDisplayModel {
    idx: number;
    details: TrophyLevelModel;
}

export interface TrophyAchievementPayloadModel {
    key: string;
    value: string;
}
