import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class AspectRatioService {
    public aspect_ratios = [
        { label: '19.5:9', value: '19.5:9' },
        { label: '16:10', value: 'SixteenByTen' },
        { label: '16:9', value: 'SixteenByNine' },
        { label: '4:4', value: 'Square' },
        { label: '4:3', value: 'FourByThree' },
        { label: '3:1', value: 'ThreeByOne' },
        { label: '4:1', value: 'FourByOne' },
        { label: '5:1', value: 'FiveByOne' },
        { label: '5:3', value: 'FiveByThree' },
        { label: '7:3', value: 'SevenByThree' },
        { label: '6:1', value: 'SixByOne' },
        { label: '7:1', value: 'SevenByOne' },
        { label: '8:1', value: 'EightByOne' },
        { label: '9:1', value: 'NineByOne' },
        { label: '10:1', value: 'TenByOne' }
    ];

    public ratios = {
        '39:18': { ratio: '39:18', class: 'iphoneXMax' },
        'SixteenByTen': { ratio: '16:10', class: 'SixteenByTen' },
        'SixteenByNine': { ratio: '16:9', class: 'SixteenByNine' },
        'Square': { ratio: '4:4', class: 'Square' },
        'FourByThree': { ratio: '4:3', class: 'FourByThree' },
        'ThreeByOne': { ratio: '3:1', class: 'ThreeByOne' },
        'FourByOne': { ratio: '4:1', class: 'FourByOne' },
        'FiveByOne': { ratio: '5:1', class: 'FiveByOne' },
        'FiveByThree': { ratio: '5:3', class: 'FiveByThree' },
        'SevenByThree': { ratio: '7:3', class: 'SevenByThree' },
        'SixByOne': { ratio: '6:1', class: 'SixByOne' },
        'SevenByOne': { ratio: '7:1', class: 'SevenByOne' },
        'EightByOne': { ratio: '8:1', class: 'EightByOne' },
        'NineByOne': { ratio: '9:1', class: 'NineByOne' },
        'TenByOne': { ratio: '10:1', class: 'TenByOne' }
    };

    constructor() { }

    async setAspectRatio(card) {
        const ratio = await this.handleRatio(card.context.aspect_ratio);
        return `${ratio}%`;
    }

    async handleRatioStr(ratio_str: string) {
        const ratio = await this.handleRatio(ratio_str);
        return `${ratio}%`;
    }

    handleRatio(ratio: string) {
        if (!ratio) {
            const kicker = (9 / 1) * 100;
            return `${kicker}%`;
        }

        if (ratio.includes(':')) {
            const maths = ((Number(ratio.split(':')[1])) / (Number(ratio.split(':')[0]))) * 100;
            return maths;
        } else if (this.ratios[ratio]) {
            const ascend = this.ratios[ratio].ratio.split(':')[1];
            const descend = this.ratios[ratio].ratio.split(':')[0];
            const maths = ((Number(ascend)) / (Number(descend))) * 100;
            return maths;
        }
    }

    handleRatioClass(ratio: string): string {
        if (!ratio) {
            return;
        }

        return this.ratios[ratio].class;
    }
}
