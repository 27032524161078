import { Component, Input, OnInit } from '@angular/core';
import { MatchScoreUpdate } from 'src/app/_interfaces';
import { LiveScoreSocketService } from 'src/app/_services';
import { handleStatusColor, handleTitleColor } from '../../services/helpers';
import { MatchDataModel } from './tennis-match-card-model';

@Component({
    selector: 'app-match-live-scores',
    templateUrl: './match-live-scores.component.html',
    styleUrls: ['./match-live-scores.component.scss']
})
export class MatchLiveScoresComponent implements OnInit {
    @Input() match_team_id: string;
    @Input() match_data: MatchDataModel;
    @Input() show_options: boolean = false;
    public handleTitleColor = handleTitleColor;
    public handleStatusColor = handleStatusColor;
    public today = new Date().toISOString();

    constructor(
        private liveScoreSocket: LiveScoreSocketService
    ) { }

    async ngOnInit() {
        await this.liveScoreSocket.connect(this.match_team_id);
        await this.liveScoreSocket.joinChannel(this.match_team_id, this.match_data.id).then((data) => {
            this.match_data.team1.points = data.team1.points;
            this.match_data.team1.sets = data.team1.sets;
            this.match_data.team2.points = data.team2.points;
            this.match_data.team2.sets = data.team2.sets;
        });
        this.liveScoreSocket.match_channel.matchUpdates(this.match_data.id).subscribe((update) => {
            this.handlePointChange(update);
            this.handleSetChange(update);
            this.handleStateChange(update);
        });
    }

    handleStateChange(update: MatchScoreUpdate) {
        const { status } = this.match_data;

        if (status !== update.status) {
            this.match_data.status = update.status;
        }
    }

    handleSetChange(update: MatchScoreUpdate) {
        const { team1, team2 } = this.match_data;

        team1.sets = update.team1.sets;
        team2.sets = update.team2.sets;

        team1.is_serving = update.team1.is_serving;
        team2.is_serving = update.team2.is_serving;
    }

    handlePointChange(update: MatchScoreUpdate) {
        const { team1, team2 } = this.match_data;

        if (team1.points !== update.team1.points) {
            team1.live_update = true;
            team1.points = update.team1.points;

            setTimeout(() => {
                team1.live_update = false;
            }, 750)
        }

        if (team2.points !== update.team2.points) {
            team2.live_update = true;
            team2.points = update.team2.points;

            setTimeout(() => {
                team2.live_update = false;
            }, 750)
        }
    }
}
