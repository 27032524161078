// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---dev` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    local_api: 'https://bleachr-webclient-dev.herokuapp.com',
    ANALYTIC_ENDPOINT: 'https://bleachr-reporting-dev.herokuapp.com/events',
    timeline_api: 'https://fan-engine-dev.herokuapp.com',
    env: 'dev',
    gaming_api: 'https://bleachr-gaming-dev.herokuapp.com',
    NARCISSUS_URL: 'https://bleachr-narcissus.herokuapp.com',
    socket_host: 'wss://fan-engine-dev.herokuapp.com',
    cloudinary_config: {
        cloud_name: 'https://api.cloudinary.com/v1_1/bleachr/image/upload',
        upload_preset: 'g8tv33gn'
    },
    BLEACHR_WEB_API: 'https://bleachr-web-api-dev.herokuapp.com/api',
    BLEACHR_CHAT_SOCKET: 'wss://bleachr-chat-dev.herokuapp.com',
    FE_API_FOUR: 'https://fan-engine-dev.herokuapp.com/api/v4',
    FE_API_THREE: 'https://fan-engine-dev.herokuapp.com/api/v3',
    ROUND_BY_ROUND: 'e71d8135-85a5-4133-a074-9264828c4a59',
    id: '0b0dff02-9336-411a-9318-4a1ae6d69bd8',
    identifier: 'tennis-one',
    webclient_api: 'https://bleachr-webclient-dev.herokuapp.com',
    BLEACHR_WEB_API_SOCKET_HOST: 'https://bleachr-web-api-dev.herokuapp.com',
    SSR_WEBCLIENT_URL: 'https://bleachr-webclient-ssr-dev.herokuapp.com',
    CL_WEB: 'https://watch.crowdlive.appcats.com/4faea0c9-49ad-4d4d-bee6-1e16605b93b2?random_guest_creation=true&identifier=bleachr'
};

/*
 * In development mode, for easier debugging, you can ignore zone related error
 * stack frames such as `zone.run`/`zoneDelegate.invokeTask` by importing the
 * below file. Don't forget to comment it out in production mode
 * because it will have a performance impact when errors are thrown
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
