export interface DailyPicksModel {
    BracketEntry_id: string;
    BracketEntry_match_id: string;
    BracketEntry_tournament_id: string;
    BracketEntry_team_id: string;
    BracketEntry_guess: string;
    BracketEntry_fan_id: string;
    BracketEntry_correct: boolean;
    BracketEntry_resolved_at: string;
    BracketEntry_created_at: string;
    BracketEntry_updated_at: string;
}
