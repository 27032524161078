import { Component, OnInit, Input, AfterContentInit } from '@angular/core';
import { helpers } from '../../helpers';
import { FontService } from '../../services/font-service.service';
declare var Siema: any;

@Component({
    selector: 'app-homescreen-fan-stream-component',
    templateUrl: './homescreen-fan-stream-component.component.html',
    styleUrls: ['./homescreen-fan-stream-component.component.scss']
})
export class HomescreenFanStreamComponentComponent implements AfterContentInit {
    @Input() fan_stream;


    public clipContentString = helpers.clipContentString;
    public ensureHTTPS = helpers.ensureHTTPS;
    public handleShadow = helpers.handleShadow;

    public handleFont: Function;
    constructor(font: FontService) {
        this.handleFont = font.handleFont;
    }

    ngAfterContentInit() {
        // Angular being awesome
        window.setTimeout(this.initSlider, 0, this.fan_stream.position);
    }

    initSlider(id: number) {
        const siema = new Siema({
            selector: `.siema-${id}`,
            perPage: 1.2
        });
    }

    checkURL(url) {
        if (url === null) {
            return 'https://res.cloudinary.com/bleachr/image/upload/v1517500393/bleachr_admin/emptyAvatar.png';
        }
        return this.ensureHTTPS(url);
    }

    buildURL(id: number) {
        return `${window.location.href}/fan_stream?id=${id}`;
    }
}
