import { Component, Input } from '@angular/core';
import { FontService } from '../../services/font-service.service';
@Component({
    selector: 'app-homescreen-trivia-card',
    templateUrl: './homescreen-trivia-card.component.html',
    styleUrls: ['./homescreen-trivia-card.component.scss']
})
export class HomescreenTriviaCardComponent {
    @Input() trivia;
    public handleFont: Function;
    constructor(font: FontService) {
        this.handleFont = font.handleFont;
    }

    buildURL() {
        const fake_url = `${window.location.href}/trivia}`;

        return fake_url;
    }
}
