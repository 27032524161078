import { Pipe, PipeTransform } from '@angular/core';
import { AppStringsService } from 'src/app/_services';
import { APP_STRING_KEYS } from 'src/app/_constants';

@Pipe({
    name: 'matchStatusTitle'
})
export class MatchStatusTitlePipe implements PipeTransform {
    public app_string_keys = APP_STRING_KEYS;

    constructor(
        public appStrings: AppStringsService
    ) {}

    transform(value: string, finished_recently = null): string {
        switch (value) {
            case 'playing':
                return this.appStrings.getAppString(this.app_string_keys.TOGGLE_LIVE).toUpperCase();
            case 'finished':
                return this.appStrings.getAppString(this.app_string_keys.FINAL).toUpperCase();
            case 'finished_recently':
                if (finished_recently !== null) {
                    return finished_recently;
                } else {
                    return this.appStrings.getAppString(this.app_string_keys.FINAL).toUpperCase();
                }
            case 'on_court':
                return this.appStrings.getAppString(this.app_string_keys.ON_COURT).toUpperCase();
            case 'finished_retired':
                return this.appStrings.getAppString(this.app_string_keys.RETIREMENT).toUpperCase();
            case 'finished_walkover':
                return this.appStrings.getAppString(this.app_string_keys.WALKOVER).toUpperCase();
            case null:
                return '';
            case 'warmup':
            case 'suspended':
            default:
                return value.toUpperCase();
        }
    }
}
