import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ApiService } from '../../scores-module/api.service';
import { handleStatusColor, handleTitleColor } from '../../services/helpers';

export interface PlayerVsPlayerInputModel {
    team1: Array<string>;
    team2: Array<string>;
}

@Component({
    selector: 'app-player-vs-player',
    templateUrl: './player-vs-player.component.html',
    styleUrls: ['./player-vs-player.component.scss']
})
export class PlayerVsPlayerComponent implements OnInit {
    @Output() closed = new EventEmitter();
    @Input() player_ids: PlayerVsPlayerInputModel;

    public handleStatusColor = handleStatusColor;
    public handleTitleColor = handleTitleColor;

    public busy = true;
    public team1;
    public team2;

    constructor(private api: ApiService) { }

    ngOnInit() {
        this.buildView();
    }

    async buildView() {
        if (this.player_ids && Array.isArray(this.player_ids.team1) && Array.isArray(this.player_ids.team2)) {
            this.team1 = await this.buildPlayerDetails(this.player_ids.team1);
            this.team2 = await this.buildPlayerDetails(this.player_ids.team2);
            this.busy = false;
        }
    }

    async buildPlayerDetails(ids: Array<string>) {
        const player1 = ids[0] ? await this.getPlayerDetails(ids[0]) : {};
        const player2 = ids[1] ? await this.getPlayerDetails(ids[1]) : {};

        return { player1, player2 };
    }

    getPlayerDetails(player_id: string) {
        return new Promise((completed, rejected) => {
            this.api.getPlayerDetails(player_id).subscribe(
                (data) => {
                    completed(data['data']);
                },
                (error) => {
                    rejected({});
                    console.error(error);
                }
            );
        });
    }

    toggleDrawer() {
        this.closed.emit();
    }
}
