import { Component, OnInit, Input } from '@angular/core';
import { PlayerDetails } from 'src/app/_interfaces';
import { helpers } from '../../helpers';
import { FontService } from '../../services/font-service.service';

@Component({
    selector: 'app-homescreen-tennis-match-card',
    templateUrl: './homescreen-tennis-match-card.component.html',
    styleUrls: ['./homescreen-tennis-match-card.component.scss']
})
export class HomescreenTennisMatchCardComponent implements OnInit {

    public player_1: PlayerDetails;
    public player_1_partner: PlayerDetails;
    public player_2: PlayerDetails;
    public player_2_partner: PlayerDetails;
    public placeholder: boolean;
    public ensureHTTPS: Function;
    public handleFont: Function;


    @Input() match_details;

    constructor(font: FontService) {
        this.ensureHTTPS = helpers.ensureHTTPS;
        this.handleFont = font.handleFont;
        this.placeholder = false;
    }

    ngOnInit() {
        if (this.match_details.data.length <= 0) {
            this.placeholder = true;
            return;
        }
        this.buildPlayers();
    }

    buildPlayers() {
        if (this.match_details.data[0].team1.player1) {
            this.player_1.display_name =
                `${this.match_details.data[0].team1.player1.first_name} ${this.match_details.data[0].team1.player1.last_name}`;

            this.player_1.photo = this.ensureHTTPS(this.match_details.data[0].team1.player1.photo);
            this.player_1.rank = this.match_details.data[0].team1.player1.rank;
            this.player_1.score = this.match_details.data[0].team1.sets;
        }
        if (this.match_details.data[0].team1.player2 && Object.keys(this.match_details.data[0].team1.player2).length > 1) {
            this.player_1_partner.display_name =
                `${this.match_details.data[0].team1.player2.first_name} ${this.match_details.data[0].team1.player2.last_name}`;

            this.player_1_partner.photo = this.ensureHTTPS(this.match_details.data[0].team1.player2.photo);
            this.player_1_partner.rank = this.match_details.data[0].team1.player2.rank;
            this.player_1_partner.score = this.match_details.data[0].team1.sets;
        }
        if (this.match_details.data[0].team2.player1) {
            this.player_2.display_name =
                `${this.match_details.data[0].team2.player1.first_name} ${this.match_details.data[0].team2.player1.last_name}`;

            this.player_2.photo = this.ensureHTTPS(this.match_details.data[0].team2.player1.photo);
            this.player_2.rank = this.match_details.data[0].team2.player1.rank;
            this.player_2.score = this.match_details.data[0].team2.sets;
        }
        if (this.match_details.data[0].team2.player2 && Object.keys(this.match_details.data[0].team2.player2).length > 0) {
            this.player_2_partner.display_name =
                `${this.match_details.data[0].team2.player1.first_name} ${this.match_details.data[0].team2.player2.last_name}`;

            this.player_2_partner.photo = this.ensureHTTPS(this.match_details.data[0].team2.player2.photo);
            this.player_2_partner.rank = this.match_details.data[0].team2.player2.rank;
            this.player_2_partner.score = this.match_details.data[0].team2.sets;
        }
    }

    buildURL() {

        const fake_url = `${window.location.href}/tennis_match/${this.match_details.data[0].id}`;

        return fake_url;
    }

    validSelector(prop: string): string {
        if (prop === '19.5:9') {
            return 'NineteenFiveByNine';
        } else {
            return prop;
        }
    }
}
