import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { TrophyDetailsDisplayModel, TrophyModel } from 'src/app/_interfaces';

@Component({
    selector: 'app-trophy-detail',
    templateUrl: './trophy-detail.component.html',
    styleUrls: ['./trophy-detail.component.scss']
})
export class TrophyDetailComponent implements OnInit, AfterViewInit {
    @Input() trophy: TrophyModel;
    @Input() show_img: boolean;
    @Output() closed = new EventEmitter();

    public progress_percentage: string;
    public achieved_level: TrophyDetailsDisplayModel = {
        idx: null,
        details: null
    };

    public next_level: TrophyDetailsDisplayModel = {
        idx: null,
        details: null
    };

    public scores = {
        left: 0,
        right: 0
    };

    constructor() { }

    async ngOnInit() {
        this.buildView();
    }

    ngAfterViewInit() {
        setTimeout(() => {
            document.getElementById('trophy-container').style.opacity = '1';
        }, 50);
    }

    buildView() {
        this.trophy.levels.sort((a, b) => a.value_threshold - b.value_threshold);

        this.setCurrentLevel();
        this.setNextLevel();

        this.setScore();
    }

    setCurrentLevel() {
        if (this.trophy.current_level_id) {
            const idx = this.trophy.levels.findIndex(level => this.trophy.current_level_id === level.id);

            if (idx !== -1) {
                this.achieved_level.idx = idx;
                this.achieved_level.details = this.trophy.levels[idx];
            }
        }
    }

    setNextLevel() {
        const achieved_idx = this.achieved_level.idx;
        const all_levels = this.trophy.levels;

        if (achieved_idx !== null && all_levels[achieved_idx + 1]) {
            this.next_level.details = all_levels[achieved_idx + 1];
            this.next_level.idx = achieved_idx + 1;

        } else if (all_levels.length > 0 && achieved_idx === null) {
            // No current level so next level is first
            this.next_level.details = all_levels[0];
            this.next_level.idx = 0;
        }
    }

    closeModal() {
        this.closed.emit(this.trophy);
    }

    setScore() {
        const next_level = this.next_level.details;
        const achieved_level = this.achieved_level.details;
        let left = 0;

        if (next_level === null && this.trophy.current_value && this.trophy.current_value > achieved_level.value_threshold) {
            left = +achieved_level.value_threshold;
        } else if (achieved_level || (!achieved_level && this.trophy.current_value)) {
            left = +this.trophy.current_value;
        }

        const right = next_level !== null ? +next_level.value_threshold : left;

        this.scores.left = left;
        this.scores.right = right;
        this.progress_percentage = `${left / right * 100}%`;
    }

    setExplainer() {
        const next_level = this.next_level.details;
        const achieved_level = this.achieved_level.details;

        if (next_level) {
            return next_level.explainer;
        }
        if (achieved_level && !next_level) {
            return achieved_level.explainer;
        }
        return this.trophy.explainer;
    }

}
