import { Component, OnInit, Input } from '@angular/core';

interface GuessModel {
    guess: string;
    odds: number;
}
@Component({
    selector: 'app-pick-percentage',
    templateUrl: './pick-percentage.component.html',
    styleUrls: ['./pick-percentage.component.scss']
})
export class PickPercentageComponent implements OnInit {
    @Input() odds: GuessModel[] = [];
    public display = { team1: '0%', team2: '0%' };
    constructor() {}

    ngOnInit() {
        this.display = this.objectifyOdds(this.odds);
    }

    objectifyOdds(arr: GuessModel[]): { team1: string; team2: string } {
        return arr.reduce((acc, odd) => {
            acc[odd.guess] = `${odd.odds}%`;
            return acc;
        }, this.display);
    }
}
