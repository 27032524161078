import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppStringComponent } from './app-string.component';



@NgModule({
    declarations: [
        AppStringComponent
    ],
    imports: [
        CommonModule
    ],
    exports: [
        AppStringComponent
    ]
})
export class AppStringModule { }
