import { Component, OnInit, Input } from '@angular/core';
import { AnalyticService } from '../../services/AnalyticService';
import { format, parseISO } from 'date-fns';
import { tournament_icons } from './tournament-type-icons';

@Component({
    selector: 'app-scores-tourney-card',
    templateUrl: './scores-tourney-card.component.html',
    styleUrls: ['./scores-tourney-card.component.scss']
})
export class ScoresTourneyCardComponent implements OnInit {
    @Input() tournament;
    public square: boolean;
    public elite = false;
    public tour_icons = tournament_icons;

    constructor(private analytics: AnalyticService) {}

    ngOnInit(): void {
        if (this.tournament.hasOwnProperty('timeframe')) {
            this.square = this.tournament.timeframe !== 'live';
        } else {
            this.square = false;
        }
        this.elite = this.tournament.partnership_level === 'elite';
    }

    formatDateForDisplay(date: string): string {
        const dmy = date.split('T')[0];
        return format(parseISO(dmy), 'M/d/yy');
    }

    handleNullImage(image_url: string): string {
        if (image_url === null) {
            return '';
        }
        return `url(${image_url.replace('ar_16:9', 'ar_23:5')})`;
    }

    handleTourneySelection() {
        const { team_id, partnership_level, name: tournament_name } = this.tournament;

        this.analytics.handleTourneySelection({ team_id, tournament_name, partnership_level });
    }

    tourNameAlign(tournament) {
        if (tournament.timeframe === 'live') {
            return {'align-items': 'start', 'padding-left': '10px'};
        }
        else {
            return {'align-items': 'center'};
        }
    }

    liveTourAlign(tournament) {
        if (tournament.timeframe === 'live') {
            return {'text-align': 'left'};
        }
        else {
            return {'text-align': 'center'};
        }
    }
}
