import { state, style, animate, transition, query, stagger } from '@angular/animations';


export function fadeIn(selector: string, duration = '400ms ease-out') {
  return [
    transition('* => *', [
      query(selector, [
        style({ opacity: 0}),
        stagger('1000ms', [
          animate(duration, style({
            opacity: 1
          }))
        ])
      ], {optional: true })
    ])
  ];
}

// export function fadeIn(selector: string, duration = '400ms ease-out') {
//   return [
//     transition('* => *', [
//       query(selector, [
//         style({ opacity: 0, transform: 'translateY(-5px)'}),
//         stagger('1000ms', [
//           animate(duration, style({
//             opacity: 1,
//             transform: 'translateX(0px)'
//           }))
//         ])
//       ], {optional: true })
//     ])
//   ];
// }
export function fadeOut(selector = ':leave', duration = 300) {
  return [
    transition('* => *', [
      query(selector, [
        style({ opacity: 1 }),
        stagger('1000ms', [
          animate(duration, style({
            opacity: 0
          }))
        ])
      ], {optional: true })
    ])
  ];
}
