import { Component, OnInit, Input } from '@angular/core';
import { AnalyticService } from 'src/app/services/AnalyticService';
import { SponsorService } from 'src/app/services/sponsor.service';
import { SponsorModel } from 'src/app/_interfaces';

@Component({
    selector: 'app-sponsor-banner',
    templateUrl: './sponsor-banner.component.html',
    styleUrls: ['./sponsor-banner.component.scss']
})
export class SponsorBannerComponent implements OnInit {
    @Input() sponsor_key: string;
    @Input() aspect_ratio: string;
    @Input() style: string;
    @Input() class: string;

    public aspect_ratio_payload: { context: { aspect_ratio: string } };
    public sponsor: SponsorModel;

    constructor(public analytics: AnalyticService, private sponsorAPI: SponsorService) { }

    ngOnInit(): void {
        this.sponsor = this.sponsorAPI.getPageSponsor(this.sponsor_key);
        this.aspect_ratio_payload = {
            context: {
                aspect_ratio: this.aspect_ratio
            }
        };
    }

    setSponsor = () => this.sponsor = this.sponsorAPI.getPageSponsor(this.sponsor_key);
}
