import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'countryName',
    pure: true
})
export class CountrynamePipe implements PipeTransform {
    transform(value: string): string {
        if (value === null || value === undefined) {
            return '';
        }
        return value.toLocaleLowerCase();
    }
}
