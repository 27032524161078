<div id="loading-container" *ngIf="busy">
    <mat-spinner diameter="75" strokeWidth="3"></mat-spinner>
</div>

<div class="pvp-wrapper">
    <button #backElement id="back-button" mat-button (click)="toggleDrawer()">
        <mat-icon>arrow_back</mat-icon>
    </button>
    <div id="top" *ngIf="!busy">
        <div id="team1-container">
            <div class="player1" style="position: relative;">
                <div class="radius" [ngClass]="team1.player2.id ? 'doubles d1-offset' : 'player-photo-frame'">
                    <app-image style="width: 100%; height: 100%;" [player]="team1.player1" [classes]="'photo bigger head-to-head-img'"></app-image>
                </div>
                <span
                    class="flag-icon flag-icon-{{team1.player1.country | countryName }} player-one"
                    [ngClass]="team1.player2.id ? 'double-flag d1-flag' : 'flag-icon'"
                ></span>
                <div *ngIf="team1.player2.id" class="radius doubles d2-offset">
                    <app-image style="width: 100%; height: 100%;" [player]="team1.player2" [classes]="'photo bigger head-to-head-img'"></app-image>
                </div>
                <span *ngIf="team1.player2.id" class="flag-icon-{{ team1.player2.country | countryName }} player-one double-flag d2-flag"></span>
                <div *ngIf="!team1.player2.id" class="name">
                    <span> {{ team1.player1.first_name }} </span>
                    <span> {{ team1.player1.last_name }} </span>
                </div>
                <div *ngIf="team1.player2.id" class="names">
                    <span> {{ team1.player1.first_name[0] }}. {{ team1.player1.last_name }} </span>
                    <span> {{ team1.player2.first_name[0] }}. {{ team1.player2.last_name }} </span>
                </div>
                <div class="rank" *ngIf="team1.player1.singles_rank && !team1.player2.id">
                    <div>Singles Rank: {{ team1.player1.singles_rank || '-' }}</div>
                </div>
                <div class="ranks" *ngIf="team1.player1.doubles_rank && team1.player2.doubles_rank">
                   <div>Doubles Rank: {{ team1.player1.doubles_rank || '-' }}</div> 
                   <div>Doubles Rank: {{ team1.player2.doubles_rank || '-' }}</div> 
                </div>
            </div>
        </div>
        <div class="vs-container">
            VS
        </div>
        <div id="team2-container">
            <div class="player1">
                <div class="radius" [ngClass]="team2.player2.id ? 'doubles d1-offset' : 'player-photo-frame'">
                    <app-image style="width: 100%; height: 100%;" [player]="team2.player1" [classes]="'photo bigger head-to-head-img'"></app-image>
                </div>
                <span
                    class="flag-icon flag-icon-{{ team2.player1.country | countryName }} player-one"
                    [ngClass]="team2.player2.id ? 'double-flag d1-flag' : 'flag-icon'"
                ></span>
                <div *ngIf="team2.player2.id" class="radius doubles d2-offset">
                    <app-image style="width: 100%; height: 100%;" [player]="team2.player2" [classes]="'photo bigger head-to-head-img'"></app-image>
                </div>
                <span *ngIf="team2.player2.id" class="flag-icon-{{ team2.player2.country | countryName }} player-one double-flag d2-flag"></span>
                <div *ngIf="!team2.player2.id" class="name">
                    <span> {{ team2.player1.first_name }} </span>
                    <span> {{ team2.player1.last_name }} </span>
                </div>
                <div *ngIf="team1.player2.id" class="names">
                    <span> {{ team2.player1.first_name[0] }}. {{ team2.player1.last_name }} </span>
                    <span> {{ team2.player2.first_name[0] }}. {{ team2.player2.last_name }} </span>
                </div>
                <div class="rank" *ngIf="team2.player1.id && !team2.player2.id">
                    <div>Singles Rank: {{ team2.player1.singles_rank || '-' }}</div>
                </div>
                <div class="ranks" *ngIf="team2.player1.id && team2.player2.id">
                   <div>Doubles Rank: {{ team2.player1.doubles_rank || '-' }}</div> 
                   <div>Doubles Rank: {{ team2.player2.doubles_rank || '-' }}</div> 
                </div>
            </div>
        </div>
    </div>

    <div class="player-details" *ngIf="!busy && team1?.player1.id && team2?.player1.id">
        <h3 style="background-color: #ffffff; text-align: center; text-transform: uppercase; font-weight: 500; margin: 0; padding: 1em 0em;">
            Player Details
        </h3>
        <div class="row">
            <div class="header">AGE</div>
            <div class="info">
                <div class="left">
                    <div>{{ team1.player1.details?.age || '-' }}</div>
                    <div *ngIf="team1.player2.id">{{ team1.player2.details?.age || '-' }}</div>
                </div>
                <div class="right">
                    <div>{{ team2.player1.details?.age || '-' }}</div>
                    <div *ngIf="team2.player2.id">{{ team2.player2.details?.age || '-' }}</div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="header">HEIGHT</div>
            <div class="info">
                <div class="left">
                    <div>{{ team1.player1.details?.height?.imperial || '-' }}</div>
                    <div *ngIf="team1.player2.id">{{ team1.player2.details?.height?.imperial || '-' }}</div>
                </div>
                <div class="right">
                    <div>{{ team2.player1.details.age || '-' }}</div>
                    <div *ngIf="team2.player2.id">{{ team2.player2.details.height?.imperial || '-' }}</div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="header">RESIDENCE</div>
            <div class="info">
                <div class="left">
                    <div>{{ team1.player1.details?.residence || '-' }}</div>
                    <div *ngIf="team1.player2.id">{{ team1.player2.details?.residence || '-' }}</div>
                </div>
                <div class="right">
                    <div>{{ team2.player1.details.residence || '-' }}</div>
                    <div *ngIf="team2.player2.id">{{ team2.player2.details?.residence || '-' }}</div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="header">PLAYS</div>
            <div class="info">
                <div class="left">
                    <div>{{ team1.player1.details?.play_hand || '-' }}</div>
                    <div *ngIf="team1.player2.id">{{ team1.player2.details?.play_hand || '-' }}</div>
                </div>
                <div class="right">
                    <div>{{ team2.player1.details.play_hand || '-' }}</div>
                    <div *ngIf="team2.player2.id">{{ team2.player2.details?.play_hand || '-' }}</div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="header">TURNED PRO</div>
            <div class="info">
                <div class="left">
                    <div>{{ team1.player1.details?.pro_year || '-' }}</div>
                    <div *ngIf="team1.player2.id">{{ team1.player2.details?.pro_year || '-' }}</div>
                </div>
                <div class="right">
                    <div>{{ team2.player1.details.pro_year || '-' }}</div>
                    <div *ngIf="team2.player2.id">{{ team2.player2.details?.pro_year || '-' }}</div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="header">YTD W/L</div>
            <div class="info">
                <div class="left">
                    <div>{{ team1.player1.stats?.ytd_winlosses || '-' }}</div>
                    <div *ngIf="team1.player2.id">{{ team1.player2.stats?.ytd_winlosses || '-' }}</div>
                </div>
                <div class="right">
                    <div>{{ team2.player1.stats?.ytd_winlosses || '-' }}</div>
                    <div *ngIf="team2.player2.id">{{ team2.player2.stats?.ytd_winlosses || '-' }}</div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="header">YTD TITLES</div>
            <div class="info">
                <div class="left">
                    <div>{{ team1.player1.stats?.ytd_titles || '-' }}</div>
                    <div *ngIf="team1.player2.id">{{ team1.player2.stats?.ytd_titles || '-' }}</div>
                </div>
                <div class="right">
                    <div>{{ team2.player1.stats?.ytd_titles || '-' }}</div>
                    <div *ngIf="team2.player2.id">{{ team2.player2.stats?.ytd_titles || '-' }}</div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="header">CAREER TITLES</div>
            <div class="info">
                <div class="left">
                    <div>{{ team1.player1.stats?.career_titles || '-' }}</div>
                    <div *ngIf="team1.player2.id">{{ team1.player2.stats?.career_titles || '-' }}</div>
                </div>
                <div class="right">
                    <div>{{ team2.player1.stats?.career_titles || '-' }}</div>
                    <div *ngIf="team2.player2.id">{{ team2.player2.stats?.career_titles || '-' }}</div>
                </div>
            </div>
        </div>
    
        <div class="row">
            <div class="header">CAREER PRIZE</div>
            <div class="info">
                <div class="left">
                    <div>{{ team1.player1.stats?.career_prize_money || '-' }}</div>
                    <div *ngIf="team1.player2.id">{{ team1.player2.stats?.career_prize_money || '-' }}</div>
                </div>
                <div class="right">
                    <div>{{ team2.player1.stats?.career_prize_money || '-' }}</div>
                    <div *ngIf="team2.player2.id">{{ team2.player2.stats?.career_prize_money || '-' }}</div>
                </div>
            </div>
        </div>
    </div>
</div>
