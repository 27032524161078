export interface ClientTournamentAsset {
    id?: string;
    client_tournament_year_id?: string;
    t1_asset?: boolean;
    acknowledged?: boolean;
    submitted?: boolean;
    type: string;
    asset: { [key: string]: string };
    label: string;
    hidden?: boolean;
}

export interface ClientTournament {
    id?: string;
    password: string;
    url_key: string;
    name: string; 
}

export enum ASSET_COLLECTION_TYPES {
    NOTE = 'Note',
    SPONSOR = 'Sponsor',
    ASSET = 'Asset'
}