import { Component, OnInit, EventEmitter, Output, Input, OnChanges, SimpleChanges, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NewFilterModel, ToggleDisplayModel } from 'src/app/_interfaces';

@Component({
    selector: 'app-toggle-filter',
    templateUrl: './toggle-filter.component.html',
    styleUrls: ['./toggle-filter.component.scss']
})
export class ToggleFilterComponent implements OnInit, OnChanges, AfterViewInit {
    @Input() toggle: ToggleDisplayModel;
    @Input() disable: boolean;
    @Input() theme: string;
    @Output() applyFilter = new EventEmitter();

    public active_toggle: string;
    public span_widths: Array<number> = [];
    public toggle_values: Array<string> = [];
    public inactive: boolean;

    constructor(private route: ActivatedRoute, private router: Router) {
        this.inactive = false;
    }

    ngOnInit() {
        this.active_toggle = this.toggle.filters[0].value;
        this.toggle.filters.forEach(filter => {
            this.toggle_values.push(filter.value);
        });
    }

    ngAfterViewInit() {
        this.initToggle();
        this.toggle.filters.forEach(filter => {
            const element = document.getElementById(`${this.toggle.id}-${filter.value}`);
            const elementWidth = element.offsetWidth;
            this.span_widths.push(elementWidth);
        });
        if (this.route.snapshot.queryParamMap.get('my_players')) {
            this.handleToggle('my_players');
            this.toggleAllPlayers('my_players');
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['disable']) {
            if (this.disable && this.toggle_values.length > 0) {
                this.handleToggle(this.toggle_values[0], false);
            }

            this.inactive = !!this.disable;
        }
    }

    initToggle() {
        if (this.active_toggle === 'view_all_players') {
            this.setActiveLine();
            return;
        }
        const element = document.getElementById(`${this.toggle.id}-${this.active_toggle}`);
        const elementWidth = `${element.offsetWidth}px`;
        const toggle = document.getElementById(`${this.toggle.id}`);
        toggle.style.width = elementWidth;
        toggle.className = 'toggle-active';
        toggle.style.backgroundColor = this.theme;
    }

    handleToggle(value: string, analytics = true) {
        if (this.inactive) {
            return;
        }
        if (value === 'view_all_players') {
            const params = { ...this.route.snapshot.queryParams };
            if (Object.keys(params).length > 0) {
                delete params.my_players;
                this.router.navigate([], { queryParams: params });
            }
        }
        const previousValue = this.active_toggle;
        const newValue = value;

        if (previousValue !== newValue) {
            let analytics_value = null;

            if (analytics) {
                analytics_value = value === 'playing' ? 'live' : value;
            }

            const payload: NewFilterModel = {
                type: this.toggle.type,
                value,
                analytics_value
            };

            this.applyFilter.emit(payload);

            // handle slider transforms
            this.active_toggle = value;
            if (value !== 'my_players' && value !== 'view_all_players') {
                const newEl = document.getElementById(`${this.toggle.id}-${this.active_toggle}`);
                const newElWidth = `${newEl.offsetWidth}px`;

                const slider = document.getElementById(`${this.toggle.id}`);
                const distance = this.handleSlider(previousValue, newValue);
                slider.style.width = newElWidth;
                slider.style.transform = `translate(${distance}px)`;
                slider.style.transition = 'transform 0.25s';
                slider.style.backgroundColor = this.theme;
            }
        }
    }

    setActiveLine() {
        const line = document.getElementById(`active-line`);
        line.style.width = '100%';
        line.style.borderBottom = '5px solid #ffffff';
        line.style.position = 'absolute';
        line.style.bottom = '0';
        line.style.left = '-50%';
    }

    toggleAllPlayers(value: string) {
        if (value === 'my_players') {
            const line = document.getElementById(`active-line`);
            line.style.left = '50%';
        }
        else if (value === 'view_all_players') {
            const line = document.getElementById(`active-line`);
            line.style.left = '-50%';
        }
    }

    handleLabelClass(filter) {
        if (filter === this.active_toggle) {
            return 'active-label';
        } else {
            return 'inactive-label';
        }
    }

    handleSlider(prevVal: string, nextVal: string) {
        const prevIdx = this.toggle_values.indexOf(prevVal);
        const nextIdx = this.toggle_values.indexOf(nextVal);
        let total = 0;
        if (prevIdx !== nextIdx) {
            for (let i = 0; i < nextIdx; i++) {
                total = total + this.span_widths[i];
            }
        }
        return total;
    }
}
