import { Injectable } from '@angular/core';
import { ScheduledEntry, MatchData } from '../_interfaces';
import { environment } from 'src/environments/environment';
import { HttpPromiseService } from './'


@Injectable({
    providedIn: 'root'
})
export class TennisScheduleEntriesService {
    constructor(
        private http: HttpPromiseService
    ) { }

    async getEntries(): Promise<ScheduledEntry[]> {
        return await this.http.get<{ data: ScheduledEntry[], error: string }>(`${environment.FE_API_FOUR}/tennis_schedule_entries`)
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                throw err;
            });
    }

    async getScheduledEntryMatches(team_id: string): Promise<MatchData[]> {
        return await this.http.get<{ data: MatchData[], error: string }>(`${environment.BLEACHR_WEB_API}/matches/team/${team_id}`)
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                console.error(err)
                throw err
            })
    }
}