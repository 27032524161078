<section *ngIf="enhanced_articles">
    <div class="timeline-item-header" [style.color]="enhanced_articles.title_color">
        <h2 [style.text-align]="enhanced_articles.context.title.alignment"
            [style.font-size.px]="enhanced_articles.context.title.font_size"
            [ngStyle]="handleFont(enhanced_articles.context.title.font)">
            {{enhanced_articles.title}}
        </h2>
    </div>
    <div class="subtitle" [style.color]="enhanced_articles.subtitle_color"
        [style.text-align]="enhanced_articles.context.subtitle.alignment"
        [style.font-size.px]="enhanced_articles.context.subtitle.font_size"
        [ngStyle]="handleFont(enhanced_articles.context.subtitle.font)">
        {{enhanced_articles.subtitle}}
    </div>
</section>
<app-glide-carousel [data_object]="enhanced_articles" [data_type]="'enhanced_articles'"></app-glide-carousel>