export const MatchStatusTranslations: Map<string, Map<string, string>> = new Map([
    ['live', new Map([
        ['en', 'Live Scores'],
        ['fr', 'Score en direct'],
        ['de', 'Live Scores']
    ])],
    ['suspended', new Map([
        ['en', 'Suspended'],
        ['fr', 'Suspendu'],
        ['de', 'Suspended']
    ])],
    ['cancelled', new Map([
        ['en', 'Cancelled'],
        ['fr', 'Annulé'],
        ['de', 'Abgesagt']
    ])]
])