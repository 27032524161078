import { Component, Input, Output, EventEmitter, OnInit, AfterViewInit } from '@angular/core';
import { getUser } from 'src/app/services/helpers';
import { MatchesService } from '../../services/matches.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TrophiesService } from '../../services/trophies.service';

const Android = window.Android || null;
import Glide from '@glidejs/glide';
import { SponsorService } from 'src/app/services/sponsor.service';
import { AnalyticService } from 'src/app/services/AnalyticService';
import { FollowedPlayersService } from 'src/app/services/followed-players.service';
import { PlayerDetailsService } from 'src/app/services/player-details.service';
import { CourtModel, PlayerDetailsModel, PlayerProfile, ScoreUpdateModel, SponsorModel, TrophyModel } from 'src/app/_interfaces';

@Component({
    selector: 'app-standalone-player-profile',
    templateUrl: './standalone-player-profile.component.html',
    styleUrls: ['./standalone-player-profile.component.scss']
})
export class StandalonePlayerProfileComponent implements OnInit, AfterViewInit {
    @Input() profile: PlayerProfile;
    @Input() player_matches: ScoreUpdateModel[];
    @Output() closed = new EventEmitter();
    @Output() handleFollowPlayer = new EventEmitter();

    public follow_button = false;

    public completed_matches: CourtModel = null;
    public upcoming_matches: CourtModel = null;
    public live_matches: CourtModel = null;
    public trophy: TrophyModel;
    public sponsor: SponsorModel;
    public displayed_details: PlayerDetailsModel[] = null;

    constructor(
        public matchesService: MatchesService,
        private dom: DomSanitizer,
        private router: Router,
        private trophiesAPI: TrophiesService,
        private sponsorAPI: SponsorService,
        public analytics: AnalyticService,
        public followedPlayers: FollowedPlayersService,
        public playerDetails: PlayerDetailsService
    ) { }

    async ngOnInit() {
        this.sponsor = this.sponsorAPI.getPageSponsor('player-details-top');
        if (Android !== null && Android.followPlayer && Android.unfollowPlayer) {
            this.follow_button = true;
        }

        this.analytics.handlePageView('Player details');

        this.buildView();
    }

    ngAfterViewInit() {
        if (this.profile.details.highlight_photos && this.profile.details.highlight_photos.length > 0) {
            setTimeout(() => {
                this.initGlide();
            }, 100);
        }
    }

    buildView() {
        this.displayed_details = this.playerDetails.buildPlayerDetails(this.profile);

        if (this.player_matches) {
            this.sortMatchesByStatus();
        }
    }

    sortMatchesByStatus() {
        const finishedStatuses = ['finished', 'completed', 'finished_retired', 'finished_walkover'];
        const liveStatuses = ['playing', 'on_court', 'warmup', 'suspended'];

        const live = [];
        const upcoming = [];
        const completed = [];

        this.player_matches.forEach(match => {
            if (match.status) {
                if (liveStatuses.some(status => status === match.status)) {
                    live.push(match);
                } else if (finishedStatuses.some(status => status === match.status)) {
                    completed.push(match);
                } else {
                    upcoming.push(match);
                }
            }
        });
        this.live_matches = this.matchesService.assignMatchesToCourts(live);
        this.upcoming_matches = this.matchesService.assignMatchesToCourts(upcoming);
        this.completed_matches = this.matchesService.assignMatchesToCourts(completed);
    }

    toggleDrawer() {
        this.closed.emit(null);
    }

    async handleFollow(id: string) {
        if (getUser() === null) {
            this.router.navigateByUrl('/login-prompt');
        } else {
            const idx = this.followedPlayers.getPlayerFollows().indexOf(id);
            if (idx === -1) {
                this.trophiesAPI.sendTrophyAchievement('how_you_doing', '1', this);
                this.followedPlayers.followPlayer(id);
            } else {
                this.followedPlayers.unfollowPlayer(id, idx)
            }
        }
    }

    noMatches(matches: CourtModel) {
        return !Object.keys(matches).length;
    }

    buildCourtName(key: string) {
        return this.matchesService.buildCourtName(key);
    }

    handleImageURL(url: string) {
        return this.dom.bypassSecurityTrustStyle(`url(${url})`);
    }

    async initGlide() {
        const glide = await this.buildGlide();
        glide.mount();
    }

    buildGlide(): Glide {
        return new Promise((completed) => {
            if (this.profile.details.highlight_photos.length === 1) {
                const new_glide = new Glide(`.glide`, {
                    type: 'slider',
                    gap: 32,
                    focusAt: 'center'
                });
                const slider: HTMLElement = document.querySelector('.glide');
                slider.classList.add('slider-margin');
                completed(new_glide);
            } else {
                const new_glide = new Glide(`.glide`, {
                    type: 'carousel',
                    gap: 16,
                    focusAt: 'center',
                    peek: 32,
                    animationTimingFunc: 'ease-in-out'
                });
                completed(new_glide);
            }
        });
    }

    toggleTrophyDetails(trophy: TrophyModel) {
        this.trophy = this.trophy ? null : trophy;
    }
}
