export interface OrderReceipt {
    id:                    string;
    number:                string;
    fan_id:                null;
    event_id:              string;
    section:               null;
    row:                   null;
    seat:                  null;
    status:                string;
    payment_token:         string;
    created_at:            Date;
    updated_at:            Date;
    closed_at:             Date;
    payment_reference:     string;
    final_action:          string;
    tip:                   string;
    transaction_fee:       string;
    coins_applied:         string;
    amount_charged:        string;
    store_id:              string;
    error_message:         null;
    payment_type:          string;
    payment_fixed_fee:     string;
    payment_service_fee:   string;
    fans_device_id:        string;
    phone_number:          string;
    address:               string;
    email_address:         string;
    expeditor_id:          null;
    delivery_instructions: null;
    name:                  null;
    printed:               boolean;
    order_items:           OrderItem[];
}

export interface OrderItem {
    id:         string;
    order_id:   string;
    product_id: string;
    quantity:   number;
    line_total: string;
    created_at: Date;
    updated_at: Date;
    item_price: string;
    item_name:  string;
}
