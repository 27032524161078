export class Helper {
    ensureHTTPS(value: string): string {
        if (typeof value !== 'string' || value.length <= 0) {
            return '';
        }
        const regx = /http:/g;
        return value.replace(regx, 'https:');
    }

    handleBackgroundURL(url: string): string {
        return typeof url === 'string' && url.length > 0 ? `url(${this.ensureHTTPS(url)})` : '';
    }

    validURL(url: string): boolean {
        const regx = /https:/g;
        return regx.test(url);
    }

    isImageURL(url: string): boolean {
        const supported_img_formats: string[] = ['.jpg', '.png', '.svg'];
        const supported = supported_img_formats.filter((f) => url === null ? false : url.includes(f));

        return supported.length > 0 ? true : false;
    }
}

export function handleRatio(item) {
    if (item.data[0] && item.data[0].height_px) {
        return ''
    } else if (item.data[0]?.video_preview_url) {
        return ''
    } else if (item.card_type === 'contributed_article' || item.card_type === 'combined_social') {
        return '1/1'
    } else if (item.card_type === 'broadcast_schedule') {
        return ''
    }
    return item.context.aspect_ratio ? item.context.aspect_ratio.replace(':', ' / ') : '16 / 9';
}