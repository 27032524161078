import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class PlayerDetailsService {
    constructor() { }

    getPlayHandValue(val: string) {
        if (val) {
            return val === 'left' ? 'Left-Handed' : 'Right-Handed'
        }
        return '-';
    }

    checkVal(val: string | number) {
        return val || '-';
    }

    buildPlayerDetails(profile): Array<{ label: string, value: string | number }> {
        return [
            {
                label: 'tennis.players.stat.age',
                value: this.checkVal(profile.details.age)
            },
            {
                label: 'tennis.players.stat.plays',
                value: this.getPlayHandValue(profile.details.play_hand)
            },
            {
                label: 'tennis.players.stat.height',
                value: this.checkVal(profile.details.height?.imperial)
            },
            {
                label: 'tennis.players.stat.birthplace',
                value: this.checkVal(profile.details.birth_city)
            },
            {
                label: 'tennis.players.stat.turned.pro',
                value: this.checkVal(profile.details.pro_year)
            },
            {
                label: 'tennis.players.stat.coach',
                value: this.checkVal(profile.details.current_coach)
            },
            {
                label: 'tennis.players.stat.ytd.wl',
                value: this.checkVal(profile.stats.ytd_winlosses)
            },
            {
                label: 'tennis.players.stat.ytd.titles',
                value: this.checkVal(profile.stats.ytd_titles)
            },
            {
                label: 'tennis.players.stat.career.wins',
                value: this.checkVal(profile.stats.career_wins)
            },
            {
                label: 'tennis.players.stat.career.losses',
                value: this.checkVal(profile.stats.career_losses)
            },
            {
                label: 'tennis.players.stat.career.titles',
                value: this.checkVal(profile.stats.career_titles)
            },
            {
                label: 'tennis.players.stat.career.prize',
                value: this.checkVal(profile.stats.career_prize_money)
            }
        ]
    }
}
