import { Component, OnInit, Input } from '@angular/core';
import { helpers } from '../../helpers';
import { FontService } from '../../services/font-service.service';

@Component({
    selector: 'app-homescreen-social-feed-component',
    templateUrl: './homescreen-social-feed-component.component.html',
    styleUrls: ['./homescreen-social-feed-component.component.scss']
})
export class HomescreenSocialFeedComponentComponent implements OnInit {
    @Input() social_feed;
    public ensureHTTPS = helpers.ensureHTTPS;
    public handleFont;
    public handleShadow = helpers.handleShadow;

    constructor(
        font: FontService
    ) {
        this.handleFont = font.handleFont;
    }

    ngOnInit() {
        this.formatURL();
    }

    formatURL() {
        if (!this.social_feed.data[0]) {
            this.social_feed.data[0] = [];
            return;
        }

        for (const post of this.social_feed.data[0].items) {
            if (post.has_media && post.has_video) {
                const regex = /watch\?v=/g;
                post.link = post.link.replace(regex, 'embed/');
            }
        }
    }

    buildReadMoreURL() {
        const fake_url = `${window.location.href}/social_feed}`;

        return fake_url;
    }

    buildURL(feedItem) {
        return feedItem.link;
    }
}
