import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlyrComponent } from './plyr.component';



@NgModule({
    declarations: [
        PlyrComponent
    ],
    imports: [
        CommonModule
    ],
    exports: [
        PlyrComponent
    ]
})
export class PlyrModule { }
