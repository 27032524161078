export interface UserModel {
    meta?: string;
    data: {
        token: string
    };
}

export interface CurrentUserModel {
    fingerprint: string;
    meta: any;
    token: string;
}

// export interface FanModel {
//     fan_id?: string;
//     team_id?: string;
//     earned?: string;
//     purchased?: string;
//     balance?: string;
//     rounded_down_balance?: string;
//     activated?: boolean;
//     loaded: boolean;
// }
