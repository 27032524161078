import { Component, Input } from '@angular/core';
import { FollowedPlayersService } from 'src/app/services/followed-players.service';

@Component({
    selector: 'app-match-team',
    templateUrl: './match-team.component.html',
    styleUrls: ['./match-team.component.scss']
})
export class MatchTeamComponent {
    @Input() team_data;
    @Input() match_status;

    constructor(
        private followedPlayers: FollowedPlayersService
    ) { }

    isFollowing(id: string) {
        return this.followedPlayers.getPlayerFollows().includes(id);
    }

    matchWon(team1_won: boolean, team2_won: boolean) {
        return team1_won || team2_won;
    }

    checkMatchStatusForPointDisplay(status: string) {
        if (status === 'playing' || status === 'suspended') {
            return true;
        }
        return false;
    }

}
