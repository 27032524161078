<a (click)="handleTourneySelection()" target="_self">
    <div [ngClass]="{'square': square, 'ratio': !square, 'elite': elite}">
        <div class="background" [style.background-image]="handleNullImage(tournament.image_url)"></div>
        <div *ngIf="elite" class="partner">
            <img src="https://res.cloudinary.com/bleachr/image/upload/v1561649236/sponsor-clock/t1_black_logo.png"
                alt="TennisONE Logo">
        </div>
        <div class="placeholder-copy" [ngStyle]="tourNameAlign(tournament)">
            <div *ngIf="elite && tournament.theme.logo_url" class="logo-container flex-center">
                <img [src]="tournament.theme.logo_url" alt="Partner Logo">
                </div>
            <div [ngClass]="{'title': square, 'ratio-title': !square, 'mtop': (elite && square)}" [ngStyle]="liveTourAlign(tournament)">
                {{tournament.name}}
            </div>
            <div>{{tournament.theme.subtitle}}</div>
            <div [ngClass]="{'square-date': square}"> 
                {{formatDateForDisplay(tournament.start_date)}} -
                {{formatDateForDisplay(tournament.end_date)}} 
            </div>
            <div *ngIf="tournament.timeframe === 'live' && tournament.theme.types" class="icons-container">
                <div *ngFor="let type of tournament.theme.types; let i = index">
                    <img [src]="tour_icons[type]" class="tour-logo"/>
                </div>
            </div>
        </div>
    </div>
</a>
