<div *ngIf="sponsor"
    [appAspectRatio]="aspect_ratio_payload" 
    class="bleachr-card sponsor-banner" 
    [class]="class"
    (click)="analytics.handleSponsorClick(sponsor, sponsor.name)"
    [style]="style"
    >

    <a class="content-container" [href]="sponsor.url" target="_blank">
        <img [src]="sponsor.image_url" [alt]="sponsor.name">
    </a>
</div>
