import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpOptionsHeaders } from './http-option-headers';
import { setOS } from './helpers';
import { v4 } from 'uuid';
import { Location } from '@angular/common';
import { ToggleAnalyticsPayload } from '../_interfaces';

const Android = window.Android || null;

interface Sessions {
    total: {
        type: string;
        sessionStart: number;
    };
    sub?: {
        type: string;
        sessionStart: number;
    };
    tourney?: {
        team: {
            id: string;
        };
        type: string;
        sessionStart: string;
    };
}

@Injectable({
    providedIn: 'root'
})
export class AnalyticService {
    private account_id = 'tennis-one';
    public buildHeaders = new HttpOptionsHeaders();
    private os: string;
    private fingerprint: string;
    private currentSessionToken: string;
    private primaryTeamId: string;
    private activeTeamId: string = null;

    constructor(private http: HttpClient, private location: Location) {
        this.os = setOS();
        this.setDeviceFingerprint();
        this.setCurrentSessionToken();
    }

    handleTourneySelection = (selectedTourneyObj) => {
        const { team_id } = selectedTourneyObj;
        this.startTourneySession(team_id);
        this.activeTeamId = team_id;

        this.postEvent('tournament_selection', selectedTourneyObj).subscribe(
            (data) => { },
            (error) => {
                console.log(error);
            }
        );
    };

    handleLocalhost(): boolean {
        return location.host.includes('localhost');
    }

    handlePageView = (screenName) => {
        if (this.handleLocalhost()) {
            return;
        }

        const event = { screen_name: screenName };

        this.postEvent('page_view', event).subscribe(
            (data) => { },
            (error) => {
                console.log(error);
            }
        );
    };

    handleTabSelection = (tabName, screenName) => {
        const event = { name: tabName, screen_name: screenName };

        this.postEvent('tab_selected', event).subscribe(
            (data) => { },
            (error) => {
                console.log(error);
            }
        );
    };

    handleButtonPress = (id, screen_name, name = '', parameters = {}): void => {
        const event = { id, screen_name, name, parameters };

        this.postEvent('button_press', event).subscribe(
            (data) => { },
            (error) => {
                console.log(error);
            }
        );
    };

    getCurrSessionsString = (): string => {
        return localStorage.getItem('sessions');
    };

    startTourneySession = (team_id: string) => {
        const runningSessions = JSON.parse(this.getCurrSessionsString());
        const newTourneySess = {
            team: { team_id },
            type: 'tourney',
            sessionStart: Date.now()
        };

        runningSessions['tourney'] = newTourneySess;

        localStorage.setItem('sessions', JSON.stringify(runningSessions));
    };

    startSubSession = (type: String): void => {
        const runningSessions = JSON.parse(this.getCurrSessionsString());
        const runningSubSession = runningSessions['sub'];
        const isNewSubSession = runningSubSession.type !== type;

        if (isNewSubSession && runningSubSession.type !== null) {
            this.endSubSession(runningSubSession);

            runningSessions['sub'] = {
                type,
                sessionStart: Date.now()
            };

            localStorage.setItem('sessions', JSON.stringify(runningSessions));
        } else if (runningSubSession.type === null) {
            runningSessions['sub'] = {
                type,
                sessionStart: Date.now()
            };

            localStorage.setItem('sessions', JSON.stringify(runningSessions));
        }
    };

    startTotalSession = (): void => {
        if (this.getCurrSessionsString() === null) {
            const sessionStart = Date.now();
            const currentSessionToken = v4();

            const currSessions = {
                total: {
                    type: 'total',
                    sessionStart
                },
                sub: {
                    type: null,
                    sessionStart: null
                },
                tourney: {
                    team: {
                        id: null
                    },
                    type: 'tourney',
                    sessionStart: null
                },
                currentSessionToken
            };

            this.currentSessionToken = currentSessionToken;
            localStorage.setItem('sessions', JSON.stringify(currSessions));
        }
    };

    endSubSession = (runningSubSession): void => {
        const { type, sessionStart, team } = runningSubSession;
        const teamId = team ? team.team_id : null;

        const sessionEndISO = new Date().toISOString();
        const sessionStartISO = new Date(sessionStart).toISOString();

        this.handleSession(type, sessionEndISO, sessionStartISO, teamId);
    };

    endAllSessions = () => {
        const runningSessions = JSON.parse(this.getCurrSessionsString());
        const { total, sub, tourney } = runningSessions;
        const sessionEnd = new Date().toISOString();
        const hasTourneySession = tourney['sessionStart'] ? true : false;

        total['session_end_local'] = sessionEnd;
        total['session_start_local'] = new Date(total['sessionStart']).toISOString();
        total['type'] = 'total';
        delete total['sessionStart'];

        sub['session_end_local'] = sessionEnd;
        sub['session_start_local'] = new Date(sub['sessionStart']).toISOString();
        delete sub['sessionStart'];

        const meta = this.getMetaData();
        const events = [
            { event_key: 'session', event: total, meta },
            { event_key: 'session', event: sub, meta }
        ];

        if (hasTourneySession) {
            tourney['session_end_local'] = sessionEnd;
            tourney['session_start_local'] = new Date(tourney['sessionStart']).toISOString();
            delete tourney['sessionStart'];
            delete tourney['team']['id'];

            events.push({ event_key: 'session', event: tourney, meta });
        }

        const payload = { events };

        const xhr = new XMLHttpRequest();

        xhr.open('POST', environment.ANALYTIC_ENDPOINT, false); // third parameter of `false` means synchronous
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.setRequestHeader('x-bleachr', this.account_id);
        xhr.setRequestHeader('fingerprint', this.fingerprint);

        xhr.send(JSON.stringify(payload));
    };

    setAccount(account): void {
        this.account_id = account.identifier;
    }

    getMetaData = () => {
        const meta: { [key: string]: any } = {
            local_time: new Date().toISOString(),
            language: navigator.language,
            locale: navigator.language.replace(/-/g, '_'),
            os: this.os,
            session_token: this.currentSessionToken,
            team: {
                activeId: this.activeTeamId
            },
            advertiserid: ''
        };

        return meta;
    };

    endTourneySession = () => {
        if (this.activeTeamId) {
            const currSessions = JSON.parse(this.getCurrSessionsString());
            const { tourney } = currSessions;

            this.activeTeamId = null;
            this.endSubSession(tourney);
        }
    };

    setCurrentSessionToken = (): void => {
        const sessionData = JSON.parse(this.getCurrSessionsString());

        if (sessionData) {
            const { currentSessionToken } = sessionData;
            this.currentSessionToken = currentSessionToken;
        }
    };

    setDeviceFingerprint = (): void => {
        if (Android !== null) {
            try {
                this.fingerprint = Android.getFingerprint();
                this.os = 'android';
            } catch (e) {
                console.error('Android unavailable');
            }
        } else {
            this.os = `Web ${this.os}`;

            const user = JSON.parse(localStorage.getItem('TennisONEUser'));

            if (user) {
                const decodedJwtJsonData = window.atob(user.token.split('.')[1]);
                const decodedJwtData = JSON.parse(decodedJwtJsonData);

                this.fingerprint = decodedJwtData.fan_id;
            } else if (!localStorage.getItem('anonFingerprint')) {
                this.fingerprint = v4();

                localStorage.setItem('anonFingerprint', this.fingerprint);
            } else {
                this.fingerprint = localStorage.getItem('anonFingerprint');
            }
        }
    };

    handleSession = (type, end, start, teamId = null) => {
        const event = {
            type: type,
            session_end_local: end,
            session_start_local: start
        };

        if (type === 'tourney') {
            event['team'] = { id: teamId };
        }

        this.postEvent('session', event).subscribe(
            (data) => { },
            (error) => {
                console.log(error);
            }
        );
    };

    handleLoginClick = (name: string, screen_name: string, parameters = {}) => {
        const event = { name, screen_name, parameters };

        this.postEvent('button_press', event).subscribe(
            (data) => { },
            (error) => {
                console.log(error);
            }
        );
    };

    handleSponsorClick = (unformattedSponsor, location, click_event = 'Sponsor banner tapped') => {
        const event = {
            key: unformattedSponsor.key,
            sponsor_name: unformattedSponsor.name,
            sponsor_id: unformattedSponsor.id,
            sponsor_location: location,
            event: click_event
        };

        this.postEvent('sponsor', event).subscribe(
            (data) => { },
            (error) => {
                console.log(error);
            }
        );
    };

    handlePlayerListSponsor = (playerId: string, sponsorUrl: string, click_event = 'Sponsor banner tapped') => {
        const event = {
            name: 'view.player.sponsor',
            parameters: {
                playerId,
                sponsorUrl
            },
            screen_name: 'TennisONE Players List',
            event: click_event
        }
        this.postEvent('custom_event', event).subscribe(
            (data) => { },
            (error) => {
                console.log(error);
            }
        );
    }

    handleFilterToggle = (event: ToggleAnalyticsPayload) => {
        this.postEvent('filter_toggled', event).subscribe(
            (data) => { },
            (error) => {
                console.log(error);
            }
        );
    };

    handleHomescreenCustomTap = (event) => {
        this.postEvent('homescreen_custom', event).subscribe(
            (data) => { },
            (error) => {
                console.log(error);
            }
        );
    };

    handleFanStreamTap = (event_key, event) => {
        this.postEvent(event_key, event).subscribe(
            (data) => { },
            (error) => {
                console.log(error);
            }
        );
    };

    postEvent(event_key: string, event: { [key: string]: any }, advertiserid = null): Observable<any> {
        if (!this.fingerprint) {
            this.setDeviceFingerprint();
        }

        const headers = this.buildHeaders.buildAnalyticHeaders(this.account_id, this.fingerprint);
        const meta = this.getMetaData();
        const events: Array<{ [key: string]: any }> = [{ event_key, event, meta }];
        const payload: { [key: string]: any } = { events };

        const currAnalytics = this.http.post(environment.ANALYTIC_ENDPOINT, payload, headers);
        // const kinesisAnalytics = this.http.post(environment.ANALYTIC2_ENDPOINT, payload, headers);

        return forkJoin([currAnalytics]);
    }
}
