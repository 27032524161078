export interface ColorCodeModel {
    primary: {
        color: string;
        text_color: string;
        css: {
            'background-color': string;
            color: string;
        }
    };
    secondary: {
        color: string;
        text_color: string;
        css: {
            'background-color': string;
            color: string;
        }
    };
}
