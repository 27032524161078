import { Team } from '../_interfaces';

export function ensurePlayer(team: Team, player: 'player1' | 'player2'): boolean {
    switch (player) {
        case 'player1': {
            return team.player1 && team.player1.first_name && team.player1.last_name ? true : false
        }
        case 'player2': {
            return team.player2 && team.player2.first_name && team.player2.last_name ? true : false
        }
        default: {
            return false
        }
    }
}